import axios, { AxiosHeaders } from 'axios';
import { Config } from 'config';
import useNavigate from 'hooks/use-navigate';
import Error404 from 'pages/Error404';
import HomePage from 'pages/home/HomePage';
import InboxDetailPage from 'pages/home/inbox/InboxDetailPage';
import InboxPage from 'pages/home/inbox/InboxPage';
import DefaultLayout from 'pages/layouts/DefaultLayout';
import DefaultMobileLayout from 'pages/layouts/DefaultMobileLayout';
import LoginPage from 'pages/LoginPage';
import ManageCompanyCreatePage from 'pages/manage/company/ManageCompanyCreatePage';
import ManageCompanyDetailPage from 'pages/manage/company/ManageCompanyDetailPage';
import ManageCompanyEditPage from 'pages/manage/company/ManageCompanyEditPage';
import ManageCompanyPage from 'pages/manage/company/ManageCompanyPage';
import ManageInformationCreatePage from 'pages/manage/information/ManageInformationCreatePage';
import ManageInformationDetailPage from 'pages/manage/information/ManageInformationDetailPage';
import ManageInformationEditPage from 'pages/manage/information/ManageInformationEditPage';
import ManageInformationPage from 'pages/manage/information/ManageInformationPage';
import AiPSCiCreatePage from 'pages/manage/power_plant/aipcsi/AiPSCiCreatePage';
import AiPSCiEditPage from 'pages/manage/power_plant/aipcsi/AiPSCiEditPage';
import ManagePowerPlantCreatePage from 'pages/manage/power_plant/ManagePowerPlantCreatePage';
import ManagePowerPlantDetailPage from 'pages/manage/power_plant/ManagePowerPlantDetailPage';
import ManagePowerPlantEditPage from 'pages/manage/power_plant/ManagePowerPlantEditPage';
import ManagePowerPlantPage from 'pages/manage/power_plant/ManagePowerPlantPage';
import PCSCreatePage from 'pages/manage/power_plant/pcs/PCSCreatePage';
import PCSEditPage from 'pages/manage/power_plant/pcs/PCSEditPage';
import ManageUserChangePasswordPage from 'pages/manage/user/ManageUserChangePasswordPage';
import ManageUserCreatePage from 'pages/manage/user/ManageUserCreatePage';
import ManageUserDetailPage from 'pages/manage/user/ManageUserDetailPage';
import ManageUserEditPage from 'pages/manage/user/ManageUserEditPage';
import ManageUserPage from 'pages/manage/user/ManageUserPage';
import MobileHomePage from 'pages/mobile/MobileHomePage';
import MobileManagePowerPlantPage from 'pages/mobile/MobileManagePowerPlantPage';
import MobileManagePowerPlantDetailPage from 'pages/mobile/MobileManagePowerPlantDetailPage';
import MobileMonitorAlertPage from 'pages/mobile/MobileMonitorAlertPage';
import MobileMonitorAlertDetailPage from 'pages/mobile/MobileMonitorAlertDetailPage';
import MobileMonitorPCSPage from 'pages/mobile/MobileMonitorPCSPage';
import MobileMonitorMapPage from 'pages/mobile/MobileMonitorMapPage';
import MonitorAlertDetailPage from 'pages/monitor/alert/MonitorAlertDetailPage';
import MonitorAlertPage from 'pages/monitor/alert/MonitorAlertPage';
import MonitorFailurePage from 'pages/monitor/failure/MonitorFailurePage';
import MonitorPCSPage from 'pages/monitor/pcs/MonitorPCSPage';
import MonitorMapPage from 'pages/monitor/map/MonitorMapPage';
import MonitorMapMemoPage from 'pages/monitor/map/MonitorMapMemoPage';
import MonitorMapApprovePage from 'pages/monitor/map/MonitorMapApprovePage';
import MonitorMapSettingPage from 'pages/monitor/map/MonitorMapSettingPage';
import SettingMypageChangePasswordPage from 'pages/setting/mypage/SettingMypageChangePasswordPage';
import SettingMypageEditPage from 'pages/setting/mypage/SettingMypageEditPage';
import SettingMypagePage from 'pages/setting/mypage/SettingMypagePage';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Route, Routes, useLocation } from 'react-router-dom';

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const pc = query.get('pc');

  const initialized = React.useRef(false);

  const axiosInitialize = () => {
    axios.defaults.baseURL = Config.API_URL;

    axios.interceptors.request.use((request) => {
      const token = localStorage.getItem('token');
      const headers = request.headers as AxiosHeaders;
      if (headers) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return request;
    });

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 302) {
          navigate('/');
        }
        if (error.response.status === 401) {
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );
  };

  React.useLayoutEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      axiosInitialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMobile && (pc === null || pc === 'false')) {
    return (
      <Routes>
        <Route path="login" element={<LoginPage />} />

        <Route path="/" element={<DefaultMobileLayout />}>
          <Route path="" element={<MobileHomePage />} />

          <Route path="monitor">
            <Route path="alert">
              <Route path="" element={<MobileMonitorAlertPage />} />
              <Route
                path="detail/:id"
                element={<MobileMonitorAlertDetailPage />}
              />
            </Route>
            <Route path="pcs" element={<MobileMonitorPCSPage />} />
            <Route path="map">
              <Route path="" element={<MobileMonitorMapPage />} />
            </Route>
          </Route>

          {/* ユーザー情報 */}
          <Route path="manage">
            <Route path="power_plant">
              <Route path="" element={<MobileManagePowerPlantPage />} />
              <Route path="detail/:id">
                <Route path="" element={<MobileManagePowerPlantDetailPage />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="*" element={<Error404 />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />

      <Route path="/" element={<DefaultLayout />}>
        <Route path="" element={<HomePage />} />

        <Route path="inbox">
          <Route path="" element={<InboxPage />} />
          <Route path="detail/:id" element={<InboxDetailPage />} />
        </Route>

        <Route path="monitor">
          <Route path="alert">
            <Route path="" element={<MonitorAlertPage />} />
            <Route path="detail/:id" element={<MonitorAlertDetailPage />} />
          </Route>
          <Route path="pcs" element={<MonitorPCSPage />} />
          <Route path="failure" element={<MonitorFailurePage />} />

          <Route path="map">
            <Route path="" element={<MonitorMapPage />} />
            <Route path="memo" element={<MonitorMapMemoPage />} />
            <Route path="settings" element={<MonitorMapSettingPage />} />
            <Route path="approve" element={<MonitorMapApprovePage />} />
          </Route>
        </Route>

        {/* ユーザー情報 */}
        <Route path="manage">
          <Route path="company">
            <Route path="" element={<ManageCompanyPage />} />
            <Route path="create" element={<ManageCompanyCreatePage />} />
            <Route path="edit/:id" element={<ManageCompanyEditPage />} />
            <Route path="detail/:id" element={<ManageCompanyDetailPage />} />
          </Route>
          <Route path="power_plant">
            <Route path="" element={<ManagePowerPlantPage />} />
            <Route path="create" element={<ManagePowerPlantCreatePage />} />
            <Route path="edit/:id" element={<ManagePowerPlantEditPage />} />
            <Route path="detail/:id">
              <Route path="" element={<ManagePowerPlantDetailPage />} />
              <Route path="aipcsi">
                <Route path="create" element={<AiPSCiCreatePage />} />
                <Route path="edit/:aipcsi_id" element={<AiPSCiEditPage />} />
              </Route>
              <Route path="pcs">
                <Route path="create" element={<PCSCreatePage />} />
                <Route path="edit/:pcs_id" element={<PCSEditPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="user">
            <Route path="" element={<ManageUserPage />} />
            <Route path="create" element={<ManageUserCreatePage />} />
            <Route path="edit/:id" element={<ManageUserEditPage />} />
            <Route path="detail/:id" element={<ManageUserDetailPage />} />
            <Route
              path="changepw/:id"
              element={<ManageUserChangePasswordPage />}
            />
          </Route>
          <Route path="information">
            <Route path="" element={<ManageInformationPage />} />
            <Route path="create" element={<ManageInformationCreatePage />} />
            <Route path="edit/:id" element={<ManageInformationEditPage />} />
            <Route
              path="detail/:id"
              element={<ManageInformationDetailPage />}
            />
          </Route>
        </Route>

        <Route path="setting">
          <Route path="mypage">
            <Route path="" element={<SettingMypagePage />} />
            <Route path="edit" element={<SettingMypageEditPage />} />
            <Route
              path="changepw/:id"
              element={<SettingMypageChangePasswordPage />}
            />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default App;
