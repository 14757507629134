import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Head from 'components/common/Head';
import TextField from 'components/common/TextField';
import Pagination from 'components/common/pagination/Pagination';
import dayjs from 'dayjs';
import { OutletContextModel, PowerPlantListModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { Store } from 'store';
import Utils from 'utils';

type FormState = {
  keyword: string;
};

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;

const ManagePowerPlantPage: React.FC = () => {
  const user = Store.loadUserDara();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const methods = useForm<FormState>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      keyword: '',
    },
  });

  const [data, setData] = React.useState<PowerPlantListModel[]>([]);
  const [filtered, setFiltered] = React.useState<PowerPlantListModel[]>([]);
  const [items, setItems] = React.useState<PowerPlantListModel[]>([]);
  const [page, setPage] = React.useState(1);

  const onFilter = () => {
    let filtered = data;
    const filterText = methods.getValues().keyword;
    if (filterText !== '') {
      filtered = data.filter(
        (v) =>
          v.power_plant_name.includes(filterText) ||
          v.address.includes(filterText) ||
          v.manager_first_name.includes(filterText) ||
          v.manager_last_name.includes(filterText)
      );
    }
    setPage(1);
    setFiltered(filtered);
    setItems(filtered.slice(0, NUM_IN_PAGE));
  };

  const onChangePage = (page: number) => {
    setPage(page);
    if (!filtered) {
      setItems([]);
      return;
    }

    const start = NUM_IN_PAGE * (page - 1);
    const end = start + NUM_IN_PAGE;
    setItems(filtered.slice(start, end));
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.PowerPlant.list();
        console.log(response);
        if (response.data) {
          setData(response.data);
          setFiltered(response.data);
          setItems(response.data.slice(NUM_IN_PAGE * (page - 1), NUM_IN_PAGE));
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="発電所一覧" />
      <Breadcrumbs items={[{ label: '発電所一覧' }]} />
      <div className="font-bold text-3xl text-re-063B67">発電所一覧</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="flex items-center gap-10">
          <div className="flex-1 font-bold text-2xl text-re-063B67">
            発電所一覧
          </div>
          <div className="flex items-center gap-1">
            <FormProvider {...methods}>
              <TextField
                fieldName="keyword"
                width="w-[320px]"
                placeholder="例： 発電所名 など"
              />
            </FormProvider>
            <Button text="絞り込み" size="sm" onClick={onFilter} />
          </div>
          {user?.type === 'master' && (
            <Button text="発電所新規登録" link="/manage/power_plant/create" />
          )}
        </div>

        <table className="w-full my-4 border border-re-gray rounded-lg border-separate border-spacing-0">
          <thead>
            <tr className="bg-re-4D7FAF text-white">
              <th className="p-2 rounded-tl-lg">発電事業者名</th>
              <th className="p-2">発電所名</th>
              <th className="p-2">発電所住所</th>
              <th className="p-2">AiPCSi稼働台数</th>
              <th className="p-2">PCS稼働台数</th>
              <th className="p-2">発電所担当者</th>
              <th className="p-2 rounded-tr-lg">発電所詳細</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              const isDeleted =
                !!item.deleted_at && dayjs(item.deleted_at).isValid();
              return (
                <tr
                  key={index}
                  className={`group ${isDeleted ? 'bg-re-smoke text-re-gray' : ''
                    }`}
                >
                  <td className="p-4 font-bold group-last:rounded-bl-lg">
                    {item.company_name}
                  </td>
                  <td className="p-4">{item.power_plant_name}</td>
                  <td className="p-4">{item.address}</td>
                  <td className="p-4">{item.aipcsi_count}</td>
                  <td className="p-4">{item.pcs_count}</td>
                  <td className="p-4">
                    {item.manager_last_name}&nbsp;{item.manager_first_name}
                  </td>
                  <td className="p-4 text-center group-last:rounded-br-lg">
                    <Button
                      text="詳細"
                      size="sm"
                      link={`/manage/power_plant/detail/${item.id}/`}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <Pagination
            current={page}
            count={Math.ceil(filtered.length / NUM_IN_PAGE)}
            onChange={onChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default ManagePowerPlantPage;
