import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Head from 'components/common/Head';
import Pagination from 'components/common/pagination/Pagination';
import dayjs from 'dayjs';
import { InformationModel, OutletContextModel } from 'models';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import Utils from 'utils';

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;

const InboxPage: React.FC = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const [data, setData] = React.useState<InformationModel[]>([]);
  const [items, setItems] = React.useState<InformationModel[]>([]);
  const [page, setPage] = React.useState(1);

  const initialized = React.useRef(false);

  const onChangePage = (page: number) => {
    setPage(page);

    const start = NUM_IN_PAGE * (page - 1);
    const end = start + NUM_IN_PAGE;
    setItems(data.slice(start, end));
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);

      try {
        const response = await API.Information.historyList();
        if (response.data) {
          setData(response.data);
          setItems(response.data.slice(NUM_IN_PAGE * (page - 1), NUM_IN_PAGE));
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full bg-re-F3F7FA px-8 py-2 pb-20 flex flex-col gap-4">
      <Head title="お知らせ履歴" />
      <Breadcrumbs items={[{ label: 'お知らせ 履歴' }]} />
      <div className="font-bold text-3xl text-re-063B67">お知らせ 履歴</div>
      <Card className="p-6">
        <div className="flex items-center">
          <div className="flex-1 font-bold text-2xl text-re-gray">
            お知らせ 履歴
          </div>
        </div>

        <table className="w-full my-4 border border-re-gray rounded-lg border-separate border-spacing-0">
          <thead>
            <tr className="bg-re-4D7FAF text-white">
              <th className="w-[28%] p-2 rounded-tl-lg">件名</th>
              <th className="w-[28%] p-2">本文</th>
              <th className="w-[8%] p-2">配信開始時間</th>
              <th className="w-[8%] p-2">内容確認</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index} className="group">
                <td className="p-4 group-last:rounded-bl-lg">
                  <span className="line-clamp-1 break-all">{item.title}</span>
                </td>
                <td className="p-4">
                  <span className="line-clamp-1 break-all">{item.body}</span>
                </td>
                <td className="p-4">
                  {dayjs(item.delivery_on).format('YYYY-MM-DD HH:mm')}
                </td>
                <td className="p-4">
                  <Button
                    text="内容確認"
                    width="w-full"
                    link={`/inbox/detail/${item.id}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          current={page}
          count={Math.ceil(data.length / NUM_IN_PAGE)}
          onChange={onChangePage}
        />
      </Card>
    </div>
  );
};

export default InboxPage;
