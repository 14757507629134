/** ユーザーの権限種別 */
export const enum AlertType {
  TROUBLE = '異常',
  WARNING = '警告',
  CAUTION = '注意',
  NORMAL = '正常',
}

/** 「アラート種別」の文字列を返します。*/
export const getAlertTypeStr = (type: string) => {
  switch (type) {
    case 'trouble':
      return AlertType.TROUBLE;
    case 'warning':
      return AlertType.WARNING;
    case 'caution':
      return AlertType.CAUTION;
    default:
      return AlertType.NORMAL;
  }
};

/** 「アラート種別」の文字列を返します。*/
export const getAlertColor = (type: string) => {
  switch (type) {
    case 'trouble':
      return 'border border-1 border-re-danger bg-re-danger text-white';
    case 'warning':
      return 'border border-1 border-re-warn bg-re-warn text-white';
    case 'caution':
      return 'border border-1 border-re-warn bg-re-warn text-white';
    default:
      return 'border border-1 border-re-normal text-re-normal';
  }
};
