import Button from 'components/common/Button';
import Dialog from 'components/common/Dialog';
import Menu from 'components/common/menu/Menu';
import Spinner from 'components/common/Spinner';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { Store } from 'store';

const DefaultLayout: React.FC = () => {
  const navigate = useNavigate();

  const [spinner, setSpinner] = useState(false);
  const [msgDialogOption, setMsgDialogOption] = useState<{
    message: string;
    onClosed?: () => void;
  } | null>(null);

  const token = Store.loadToken();
  if (!token) {
    return <Navigate to="/login" />;
  }

  const onChangeMobileMode = () => {
    navigate('/');
  };

  return (
    <>
      {isMobile && (
        <div className="h-16">
          <div className="fixed top-0 left-0 w-screen bg-black bg-opacity-60 h-16 flex justify-center items-center">
            <Button
              outlined
              size="sm"
              text="スマートフォン表示に切り替え"
              width="whitespace-nowrap px-6"
              onClick={onChangeMobileMode}
            />
          </div>
        </div>
      )}
      <div className="max-w-[1980px] min-w-[1200px] min-h-screen mx-auto flex bg-white ">
        <Menu />
        <div className="flex-1">
          <Outlet
            context={{
              spinner: { flg: spinner, setState: setSpinner },
              msgDialog: {
                open: (message: string, onClosed?: () => void) => {
                  setMsgDialogOption({ message, onClosed });
                },
              },
            }}
          />
        </div>
        <Spinner show={spinner} />
        <Dialog
          show={!!msgDialogOption}
          onConfirm={() => {
            if (msgDialogOption?.onClosed) {
              msgDialogOption.onClosed();
            }
            setMsgDialogOption(null);
          }}
        >
          {msgDialogOption?.message}
        </Dialog>
      </div>
    </>
  );
};

export default DefaultLayout;
