import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { createFadingAnime } from 'styles/transition';
import { AiOutlineCloseSquare } from 'react-icons/ai';

type PROPS = {
  show: boolean;
  src: string;
  onClose: () => void;
};

const ImageModal: React.FC<PROPS> = ({ show, src, onClose }: PROPS) => {
  const nodeRef = React.useRef(null); // StrictModeエラーの対処用
  const fade = createFadingAnime(0.3);

  // モーダルの外側をクリックで閉じる
  const clickOutSideToClose = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget && onClose) onClose();
  };

  return (
    <CSSTransition
      in={show}
      timeout={{ enter: 0, exit: 100 }}
      unmountOnExit
      nodeRef={nodeRef}
    >
      {(state) => (
        <div
          className="fixed top-0 left-0 h-full w-full bg-black bg-opacity-60 flex justify-center items-center z-[999]"
          style={fade[state]}
          onClick={(e) => {
            clickOutSideToClose(e);
          }}
        >
          <div
            className="absolute top-10 right-10 cursor-pointer"
            onClick={onClose}
          >
            <AiOutlineCloseSquare className="text-white text-4xl" />
          </div>
          <img
            src={src}
            alt="Image_modal"
            className="w-[85%] h-[85%] object-contain"
          />
        </div>
      )}
    </CSSTransition>
  );
};

export default ImageModal;
