import Button from 'components/common/Button';
import { UserModel } from 'models';
import React from 'react';

type PROPS = {
  data?: UserModel;
  onAddEmail: (email: string) => Promise<boolean>;
  onRemoveEmail: (emailID: string) => void;
  isDeleted?: boolean;
};

const UserDetailPanel3: React.FC<PROPS> = (props) => {
  const [email, setEmail] = React.useState('');

  const onAddEmail = async () => {
    const result = await props.onAddEmail(email);
    if (result) {
      setEmail('');
    }
  };
  const onRemoveEmail = (id: string) => {
    props.onRemoveEmail(id);
  };

  return (
    <div className="drop-shadow rounded bg-white p-6 flex flex-col gap-4">
      <div className="font-bold text-2xl text-re-063B67">
        通知を受け取るメールアドレス登録
      </div>
      <div className="text-sm">
        PCS故障予知アラート、定期レポート、システムに関するお知らせ、サブユーザーがMAP機能を操作した際の承認通知
      </div>
      <div className="flex gap-24">
        <div className="font-bold">メールアドレスの登録</div>
        <table>
          <tbody>
            {(props.data?.emails ?? []).map((item, i) => {
              return (
                <tr key={i} className='[&>td]:p-2'>
                  <td>{item.email}</td>
                  <td>
                    <Button
                      text="解除"
                      size="sm"
                      outlined
                      onClick={() => {
                        onRemoveEmail(item.email_id);
                      }}
                    />
                  </td>
                  <td>
                    {item.is_nondelivery && <div className="text-re-danger">エラー</div>}
                  </td>
                </tr>
              );
            })}
            <tr className='[&>td]:p-2'>
              <td>
                <div className="w-96">
                  <input
                    className="w-full p-2 border-2 border-zinc-700"
                    placeholder="例：xxxx-mailing-list@sample.com"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    disabled={props.isDeleted}
                  />
                </div>
              </td>
              <td>
                <Button
                  text="登録"
                  size="sm"
                  onClick={onAddEmail}
                  disabled={email.length === 0}
                />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div className="grid grid-cols-3 gap-4">

        </div>
      </div>
    </div>
  );
};

export default UserDetailPanel3;
