import FormLabel from 'components/common/FormLabel';
import TextField from 'components/common/TextField';
import { UserRoleType } from 'enums';
import { UserModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {};

const UserEditFormBlock1ItemUsername: React.FC<PROPS> = (props) => {
  const { formState, getValues } = useFormContext<UserModel>();

  const type = getValues().type;

  if (type === UserRoleType.SUB) {
    return (
      <div className="flex gap-2">
        <FormLabel text="企業名" required />
        <div className="flex-1">
          <TextField
            fieldName="contractor_name"
            placeholder="例：◯◯◯会社"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.contractor_name?.message}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-2">
      <FormLabel text="ユーザー名" required />
      <div className="flex-1">
        <div className="flex-1 flex gap-4">
          <TextField
            fieldName="last_name"
            placeholder="苗字"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.last_name?.message}
          />
          <TextField
            fieldName="first_name"
            placeholder="名前"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.first_name?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default UserEditFormBlock1ItemUsername;
