import BackgroundImg from 'assets/background_login.png';
import LogoDark from 'assets/logo_dark.svg';
import PowerPlantImg from 'assets/powerplant.png';
import axios from 'axios';
import Button from 'components/common/Button';
import Head from 'components/common/Head';
import Spinner from 'components/common/Spinner';
import useNavigate from 'hooks/use-navigate';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Store } from 'store';
import Utils from 'utils';

type Fields = {
  login_id: string;
  password: string;
};

const Input: React.FC<{
  type?: string;
  fieldName: string;
  validations: object;
}> = (props) => {
  const { register } = useFormContext();

  return (
    <input
      className="w-full p-2 bg-re-F7FCFF border border-re-gray group-[.error]:bg-re-red50 group-[.error]:border-2 group-[.error]:border-re-danger"
      type={props.type ?? 'text'}
      {...register(props.fieldName, props.validations)}
    />
  );
};

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const [spinner, setSpinner] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState('');

  const methods = useForm<Fields>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      login_id: '',
      password: '',
    },
  });

  const onLogin = async (data: Fields) => {
    console.log(data);

    setSpinner(true);
    try {
      const response = await axios.post<
        { token: string; user: Store.UserData } | { message: string }
      >('/auth', {
        login_id: data.login_id,
        password: data.password,
      });
      if ('message' in response.data) {
        // ログインエラー
        // console.log(response.data.message);
        setErrorMessage(response.data.message);
      } else {
        const token = response.data.token;
        const user = response.data.user;
        Store.save(token, user);
        navigate('/');
      }
    } catch (e) {
      // console.error(e);
      methods.setError('password', {
        message: 'ユーザーIDまたはパスワードが間違っています',
      });
    }
    setSpinner(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  return (
    <div
      className={`w-full min-h-screen bg-re-99DDFF flex flex-col items-center group ${
        errorMessage && 'error'
      }
      }`}
      style={{
        backgroundImage: `url("${BackgroundImg}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    >
      <Head title="ログイン" />
      <div className="my-24 drop-shadow rounded bg-white sm:p-20 p-8 sm:w-[520px] w-11/12 flex flex-col items-center gap-12 ">
        <img
          src={LogoDark}
          width="350px"
          alt="logo"
          className="sm:sm:w-[350px] w-11/12"
        />
        <FormProvider {...methods}>
          <div className="w-full flex flex-col">
            <img
              src={PowerPlantImg}
              alt="powerplant"
              className="sm:w-[375px] w-11/12 container mx-auto"
            />
            <label className="font-bold">ユーザーID</label>
            <Input
              fieldName="login_id"
              validations={{
                required: '必須項目です',
                pattern: {
                  value: /.+@.+$/,
                  message: 'ユーザーIDの形式が不正です',
                },
              }}
            />
            <div className="text-sm text-re-danger">
              {methods.formState.errors.login_id?.message}
            </div>
          </div>
          <div className="w-full flex flex-col">
            <label className="font-bold">パスワード</label>
            <Input
              type="password"
              fieldName="password"
              validations={Utils.requiredValidation()}
            />
            <div className="text-sm text-re-danger">
              {methods.formState.errors.password?.message}
            </div>
          </div>
        </FormProvider>
        <div className="flex flex-col items-center">
          {errorMessage && (
            <div className="text-sm text-re-danger">{errorMessage}</div>
          )}
          <Button
            text="ログイン"
            onClick={() => {
              setErrorMessage('');
              methods.handleSubmit(onLogin)();
            }}
          />
        </div>

        {/* <div className="text-sm underline">パスワードを忘れた方</div> */}
      </div>
      <Spinner show={spinner} />
    </div>
  );
};

export default LoginPage;
