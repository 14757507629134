import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Head from 'components/common/Head';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { InformationModel, OutletContextModel } from 'models';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import Utils from 'utils';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const ManageinformationPage: React.FC = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const [publishData, setPublishData] = React.useState<InformationModel[]>([]);
  const [unPublishData, setUnPublishData] = React.useState<InformationModel[]>(
    []
  );
  const initialized = React.useRef(false);

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);

      try {
        const response = await API.Information.list();
        if (response.data) {
          /** 現在HOMEに表示中のお知らせ */
          setPublishData(
            response.data.filter((v) => {
              // deleted_atが設定されている要素は除外する
              if (v.deleted_at && dayjs(v.deleted_at).isValid()) {
                return false;
              }
              return dayjs().isSameOrBefore(v.delivery_end) || !v.delivery_end;
            })
          );

          /** HOMEから下げたお知らせ */
          setUnPublishData(
            response.data.filter((v) => {
              // deleted_atが設定されている要素を含む
              if (v.deleted_at && dayjs(v.deleted_at).isValid()) {
                return true;
              }
              return dayjs().isSameOrAfter(v.delivery_end);
            })
          );
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full bg-re-F3F7FA px-8 py-2 pb-20 flex flex-col gap-4">
      <Head title="お知らせ一覧" />
      <Breadcrumbs items={[{ label: 'お知らせ 管理' }]} />
      <div className="font-bold text-3xl text-re-063B67">お知らせ管理</div>
      <Card className="p-6">
        <div className="flex items-center">
          <div className="flex-1 font-bold text-2xl text-re-063B67">
            HOMEに表示中・予約中のお知らせ
          </div>
          <Button text="お知らせ 新規作成" link="/manage/information/create" />
        </div>

        <table className="w-full my-4 border border-re-gray rounded-lg border-separate border-spacing-0">
          <thead>
            <tr className="bg-re-4D7FAF text-white">
              <th className="w-[28%] p-2 rounded-tl-lg">件名</th>
              <th className="w-[28%] p-2">本文</th>
              <th className="w-[8%] p-2">配信開始時間</th>
              <th className="w-[8%] p-2">配信終了時間</th>
              <th className="w-[8%] p-2">内容確認</th>
              <th className="w-[12%] p-2 rounded-tr-lg">ステータス</th>
            </tr>
          </thead>
          <tbody>
            {publishData.map((item, index) => (
              <tr key={index} className="group">
                <td className="p-4 group-last:rounded-bl-lg">
                  <span className="line-clamp-1 break-all">{item.title}</span>
                </td>
                <td className="p-4">
                  <span className="line-clamp-1 break-all">{item.body}</span>
                </td>
                <td className="p-4">
                  {dayjs(item.delivery_on).format('YYYY-MM-DD HH:mm')}
                </td>
                <td className="p-4">
                  {item.delivery_end
                    ? dayjs(item.delivery_end).format('YYYY-MM-DD HH:mm')
                    : '指定なし'}
                </td>
                <td className="p-4">
                  <Button
                    text="内容確認"
                    width="w-full"
                    link={`/manage/information/detail/${item.id}`}
                  />
                </td>
                <td className="p-4 text-center group-last:rounded-br-lg">
                  {dayjs().isSameOrAfter(item.delivery_on)
                    ? '表示中'
                    : '配信待ち/予約中'}
                  {item.is_master_only && '(マスターのみ)'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>

      <Card className="p-6 mt-4">
        <div className="flex items-center">
          <div className="font-bold text-2xl text-re-gray">
            HOMEから下げたお知らせ
          </div>
        </div>

        <table className="w-full my-4 border border-re-gray rounded-lg border-separate border-spacing-0">
          <thead>
            <tr className="bg-re-4D7FAF text-white">
              <th className="w-[28%] p-2 rounded-tl-lg">件名</th>
              <th className="w-[28%] p-2">本文</th>
              <th className="w-[8%] p-2">配信開始時間</th>
              <th className="w-[8%] p-2">配信終了時間</th>
              <th className="w-[8%] p-2">内容確認</th>
              <th className="w-[12%] p-2 rounded-tr-lg">ステータス</th>
            </tr>
          </thead>
          <tbody>
            {unPublishData.map((item, index) => (
              <tr key={index} className="group">
                <td className="p-4 group-last:rounded-bl-lg">
                  <span className="line-clamp-1 break-all">{item.title}</span>
                </td>
                <td className="p-4">
                  <span className="line-clamp-1 break-all">{item.body}</span>
                </td>
                <td className="p-4">
                  {dayjs(item.delivery_on).format('YYYY-MM-DD HH:mm')}
                </td>
                <td className="p-4">
                  {dayjs(item.delivery_end).format('YYYY-MM-DD HH:mm')}
                </td>
                <td className="p-4">
                  <Button
                    text="内容確認"
                    width="w-full"
                    link={`/manage/information/detail/${item.id}`}
                  />
                </td>
                <td className="p-4 text-center group-last:rounded-br-lg">
                  配信済
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

export default ManageinformationPage;
