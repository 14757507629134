import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Head from 'components/common/Head';
import PowerPlantEditFormBlock1 from 'components/manage/power_plant/PowerPlantEditFormBlock1';
import PowerPlantEditFormBlock2 from 'components/manage/power_plant/PowerPlantEditFormBlock2';
import useNavigate from 'hooks/use-navigate';
import { CompanyModel, OutletContextModel, PowerPlantModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import Utils from 'utils';

const ManagePowerPlantCreatePage: React.FC = () => {
  const navigate = useNavigate();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const companyList = React.useRef<CompanyModel[]>([]);

  const methods = useForm<PowerPlantModel>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      company_id: '' as any,
      name: '',
      address: '',
      manager_first_name: '',
      manager_last_name: '',
      department: '',
      manager_address: '',
      manager_tel: '',
      map_file_id: null,
    },
  });

  const onCreate = async (data: PowerPlantModel) => {
    console.log('登録');
    // TODO: バリデーション処理
    console.log(data);

    ctx.spinner.setState(true);
    try {
      const response = await API.PowerPlant.create(data!);
      console.log(response);

      ctx.msgDialog.open('登録しました', () => {
        navigate('/manage/power_plant/');
      });
    } catch (e) {
      console.error(e);
    }
    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.Company.list();
        console.log(response);
        if (response.data) {
          // 削除済の発電事業者は選択肢から削除
          const tmp = response.data.filter((v) => v.deleted_at === undefined);
          companyList.current = tmp;
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="発電所新規登録" />
      <Breadcrumbs
        items={[
          { label: '発電所 管理', path: '/manage/power_plant/' },
          { label: '発電所 新規登録' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">発電所 新規登録</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="max-w-[800px] mx-auto flex flex-col gap-7">
          <div className="font-bold text-2xl text-re-063B67">
            発電所 基本情報
          </div>
          <FormProvider {...methods}>
            <PowerPlantEditFormBlock1 companyList={companyList.current} />
            <PowerPlantEditFormBlock2 />
          </FormProvider>
          <div className="flex justify-center gap-4">
            <Button
              text="戻る"
              outlined
              color="gray"
              link="/manage/power_plant/"
            />
            <Button text="登録する" onClick={methods.handleSubmit(onCreate)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePowerPlantCreatePage;
