import API from 'api';
import axios from 'axios';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Head from 'components/common/Head';
import UserEditFormBlock1 from 'components/manage/user/UserEditFormBlock1';
import UserEditFormBlock2 from 'components/manage/user/UserEditFormBlock2';
import UserEditFormBlock3 from 'components/manage/user/UserEditFormBlock3';
import { UserRoleType } from 'enums';
import useNavigate from 'hooks/use-navigate';
import { OutletContextModel, PowerPlantModel, UserModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { Store } from 'store';
import Utils from 'utils';

const SettingMypageEditPage: React.FC = () => {
  const navigate = useNavigate();
  const userData = Store.loadUserDara();
  const id = userData?.id ?? '';

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const initRoleType = React.useRef<UserRoleType>(); // 「権限」の初期状態
  const [data, setData] = React.useState<UserModel>();
  const [powerPlantList, setPowerPlantList] = React.useState<PowerPlantModel[]>(
    []
  );

  const methods = useForm<UserModel>({
    reValidateMode: 'onSubmit',
  });

  const onUpdate = async (data: UserModel) => {
    ctx.spinner.setState(true);
    try {
      const response = await API.User.update(data!);
      console.log(response);

      Store.updateUserDara(data);

      ctx.msgDialog.open('更新しました', () => {
        navigate('/setting/mypage');
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errors = e.response?.data.errors;
        if (errors && Array.isArray(errors)) {
          for (const error of errors) {
            methods.setError(error.param, { message: error.msg });
          }
        } else {
          console.error(e);
        }
      } else {
        console.error(e);
      }
    }
    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.User.get(id!);
        console.log(response);
        if (response.data) {
          methods.setValue('id', response.data.id);
          methods.setValue('type', response.data.type);
          methods.setValue('first_name', response.data.first_name);
          methods.setValue('last_name', response.data.last_name);
          methods.setValue('login_id', response.data.login_id);
          methods.setValue('company_name', response.data.company_name);
          methods.setValue('domain', response.data.domain);
          methods.setValue('department', response.data.department);
          methods.setValue('address', response.data.address);
          methods.setValue('tel', response.data.tel);
          methods.setValue('email', response.data.email);
          methods.setValue('power_plants', response.data.power_plants);

          setData(response.data);
          initRoleType.current = response.data.type as UserRoleType;

          const company_id = response.data.company_id;
          {
            const response = await API.PowerPlant.listInCompany(company_id);
            console.log(response);
            if (response.data) {
              setPowerPlantList(response.data);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="マイページ編集" />
      <Breadcrumbs
        items={[
          { label: 'マイページ', path: '/setting/mypage/' },
          { label: 'マイページ 編集' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">ユーザー情報 編集</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="max-w-[800px] mx-auto flex flex-col gap-7">
          <div className="font-bold text-2xl text-re-063B67">ユーザー情報</div>
          <FormProvider {...methods}>
            <UserEditFormBlock1
              initRoleType={initRoleType.current}
              companyList={[]}
            />
            <UserEditFormBlock2 />
            <UserEditFormBlock3
              powerPlantList={powerPlantList.filter(
                (v) => v.company_id === data?.company_id
              )}
            />
          </FormProvider>
          <div className="flex justify-center gap-4">
            <Button
              text="戻る"
              outlined
              color="gray"
              link={'/setting/mypage/'}
            />
            <Button text="更新する" onClick={methods.handleSubmit(onUpdate)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingMypageEditPage;
