import Button from 'components/common/Button';
import { AiPCSiModel, PCSModel } from 'models';
import React from 'react';
import { Store } from 'store';
import PowerPlantAiPCSiPanelItem from './PowerPlantAiPCSiPanelItem';

type PROPS = {
  power_plant_id: string;
  data: AiPCSiModel[] | undefined;
  pcsSet: { [aipcsi_id: string]: PCSModel[] };
  onCopyInfo?: () => void;
  onCSVDoanload?: () => void;
};

const PowerPlantAiPCSiPanel: React.FC<PROPS> = (props) => {
  const user = Store.loadUserDara();

  const power_plant_id = props.power_plant_id;

  // １件以上のAiPCSiが登録されていれば、true
  const hasAiPCSi = props.data && props.data.length > 0;

  return (
    <div className="drop-shadow rounded bg-white p-6">
      <div>
        <div className="font-bold text-2xl text-re-063B67">AiPCSi、PCS情報</div>
        <div className="mt-2 flex justify-between">
          <div className="flex gap-4">
            <Button
              text="AiPCSi、PCS情報をコピー"
              outlined
              onClick={props.onCopyInfo}
              disabled={!hasAiPCSi}
            />
            {user?.type === 'master' && (
              <Button
                text="CSVダウンロード"
                outlined
                onClick={props.onCSVDoanload}
                disabled={!hasAiPCSi}
              />
            )}
          </div>
          <div className="flex items-center gap-4">
            {user?.type === 'master' && (
              <>
                <Button
                  text="AiPCSi新規登録"
                  link={`/manage/power_plant/detail/${power_plant_id}/aipcsi/create`}
                />
                <Button
                  text="PCS新規登録"
                  link={`/manage/power_plant/detail/${power_plant_id}/pcs/create`}
                  disabled={!hasAiPCSi}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {props.data && props.data.length > 0 ? (
        props.data.map((AiPCSi, index) => {
          return (
            <PowerPlantAiPCSiPanelItem
              key={index}
              power_plant_id={power_plant_id}
              data={AiPCSi}
              pcs={props.pcsSet[AiPCSi.id!]}
            />
          );
        })
      ) : (
        <PowerPlantAiPCSiPanelItem />
      )}
    </div>
  );
};

export default PowerPlantAiPCSiPanel;
