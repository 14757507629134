import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Head from 'components/common/Head';
import UserChangePasswordFormBlock from 'components/manage/user/UserChangePasswordFormBlock';
import useNavigate from 'hooks/use-navigate';
import { OutletContextModel, UserChangePasswordModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';

const ManageUserChangePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const methods = useForm<UserChangePasswordModel>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      password: '',
      password_confirm: '',
    },
  });

  const onUpdate = async (data: UserChangePasswordModel) => {
    ctx.spinner.setState(true);
    try {
      const response = await API.User.changePassword(id!, data.password);
      console.log(response);
      ctx.msgDialog.open('パスワード変更しました', () => {
        navigate(`/manage/user/detail/${id}`);
      });
    } catch (e) {
      console.error(e);
      const errors = (e as any).response?.data?.errors;
      if (errors && Array.isArray(errors) && errors.length > 0) {
        ctx.msgDialog.open(errors[0].msg ?? 'パスワード変更できませんでした');
      } else {
        ctx.msgDialog.open('パスワード変更できませんでした');
      }
    }
    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!id) {
    return <></>;
  }

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="パスワード変更" />
      <Breadcrumbs
        items={[
          { label: 'ユーザー 管理', path: '/manage/user/' },
          { label: 'ユーザー 詳細', path: `/manage/user/detail/${id}` },
          { label: 'パスワード変更' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">
        ユーザー情報 パスワード変更
      </div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="max-w-[800px] mx-auto flex flex-col gap-7">
          <div className="font-bold text-2xl text-re-063B67">
            パスワード変更
          </div>
          <FormProvider {...methods}>
            <UserChangePasswordFormBlock />
          </FormProvider>
          <div className="flex justify-center gap-4">
            <Button
              text="戻る"
              outlined
              color="gray"
              link={`/manage/user/detail/${id}/`}
            />
            <Button text="変更する" onClick={methods.handleSubmit(onUpdate)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUserChangePasswordPage;
