import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { createDroppingDownAnime } from 'styles/transition';
import { BsCaretDownFill } from 'react-icons/bs';
import { Path, useFormContext } from 'react-hook-form';

type PROPS<T> = {
  fieldName: Path<T>; // fieldname

  validations?: object; // validation

  width?: string;

  disabled?: boolean;

  placeholder?: string;

  errorMessage?: string;
};

const TimePicker = <T,>({
  fieldName,
  validations,
  width = 'w-full',
  disabled,
  placeholder = '時間の指定',
  errorMessage,
}: PROPS<T>) => {
  const { register, getValues, setValue } = useFormContext();
  const nodeRef = React.useRef(null); // StrictModeエラーの対処用
  const selfRef = React.useRef<HTMLDivElement | null>(null);
  const [isShownItem, setIsShownItem] = React.useState(false);
  const dropDown = createDroppingDownAnime(0.1, -20);
  const time = getValues(fieldName) ? String(getValues(fieldName)) : '';

  // 時間
  const hourLists = Array(24)
    .fill(null)
    .map((_, i) => {
      return String(i).padStart(2, '0');
    });
  // 分
  const minuteLists = Array(60)
    .fill(null)
    .map((_, i) => {
      return String(i).padStart(2, '0');
    });

  // validationの発火
  React.useEffect(() => {
    register(fieldName, {
      ...validations,
    });
  }, [register, fieldName, validations]);

  // 領域外クリックでメニューを閉じる
  React.useEffect(() => {
    const el = selfRef.current;

    const hundleClickOutside = (e: MouseEvent) => {
      if (!el?.contains(e.target as Node)) {
        setIsShownItem(false);
      }
    };

    document.addEventListener('click', hundleClickOutside);

    return () => {
      document.removeEventListener('click', hundleClickOutside);
    };
  }, [selfRef]);

  const styles = {
    container: `relative`,
    selectBox: `cursor-pointer border-box flex items-center rounded-[6px] px-[16px]
      border 
      ${width} h-[40px]
      ${
        disabled
          ? `border-re-gray bg-re-smoke !cursor-default text-black`
          : errorMessage
          ? 'bg-re-red50 border-re-danger text-re-danger'
          : isShownItem
          ? 'border-re-blue200 bg-re-blue100 text-re-blue200'
          : 'border-re-gray bg-re-blue50 text-re-black'
      }
    `,
    value: `w-[95%] line-clamp-1 break-all ${!time && 'text-re-gray'}`,
    iconArea: `duration-200 ease-out ${isShownItem && '-scale-100'}`,
    optionsContainer: `${width} absolute z-10 bg-white drop-shadow`,
    optionsListBox: `w-1/2 overflow-y-scroll max-h-[200px]`,
    optionsBox: `flex items-center h-[50px] duration-200 px-[16px]
      hover:bg-re-blue100 cursor-pointer`,
    clear: `cursor-pointer inline-block bg-[#f0f0f0] w-[90%] py-1
    duration-200 hover:bg-[#e2e2e2]`,
  };

  return (
    <div
      className={styles.container}
      ref={selfRef}
      onClick={() => {
        !disabled && setIsShownItem(true);
      }}
    >
      <div className={styles.selectBox}>
        <div className={styles.value}>{time ? time : placeholder}</div>
        <div className={styles.iconArea}>
          <BsCaretDownFill />
        </div>
      </div>
      <div className="text-re-danger">{errorMessage}</div>

      {!disabled && (
        <CSSTransition
          in={isShownItem}
          timeout={{ enter: 0, exit: 100 }}
          unmountOnExit
          nodeRef={nodeRef}
        >
          {(state) => (
            <div className={styles.optionsContainer} style={dropDown[state]}>
              <div className="flex">
                <div className={styles.optionsListBox}>
                  {hourLists.map((v, i) => (
                    <div
                      key={i}
                      className={`${styles.optionsBox} ${
                        time.slice(0, 2) === v && 'bg-re-blue100'
                      }`}
                      onClick={() => {
                        const _hour = time
                          ? `${v}:${time.slice(3, 5)}`
                          : `${v}:00`;
                        setValue(fieldName, _hour as any, {
                          shouldValidate: true,
                        });
                      }}
                    >
                      <span className="cursor-pointer line-clamp-1 break-all">
                        {v}
                      </span>
                    </div>
                  ))}
                </div>

                <div className={styles.optionsListBox}>
                  {minuteLists.map((v, i) => (
                    <div
                      key={i}
                      className={`${styles.optionsBox} ${
                        time.slice(3, 5) === v && 'bg-re-blue100'
                      }`}
                      onClick={() => {
                        const _minute = time
                          ? `${time.slice(0, 2)}:${v}`
                          : `00:${v}`;
                        setValue(fieldName, _minute as any, {
                          shouldValidate: true,
                        });
                      }}
                    >
                      <span className="cursor-pointer line-clamp-1 break-all">
                        {v}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="text-center w-full my-2">
                <div
                  className={styles.clear}
                  onClick={() => {
                    setValue(fieldName, null as any, {
                      shouldValidate: true,
                    });
                  }}
                >
                  clear
                </div>
              </div>
            </div>
          )}
        </CSSTransition>
      )}
    </div>
  );
};

export default TimePicker;
