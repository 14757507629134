import React from 'react';
import { Store } from 'store';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import SelectBox from 'components/common/SelectBox';
import Button from 'components/common/Button';
import { UserRoleType } from 'enums';

type FormState = {
  company: string;
  powerPlant: string;
  map: string;
};

type Option = {
  label: string;
  value: string;
};

type PROPS = {
  user: Store.UserData | null;
  navigateState: { companyId: string; powerPlantId: string; mapId: string };
  companyOptions: Array<Option>;
  powerPlantOptions: Array<Option>;
  mapOptions: Array<Option>;
  setCompanyId: React.Dispatch<React.SetStateAction<string>>;
  setPowerPlantId: React.Dispatch<React.SetStateAction<string>>;
  setMapId: React.Dispatch<React.SetStateAction<string>>;
};

const MapTopMenu: React.FC<PROPS> = ({
  user,
  navigateState,
  companyOptions,
  powerPlantOptions,
  mapOptions,
  setCompanyId,
  setPowerPlantId,
  setMapId,
}: PROPS) => {
  const navigate = useNavigate();
  const methods = useForm<FormState>({
    defaultValues: {
      company: navigateState ? navigateState.companyId : '',
      powerPlant: navigateState ? navigateState.powerPlantId : '',
      map: navigateState ? navigateState.mapId : '',
    },
  });

  const [isMounted, setIsMounted] = React.useState(false);
  const _companyId = methods.watch('company');
  const _powerPlantId = methods.watch('powerPlant');
  const _mapId = methods.watch('map');

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  React.useEffect(() => {
    if (isMounted) {
      setCompanyId(_companyId);
      methods.setValue('powerPlant', '');
      methods.setValue('map', '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_companyId, setCompanyId, methods]);

  React.useEffect(() => {
    if (isMounted) {
      setPowerPlantId(_powerPlantId);
      methods.setValue('map', '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_powerPlantId, setPowerPlantId, methods]);

  React.useEffect(() => {
    if (isMounted) {
      setMapId(_mapId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_mapId, setMapId, methods]);

  return (
    <div className="grid grid-cols-3 max-2xl:grid-cols-2 gap-3 items-center z-[2] mb-4">
      <div className="flex col-span-3 2xl:col-span-2 items-center">
        <div className="font-bold text-2xl text-re-063B67">MAP表示</div>

        <FormProvider {...methods}>
          <div className="flex items-center ml-9">
            {user?.type === UserRoleType.MASTER && (
              <SelectBox<string>
                fieldName={'company'}
                width="w-[200px]"
                blank={''}
                blankLabel="発電事業者を選択"
                options={companyOptions}
              />
            )}

            <SelectBox<string>
              fieldName="powerPlant"
              width="w-[200px]"
              blank={''}
              blankLabel="発電所を選択"
              className="ml-3"
              options={powerPlantOptions}
            />

            <SelectBox<string>
              fieldName="map"
              width="w-[200px]"
              blank={''}
              blankLabel="Mapを選択"
              className="ml-3"
              options={mapOptions}
            />
          </div>
        </FormProvider>
      </div>

      <div className="flex 2xl:justify-end items-center">
        <Button
          text="報告一覧表示"
          size="sm"
          width="w-[145px]"
          outlined
          link="memo"
        />
        {user?.type !== UserRoleType.SUB && (
          <Button
            text="MAP設定"
            size="sm"
            outlined
            className="ml-9"
            onClick={() => {
              navigate('settings', {
                state: {
                  companyId: _companyId,
                  powerPlantId: _powerPlantId,
                  mapId: _mapId,
                },
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MapTopMenu;
