import React from 'react';
import useImage from 'use-image';
import { isMobile } from 'react-device-detect';
import { MapPinModel } from 'models';
import { Stage, Layer, Image, Label, Tag, Text } from 'react-konva';
import Konva from 'konva';
import Zoom from './Zoom';
import PinRedImage from 'assets/icons/pin_red.svg';
import PinBlueImage from 'assets/icons/pin_blue.svg';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';

type PROPS = {
  mapPin: MapPinModel | undefined;
  mapPinList: Array<MapPinModel>;
  src: string | undefined;
  height?: number;
  setMapPin?: React.Dispatch<React.SetStateAction<MapPinModel | undefined>>;
};

const MAX_SCALE = 6;
const MIN_SCALE = 0.1;
const CANVAS_SCALE = isMobile ? 0.25 : 1;

const Map: React.FC<PROPS> = ({
  mapPin,
  mapPinList,
  src,
  height,
  setMapPin,
}: PROPS) => {
  const [mapImage] = useImage(src || '');
  const [PinRed] = useImage(PinRedImage);
  const [PinBlue] = useImage(PinBlueImage);
  const ref = React.useRef<HTMLDivElement>(null);
  const transformComponentRef = React.useRef<ReactZoomPanPinchRef | null>(null);

  const handleSetNewLocation = (
    e: Konva.KonvaEventObject<MouseEvent> | Konva.KonvaEventObject<TouchEvent>
  ) => {
    if (setMapPin) {
      setMapPin(undefined);
      const _pos = e.target.getStage()?.getRelativePointerPosition();

      if (_pos) {
        const _data: MapPinModel = {
          id: '',
          title: '',
          coord_x: isMobile ? _pos.x * (1 / CANVAS_SCALE) : _pos.x,
          coord_y: isMobile ? _pos.y * (1 / CANVAS_SCALE) : _pos.y,
        };

        setMapPin(_data);
      }
    }
  };

  return (
    <>
      <div
        ref={ref}
        className="relative w-full bg-re-smoke overflow-hidden z-[1]"
        style={{ height: height ? `${height}px` : '100%' }}
      >
        {mapImage ? (
          <>
            <TransformWrapper
              ref={transformComponentRef}
              initialScale={1}
              maxScale={MAX_SCALE}
              minScale={MIN_SCALE}
              doubleClick={{ disabled: true }}
              wheel={{ disabled: true }}
            >
              {({ zoomIn, zoomOut }) => (
                <>
                  <TransformComponent
                    wrapperStyle={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {mapImage && (
                      <Stage
                        width={
                          mapImage ? (mapImage.width + 400) * CANVAS_SCALE : 0
                        }
                        height={
                          mapImage ? (mapImage.height + 300) * CANVAS_SCALE : 0
                        }
                        className="cursor-pointer"
                      >
                        <Layer>
                          <Image
                            image={mapImage}
                            width={mapImage ? mapImage.width * CANVAS_SCALE : 0}
                            height={
                              mapImage ? mapImage.height * CANVAS_SCALE : 0
                            }
                            x={
                              mapImage &&
                              ((mapImage.width + 400) * CANVAS_SCALE -
                                mapImage.width * CANVAS_SCALE) /
                                2
                            }
                            y={
                              mapImage &&
                              ((mapImage.height + 300) * CANVAS_SCALE -
                                mapImage.height * CANVAS_SCALE) /
                                2
                            }
                            onDblClick={handleSetNewLocation}
                            onDblTap={handleSetNewLocation}
                          />

                          {mapPinList.length > 0 &&
                            mapPinList.map((v, i) => (
                              <React.Fragment key={i}>
                                {mapPin === v && (
                                  <Label
                                    x={Number(v.coord_x * CANVAS_SCALE)}
                                    y={Number(v.coord_y * CANVAS_SCALE) - 42}
                                  >
                                    <Tag
                                      fill="#fff"
                                      stroke="#000"
                                      pointerDirection="down"
                                      pointerWidth={10}
                                      pointerHeight={10}
                                      cornerRadius={10}
                                    />

                                    <Text
                                      text={v.title}
                                      fontSize={20}
                                      width={250}
                                      padding={15}
                                      align="center"
                                    />
                                  </Label>
                                )}

                                <Image
                                  image={mapPin === v ? PinBlue : PinRed}
                                  width={30}
                                  height={48}
                                  x={Number(v.coord_x * CANVAS_SCALE) - 15}
                                  y={Number(v.coord_y * CANVAS_SCALE) - 42}
                                  onClick={() => {
                                    setMapPin && setMapPin(v);
                                  }}
                                  onTap={() => {
                                    setMapPin && setMapPin(v);
                                  }}
                                />
                              </React.Fragment>
                            ))}
                        </Layer>
                      </Stage>
                    )}
                  </TransformComponent>

                  {!isMobile && (
                    <div className="absolute bottom-0 right-0 mr-[40px] mb-[40px] z-10">
                      <Zoom zoomIn={zoomIn} zoomOut={zoomOut} />
                    </div>
                  )}
                </>
              )}
            </TransformWrapper>
          </>
        ) : (
          <div className="flex h-full justify-center items-center">
            <p>{src === '' ? 'MAPの登録がありません' : 'プレビュー'}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Map;
