import { Path, FieldValues, useFormContext } from 'react-hook-form';

type Props<T> = {
  fieldName: Path<T>; // fieldname

  validations?: object; // validation

  width?: string;

  rows?: number; // 行数

  placeholder?: string;

  disabled?: boolean;

  readonly?: boolean;

  label?: string;

  require?: boolean;

  labelWidth?: string;

  errorMessage?: string;
};

const TextArea = <T extends FieldValues = never>({
  fieldName,
  validations,
  width,
  rows = 4,
  placeholder,
  disabled,
  readonly,
  label,
  require,
  labelWidth,
  errorMessage,
}: Props<T>) => {
  const { register } = useFormContext();

  const style = {
    container: `flex w-full`,
    label: `${labelWidth ? labelWidth : 'w-1/4'}`,
    labelText: `whitespace-nowrap font-medium`,
    require: `whitespace-nowrap ml-[8px] ${
      require ? 'text-re-danger' : 'text-re-gray'
    }`,
    textAreaBox: `block ${
      label && !width ? 'w-3/4' : width ? width : 'w-full'
    }`,
    textArea: `outline outline-1 px-[16px] py-[9px] -mb-1 placeholder-re-gray font-normal duration-200
    ${
      !readonly &&
      !errorMessage &&
      'focus:outline-re-blue200 focus:bg-re-blue100 focus:caret-re-blue200'
    }
    w-full
    ${
      disabled
        ? 'outline-re-gray bg-re-smoke'
        : errorMessage
        ? 'outline-re-danger bg-re-red50'
        : 'outline-re-gray bg-re-blue50'
    }
    `,
  };

  return (
    <div className={style.container}>
      {label && (
        <div className={style.label}>
          <label className={style.labelText}>{label}</label>
          <span className={style.require}>{require ? '必須' : '任意'}</span>
        </div>
      )}

      <div className={style.textAreaBox}>
        <textarea
          className={style.textArea}
          rows={rows}
          placeholder={placeholder}
          readOnly={readonly}
          disabled={disabled}
          {...register(fieldName, validations)}
        />
        <div className="text-re-danger ">{errorMessage}</div>
      </div>
    </div>
  );
};

export default TextArea;
