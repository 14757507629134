import FormLabel from 'components/common/FormLabel';
import TextField from 'components/common/TextField';
import { CompanyModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {};

const CompanyEditFormBlock2: React.FC<PROPS> = (props) => {
  const { formState } = useFormContext<CompanyModel>();

  return (
    <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
      <div className="font-bold text-xl text-re-063B67">
        発電事業者&nbsp;担当者情報
      </div>

      <div className="flex gap-2">
        <FormLabel text="管理者名" required />
        <div className="flex-1 flex gap-4">
          <TextField
            fieldName="manager_last_name"
            placeholder="苗字"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.manager_last_name?.message}
          />
          <TextField
            fieldName="manager_first_name"
            placeholder="名前"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.manager_first_name?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="メールアドレス" required />
        <div className="flex-1">
          <TextField
            fieldName="manager_email"
            placeholder="例: xxxx@sample.com"
            validations={Utils.emailValidation()}
            errorMessage={formState.errors.manager_email?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="所属・部署" required={false} />
        <div className="flex-1">
          <TextField
            fieldName="department"
            placeholder="例：◯◯◯事業部"
            validations={{}}
            errorMessage={formState.errors.department?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="住所" required={false} />
        <div className="flex-1">
          <TextField
            fieldName="manager_address"
            placeholder="例：◯◯県◯◯市◯◯"
            validations={{}}
            errorMessage={formState.errors.manager_address?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="電話番号" required />
        <div className="flex-1">
          <TextField
            fieldName="manager_tel"
            placeholder="0000-0000-0000"
            validations={Utils.telValidation()}
            errorMessage={formState.errors.manager_tel?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyEditFormBlock2;
