import FormLabel from 'components/common/FormLabel';
import TextField from 'components/common/TextField';
import { UserChangePasswordModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {};

const UserChangePasswordFormBlock: React.FC<PROPS> = (props) => {
  const { formState, getValues } = useFormContext<UserChangePasswordModel>();

  return (
    <div className="flex flex-col gap-8 [&_.form-label]:w-1/3">
      <div className="font-bold text-xl text-re-063B67">アカウント</div>
      <div>
        全て半角で、大文字英語・小文字英語・数字・記号を組み合わせた10文字以上にしてください
      </div>
      <div className="flex gap-2">
        <FormLabel text="新しいパスワード" required />
        <div className="flex-1">
          <TextField
            fieldName="password"
            fieldType="password"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.password?.message}
          />
        </div>
      </div>
      <div className="flex gap-2">
        <FormLabel text="新しいパスワード(確認)" required />
        <div className="flex-1">
          <TextField
            fieldName="password_confirm"
            fieldType="password"
            validations={{
              required: '必須項目です',
              validate: (v: string) => {
                if (v !== getValues().password) {
                  return '一致していません';
                }
              },
            }}
            errorMessage={formState.errors.password_confirm?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default UserChangePasswordFormBlock;
