import React from 'react';
import { Helmet } from 'react-helmet-async';

type PROPS = {
  title?: string;
};

const Head: React.FC<PROPS> = (props) => {
  return (
    <Helmet>
      <title>{props.title ? `${props.title} | AiPCSi` : 'AiPCSi'}</title>
    </Helmet>
  );
};

export default Head;
