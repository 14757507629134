import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import PowerPlantEditFormBlock1 from 'components/manage/power_plant/PowerPlantEditFormBlock1';
import PowerPlantEditFormBlock2 from 'components/manage/power_plant/PowerPlantEditFormBlock2';
import dayjs from 'dayjs';
import useNavigate from 'hooks/use-navigate';
import { CompanyModel, OutletContextModel, PowerPlantModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';

const ManagePowerPlantEditPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const companyList = React.useRef<CompanyModel[]>([]);

  const [deleteConfirmDialogFlg, setDeleteConfirmDialogFlg] =
    React.useState(false);

  const methods = useForm<PowerPlantModel>({
    reValidateMode: 'onSubmit',
  });

  const onUpdate = async (data: PowerPlantModel) => {
    console.log('更新');
    console.log(data);

    ctx.spinner.setState(true);
    try {
      const response = await API.PowerPlant.update(data!);
      console.log(response);
      ctx.msgDialog.open('更新しました', () => {
        navigate(`/manage/power_plant/detail/${id}/`);
      });
    } catch (e) {
      console.error(e);
    }

    ctx.spinner.setState(false);
  };

  const onDelete = () => {
    setDeleteConfirmDialogFlg(true);
  };

  const onCloseDeleteConfirm = async (result?: boolean) => {
    setDeleteConfirmDialogFlg(false);
    if (!result) {
      return;
    }
    ctx.spinner.setState(true);
    try {
      const response = await API.PowerPlant.del(id!);
      console.log(response);
      ctx.msgDialog.open('削除しました', () => {
        navigate(`/manage/power_plant/`);
      });
    } catch (e) {
      console.error(e);
    }

    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        {
          const response = await API.Company.list();
          console.log(response);
          if (response.data) {
            // 削除済みの発電事業者は除外する
            const tmp = response.data.filter(v => {
              if (v.deleted_at && dayjs(v.deleted_at).isValid()) {
                return false;
              }
              return true;
            })
            companyList.current = tmp;
          }
        }
        const response = await API.PowerPlant.get(id);
        console.log(response);
        if (response.data) {
          methods.setValue('id', response.data.id);
          methods.setValue('company_id', response.data.company_id);
          methods.setValue('name', response.data.name);
          methods.setValue('address', response.data.address);
          methods.setValue(
            'manager_first_name',
            response.data.manager_first_name
          );
          methods.setValue(
            'manager_last_name',
            response.data.manager_last_name
          );
          methods.setValue('department', response.data.department);
          methods.setValue('manager_tel', response.data.manager_tel);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="発電所編集" />
      <Breadcrumbs
        items={[
          { label: '発電所 管理', path: '/manage/power_plant/' },
          { label: '発電所 編集' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">発電所 編集</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="max-w-[800px] mx-auto flex flex-col gap-7">
          <div className="font-bold text-2xl text-re-063B67">
            発電所 基本情報
          </div>
          <FormProvider {...methods}>
            <PowerPlantEditFormBlock1 companyList={companyList.current} />
            <PowerPlantEditFormBlock2 />
          </FormProvider>
          <div className="flex justify-center gap-4">
            <Button
              text="戻る"
              outlined
              color="gray"
              link={`/manage/power_plant/detail/${id}/`}
            />
            <Button text="更新する" onClick={methods.handleSubmit(onUpdate)} />
          </div>
          <div className="flex justify-center gap-4">
            <button
              className="text-re-danger hover:underline"
              onClick={onDelete}
            >
              発電所 削除
            </button>
          </div>
        </div>
      </div>
      {/* 削除確認ダイアログ */}
      <Dialog
        width="w-[997px]"
        show={deleteConfirmDialogFlg}
        onClose={onCloseDeleteConfirm}
      >
        <div className="flex flex-col items-center gap-8">
          <div className="font-bold text-xl text-re-danger">発電所 削除</div>
          <div className="font-bold text-xl text-re-danger">
            対象:{methods.getValues().name}
          </div>
          <div className="text-center">
            <div>
              発電所を削除すると、アドミン、メイン、サブは閲覧できなくなります。
            </div>
            <div>
              (マスターの画面には「削除」ステータスになりデータは残ります)
            </div>
          </div>

          <div className="flex justify-center gap-4">
            <button
              className="w-56 border border-re-gray py-2 rounded-lg"
              onClick={() => {
                onCloseDeleteConfirm();
              }}
            >
              <span className="font-bold text-re-gray">しない</span>
            </button>
            <button
              className="w-56 bg-re-be1a1a py-2 rounded-lg hover:bg-re-danger-hover duration-100 transition-colors"
              onClick={() => {
                onCloseDeleteConfirm(true);
              }}
            >
              <span className="font-bold text-white">削除</span>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ManagePowerPlantEditPage;
