import React from 'react';
import Link from './Link';

type PROPS = {
  color?: 'primary' | 'danger' | 'gray';
  width?: string;
  height?: string;
  size?: 'lg' | 'md' | 'sm';
  outlined?: boolean;
  text: string;
  link?: string; // ボタンLink
  linkState?: any;
  openTab?: boolean; // Linkを別タブで開く
  disabled?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler;
};

const Button: React.FC<PROPS> = ({
  color = 'primary',
  width,
  height,
  size = 'md',
  outlined,
  text,
  link,
  linkState,
  openTab,
  disabled,
  className,
  onClick,
}: PROPS) => {
  const baseWidth =
    size === 'lg' ? 'w-[240px]' : size === 'md' ? 'w-[220px]' : 'w-[97px]';

  const baseHeight =
    size === 'lg' ? 'h-[56px]' : size === 'md' ? 'h-[44px]' : 'h-[30px]';

  const baseRounded = size === 'sm' ? 'rounded-[4px]' : 'rounded-[6px]';

  const baseFillColor =
    color === 'primary'
      ? 'bg-re-primary hover:bg-re-primary-hover active:bg-re-primary-active text-white'
      : color === 'danger'
      ? 'bg-re-danger hover:bg-re-danger-hover text-white active:text-re-danger-active'
      : 'bg-re-gray hover:bg-re-gray-hover text-white active:text-re-gray-active';

  const baseOutlinedColor =
    color === 'primary'
      ? 'border-re-primary hover:border-re-primary-hover active:border-re-primary-active text-re-primary hover:text-re-primary-hover active:text-re-primary-active'
      : color === 'danger'
      ? 'border-re-danger hover:border-re-danger-hover text-re-danger hover:text-re-danger-hover active:text-re-danger-active'
      : 'border-re-gray hover:border-re-gray-hover text-re-gray hover:text-re-gray-hover active:text-re-gray-active';

  const style = {
    container: `flex items-center justify-center duration-300 font-bold select-none
      ${width ? width : baseWidth}
      ${height ? height : baseHeight}
      ${baseRounded}
      ${
        disabled
          ? 'cursor-default bg-re-smoke text-white pointer-events-none'
          : outlined
          ? `border bg-white ${baseOutlinedColor}`
          : baseFillColor
      }`,
  };
  return (
    <div className={className}>
      {link && !disabled ? (
        <Link to={link} state={linkState} target={openTab ? '_blank' : ''}>
          <button className={style.container}>{text}</button>
        </Link>
      ) : (
        <button
          className={style.container}
          onClick={(e) => {
            onClick && onClick(e);
          }}
        >
          {text}
        </button>
      )}
    </div>
  );
};

export default Button;
