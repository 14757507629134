import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Head from 'components/common/Head';
import Link from 'components/common/Link';
import Pagination from 'components/common/pagination/Pagination';
import TextField from 'components/common/TextField';
import dayjs from 'dayjs';
import { getAlertColor, getAlertTypeStr } from 'enums';
import { AlertModel, OutletContextModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import Utils from 'utils';

type FormState = {
  keyword: string;
};

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;

const MonitorAlertPage: React.FC = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const methods = useForm<FormState>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      keyword: '',
    },
  });

  const [data, setData] = React.useState<AlertModel[]>([]);
  const [filtered, setFiltered] = React.useState<AlertModel[]>([]);
  const [items, setItems] = React.useState<AlertModel[]>([]);
  const [page, setPage] = React.useState(1);

  const getMessage = (item: AlertModel) => {
    switch (item.sensor_name) {
      case 'indoor_temperature_status':
        return '故障予兆: 室内温度';
      case 'cooling_fan_vibration_status':
        return '故障予兆: 冷却ファン';
      case 'inverter_current_u_status':
        return '故障予兆: U電流';
      case 'inverter_current_v_status':
        return '故障予兆: V電流';
      case 'inverter_current_w_status':
        return '故障予兆: W電流';
      case 'emi_level_status':
        return '故障予兆: EMI';
      case 'logger_status':
        return 'AiPCSiシステム';
      default:
        return '';
    }
  };

  const onFilter = () => {
    let filtered = data;
    const filterText = methods.getValues().keyword;
    if (filterText !== '') {
      filtered = data.filter(
        (v) =>
          v.power_plant_name.includes(filterText) ||
          v.pcs_name.includes(filterText)
      );
    }
    setPage(1);
    setFiltered(filtered);
    setItems(filtered.slice(0, NUM_IN_PAGE));
  };

  const onChangePage = (page: number) => {
    setPage(page);
    if (!filtered) {
      setItems([]);
      return;
    }

    const start = NUM_IN_PAGE * (page - 1);
    const end = start + NUM_IN_PAGE;
    setItems(filtered.slice(start, end));
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.Alert.log();
        console.log(response);
        if (response.data) {
          setData(response.data);
          setFiltered(response.data);
          setItems(response.data.slice(NUM_IN_PAGE * (page - 1), NUM_IN_PAGE));
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4 max-w-[calc(max(100vw,1200px)_-_15rem)]">
      <Head title="アラート一覧" />
      <Breadcrumbs items={[{ label: 'アラート一覧' }]} />
      <div className="font-bold text-3xl text-re-063B67">アラート一覧</div>
      <Card className="p-6">
        <div className="flex items-center gap-10">
          <div className="flex-1 font-bold text-2xl text-re-063B67">
            アラート 履歴
          </div>
          <div className="flex items-center gap-1">
            <FormProvider {...methods}>
              <TextField
                fieldName="keyword"
                width="w-[320px]"
                placeholder="例： 発電所名 や インバーター など"
              />
            </FormProvider>
            <Button text="絞り込み" size="sm" onClick={onFilter} />
          </div>
        </div>

        <div className="overflow-x-auto w-full">
          <table className="min-w-full my-4 border border-re-gray rounded-lg border-separate border-spacing-0">
            <thead>
              <tr className="bg-re-4D7FAF text-white [&_th]:whitespace-nowrap">
                <th className="w-[10%] p-2 rounded-tl-lg">アラート発信時間</th>
                <th className="w-[8%] p-2">ステータス</th>
                <th className="w-[8%] p-2">アラート</th>
                <th className="w-[16%] p-2 min-w-[16rem]">アラート内容</th>
                <th className="w-[20%] p-2 min-w-[12rem]">発電所</th>
                <th className="w-[8%] p-2">PCSの名称</th>
                <th className="w-[10%] p-2 rounded-tr-lg">アラート詳細</th>
              </tr>
            </thead>
            <tbody>
              {items.map((v, i) => (
                <tr key={i} className="border border-re-gray">
                  <td className="p-4 font-medium">
                    {dayjs(v.captured_at).format('YYYY/MM/DD HH:mm:ss')}
                  </td>
                  {v.is_canceled ? (
                    <td className="p-4 font-bold text-center">
                      <span className="whitespace-nowrap">解除済</span>
                    </td>
                  ) : (
                    <td className="p-4 font-bold text-center ">
                      <span className="text-re-danger whitespace-nowrap">
                        未解除
                      </span>
                    </td>
                  )}
                  <td className="p-4 font-bold text-center">
                    <div
                      className={`px-4 py-2 text-white ${getAlertColor(
                        v.type
                      )} border-4`}
                    >
                      <span className="whitespace-nowrap">
                        {getAlertTypeStr(v.type)}
                      </span>
                    </div>
                  </td>
                  <td className="p-4 font-medium">{getMessage(v)}</td>
                  <td className="p-4 font-bold">{v.power_plant_name}</td>
                  <td className="p-4 font-bold">{v.pcs_name}</td>

                  <td className="p-4 group-last:rounded-br-lg">
                    <Link
                      to={`/monitor/alert/detail/${v.id}`}
                      className="flex justify-center"
                    >
                      <Button text="詳細" size="sm" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <Pagination
            current={page}
            count={Math.ceil(filtered.length / NUM_IN_PAGE)}
            onChange={onChangePage}
          />
        </div>
      </Card>
    </div>
  );
};

export default MonitorAlertPage;
