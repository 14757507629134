import React from 'react';

type PROPS = {
  zoomIn: () => void;
  zoomOut: () => void;
};

const Zoom: React.FC<PROPS> = ({ zoomIn, zoomOut }: PROPS) => {
  const style = {
    container: `bg-white rounded-[6px] drop-shadow`,
    contentBox: `flex items-center w-[60px] h-[60px] justify-center cursor-pointer duration-200
      text-re-primary text-[18px] font-bold select-none active:bg-re-primary-active active:text-white
    `,
    activeTop: 'rounded-t-[6px]',
    activeBottom: 'rounded-b-[6px]',
  };
  return (
    <div className={style.container}>
      <div
        className={`${style.contentBox} ${style.activeTop}`}
        onClick={() => {
          zoomIn();
        }}
      >
        ＋
      </div>
      <div
        className={`${style.contentBox} ${style.activeBottom}`}
        onClick={() => {
          zoomOut();
        }}
      >
        −
      </div>
    </div>
  );
};

export default Zoom;
