export const enum ApprovalStatusType {
  DISPLAY = 'display',
  PENDING = 'pending',
  PENDINGUPDATE = 'pending-update',
  PENDINGDELETE = 'pending-delete',
}

export const enum ApprovalDialogType {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

/** 「承認待ちステータス」の文字列を返します。 */
export const getMemoStatusStr = (status: string): string => {
  switch (status) {
    case ApprovalStatusType.PENDING:
      return 'コメント';
    case ApprovalStatusType.PENDINGUPDATE:
      return '更新';
    case ApprovalStatusType.PENDINGDELETE:
      return '削除';
    default:
      return '';
  }
};

/** 「承認待ちステータス」の文字列を返します。 */
export const getMemoStatusMessageStr = (status: string): string => {
  switch (status) {
    case ApprovalStatusType.PENDING:
      return 'コメントがありました';
    case ApprovalStatusType.PENDINGUPDATE:
      return 'コメント編集がありました';
    case ApprovalStatusType.PENDINGDELETE:
      return 'このコメントを削除しようとしています';
    default:
      return '';
  }
};
