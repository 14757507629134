import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Head from 'components/common/Head';
import Link from 'components/common/Link';
import AlertCard from 'components/home/AlertCard';
import { UserRoleType } from 'enums';
import {
  HomeAlertPowerPlantModel,
  InformationModel,
  OutletContextModel,
} from 'models';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Store } from 'store';
import Utils from 'utils';

type ListData = {
  company_name: string;
  data: HomeAlertPowerPlantModel;
};

const HomePage: React.FC = () => {
  const user = Store.loadUserDara();
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);
  const [information, setInformation] = React.useState<InformationModel[]>([]);
  const alertCount = React.useRef(0);
  const [pendingMemoCount, setPendingMemoCount] = React.useState('');

  const [data, setData] = React.useState<ListData[]>([]);

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        // 「お知らせ」の取得
        const _information = await API.Information.presentList();
        if (_information.data) {
          setInformation(_information.data);
        }
        // 認証待ちコメント数の取得
        if (user?.type !== UserRoleType.SUB) {
          const _count = await API.Memo.getPendingMemoCount();
          if (_count.data) {
            setPendingMemoCount(_count.data);
          }
        }
        // アラート情報の取得
        const alerts = await API.Alert.now();
        if (alerts.data) {
          const tmp: ListData[] = [];
          // 表示用にデータ構造を変換
          for (let data of alerts.data) {
            for (let powerPlant of data.power_plants) {
              // 発電所ごとのアラートの数を設定
              powerPlant.count = powerPlant.pcs.reduce(
                (sum, pcs) => sum + pcs.alerts.length,
                0
              );
              tmp.push({
                company_name: data.company_name,
                data: powerPlant,
              });
            }
          }
          // 全アラートの数を設定
          alertCount.current = tmp.reduce(
            (sum, item) => sum + (item.data.count ?? 0),
            0
          );

          // アラートがある発電所のデータを前にソート
          const tmp2 = tmp.sort((a, b) => {
            const aCount = a.data.count ?? 0;
            const bCount = b.data.count ?? 0;
            if (aCount === 0 && bCount > 0) {
              return 1;
            } else if (aCount > 0 && bCount === 0) {
              return -1;
            } else {
              return 0;
            }
          });

          setData(tmp2);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full bg-re-F3F7FA px-8 py-2 pb-20 flex flex-col gap-4">
      <Head title="HOME" />
      <Breadcrumbs items={[]} />

      <Card className="p-6">
        <div className="flex items-center">
          <div className="flex-1 font-bold text-2xl text-re-063B67">
            お知らせ
          </div>
          <Button
            text="お知らせ履歴"
            size="sm"
            width="w-[145px]"
            outlined
            link="/inbox"
          />
        </div>

        <div className="mt-6">
          {information.length > 0 ? (
            information.map((v) => (
              <Link
                to={`/inbox/detail/${v.id}`}
                className="w-fit line-clamp-1 break-all hover:text-re-primary-hover duration-200"
                key={v.id}
              >
                {v.title}
              </Link>
            ))
          ) : (
            <p>新しいお知らせはありません</p>
          )}

          {user?.type !== UserRoleType.SUB && (
            <>
              {Number(pendingMemoCount) > 0 && (
                <>
                  認証待ちのコメントが&nbsp;{pendingMemoCount}
                  &nbsp;件あります&nbsp;&nbsp;
                  <Link
                    to="/monitor/map/approve"
                    className="text-re-primary underline"
                  >
                    内容確認
                  </Link>
                </>
              )}
            </>
          )}
        </div>
      </Card>

      <Card className="p-6 mt-4">
        <>
          <div className="flex items-center">
            <div className="font-bold text-2xl text-re-063B67">
              未確認のアラート一覧
            </div>
          </div>

          <p className="mt-4 mb-3 font-bold">
            アラート未解除{' '}
            <span className="text-re-danger">{alertCount.current}件</span>
          </p>

          <div className="flex flex-col gap-4">
            {data.map((alert, index) => {
              return (
                <div key={index}>
                  <AlertCard
                    company_name={alert.company_name}
                    data={alert.data}
                  />
                </div>
              );
            })}
          </div>
        </>
      </Card>
    </div>
  );
};

export default HomePage;
