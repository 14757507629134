import Pagination from 'components/common/pagination/Pagination';
import dayjs from 'dayjs';
import { PCSLogModel } from 'models';
import React from 'react';

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;

type TableItem = {
  datetime: string;
  cooling_fan_vibration: number;
  indoor_temperature: number;
  inverter_u: number;
  inverter_v: number;
  inverter_w: number;
  emi_level: number;
};

type PROPS = {
  isAIDiagnosis: boolean;
  date?: string;
  data?: PCSLogModel;
};

const Table: React.FC<PROPS> = (props) => {
  const [data, setData] = React.useState<TableItem[]>([]);
  const [items, setItems] = React.useState<TableItem[]>([]);
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    if (!props.data) {
      setData([]);
      setItems([]);
      return;
    }
    const tmp: TableItem[] = [];
    for (let i = 0; i < props.data.cooling_fan_vibration.length; i++) {
      let datetime = dayjs(props.date);
      datetime = datetime.add(i, 'minute');
      tmp.push({
        datetime: datetime.format('YYYY/MM/DD\nHH:mm:ss'),
        cooling_fan_vibration: Number(props.data.cooling_fan_vibration[i]),
        indoor_temperature: Number(props.data.indoor_temperature[i]),
        inverter_u: Number(props.data.inverter_u[i]),
        inverter_v: Number(props.data.inverter_v[i]),
        inverter_w: Number(props.data.inverter_w[i]),
        emi_level: Number(props.data.emi_level[i]),
      });
    }
    setPage(1);
    setData(tmp);
  }, [props.data, props.date, props.isAIDiagnosis]);

  React.useEffect(() => {
    const start = NUM_IN_PAGE * (page - 1);
    const end = start + NUM_IN_PAGE;
    setItems(data.slice(start, end));
  }, [page, data]);

  return (
    <div>
      <table className="w-full my-4 border border-re-gray rounded-lg border-separate border-spacing-0">
        <thead>
          <tr className="bg-re-4D7FAF text-white [&>*]:p-2">
            <th className="rounded-tl-lg">取得時間</th>
            <th>室内温度</th>
            <th>振動</th>
            <th>U電流</th>
            <th>V電流</th>
            <th>W電流</th>
            <th className="rounded-tr-lg">EMI</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <tr key={index} className={`group [&>*]:p-4`}>
                <td className="group-last:rounded-bl-lg">
                  <span className="whitespace-pre-line">{item.datetime}</span>
                </td>
                <td>{item.indoor_temperature.toFixed(2)}</td>
                <td>{item.cooling_fan_vibration.toFixed(2)}</td>
                <td>{item.inverter_u.toFixed(2)}</td>
                <td>{item.inverter_v.toFixed(2)}</td>
                <td>{item.inverter_w.toFixed(2)}</td>
                <td className="group-last:rounded-br-lg">
                  {item.emi_level.toFixed(2)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <Pagination
          current={page}
          count={Math.ceil(data.length / NUM_IN_PAGE)}
          onChange={(page) => {
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default Table;
