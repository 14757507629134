import Card from 'components/common/Card';
import dayjs from 'dayjs';
import { getAlertColor, getAlertTypeStr } from 'enums';
import { AlertTreeAPIModel, AlertTreeModel } from 'models';
import React from 'react';
import Utils from 'utils';

type PROPS = {
  data?: AlertTreeAPIModel;
  setShowAlertList: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileAlertTransitionCard: React.FC<PROPS> = ({
  data,
  setShowAlertList,
}: PROPS) => {
  // 最新のアラート
  const [last3, setLast3] = React.useState<AlertTreeModel[]>([]);
  // 初回アラート
  const [firstAlert, setFirstAlert] = React.useState<AlertTreeModel>();

  React.useEffect(() => {
    setFirstAlert(undefined);
    if (data) {
      setLast3(data.alert_tree.slice(0, 3));
      if (data.alert_tree.length > 0 && data.alert_tree[0].first_alert_id) {
        const first_alert_id = data.alert_tree[0].first_alert_id;
        const target = data.alert_tree.find((v) => v.id === first_alert_id);
        if (target) {
          setFirstAlert(target);
        }
      }
    } else {
      setLast3([]);
    }
  }, [data]);

  return (
    <Card className="p-6 mb-3">
      <div className="font-bold text-2xl text-re-063B67 mb-8">アラート 推移</div>
      <div className="mb-8">
        <div className="font-bold text-xl text-re-063B67 mb-6">アラート ログ</div>
        <div>
          {last3.map((alert, index) => {
            return (
              <div key={index} className="mb-6">
                <div className="font-medium text-sm mb-2">
                  {dayjs(alert.captured_at).format('YYYY/MM/DD HH:mm:ss')}
                </div>
                <div className="font-medium text-sm mb-2 w-28 text-center">
                  <div
                    className={`px-6 p-1 font-bold
            ${getAlertColor(alert.type)} border-4`}
                  >
                    {getAlertTypeStr(alert.type)}
                  </div>
                </div>
                <div className="font-bold text-lg mb-2">
                  {Utils.Alert.getMessage(alert)}
                </div>
                <div className="font-medium text-sm">
                  {Utils.Alert.getSubMessage(alert)}
                </div>
              </div>
            );
          })}
          <p
            className="mt-2 text-sm underline text-re-primary cursor-pointer"
            onClick={() => setShowAlertList(true)}
          >
            もっと見る
          </p>
        </div>
      </div>
      {firstAlert && (
        <div>
          <div className="font-bold text-xl text-re-063B67 mb-8">初回アラート</div>
          <div>
            <div className="font-medium text-sm mb-2">
              {dayjs(firstAlert.captured_at).format('YYYY/MM/DD HH:mm:ss')}
            </div>
            <div className="font-medium text-sm mb-2 w-28 text-center">
              <div
                className={`px-6 p-1 font-bold
            ${getAlertColor(firstAlert.type)} border-4`}
              >
                {getAlertTypeStr(firstAlert.type)}
              </div>
            </div>
            <div className="font-bold text-lg mb-2">
              {Utils.Alert.getMessage(firstAlert)}
            </div>
            <div className="font-medium text-sm">
              {Utils.Alert.getSubMessage(firstAlert)}
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default MobileAlertTransitionCard;
