import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Head from 'components/common/Head';
import SelectBox from 'components/common/SelectBox';
import GraphArea from 'components/monitor/failure/GraphArea';
import PCSStatus from 'components/monitor/failure/PCSStatus';
import dayjs from 'dayjs';
import { UserRoleType } from 'enums';
import { FailureModel, OutletContextModel, PowerPlantModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Store } from 'store';
import Utils from 'utils';

type FormState = {
  company: string;
  powerPlant: string;
  pcs: string;
};

type Option = {
  label: string;
  value: string;
};

const MonitorFailurePage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const methods = useForm<FormState>({
    defaultValues: {
      company: location.state?.company_id ?? '',
      powerPlant: '',
      pcs: '',
    },
  });

  const user = React.useRef(Store.loadUserDara());
  const spinner = React.useRef(ctx.spinner);

  const processedCompanyID = React.useRef('');
  const processedPowerPlantID = React.useRef('');
  const processedPCSID = React.useRef('');
  const [companyOptions, setCompanyOptions] = React.useState<Array<Option>>([]); // 発電事業者リスト
  const [powerPlantOptions, setPowerPlantOptions] = React.useState<
    Array<Option>
  >([]); // 発電所リスト
  const [pcsOptions, setPcsOptions] = React.useState<Array<Option>>([]); // PCSリスト
  const companyId = methods.watch('company');
  const powerPlantId = methods.watch('powerPlant');
  const pcsId = methods.watch('pcs');

  const powerPlants = React.useRef<PowerPlantModel[] | null>(null);
  const [powerPlant, setPowerPlant] = React.useState<PowerPlantModel | null>(
    null
  );
  const [targetDate, setTargetDate] = React.useState<string | undefined>(
    undefined
  );
  const [data, setData] = React.useState<FailureModel | null>(null);

  const [pcsStatus, setPCSStatus] = React.useState<{
    timestamp: dayjs.Dayjs;
    data: {
      sensor_name: string;
      type: string;
    }[];
  } | null>(null);

  const load = async (pcsId: string, date?: string) => {
    date = date ?? targetDate;
    const response = await API.PCS.monitorData(pcsId, date);
    console.log(response);
    setData(response.data);

    {
      const _ = await API.PCS.status(pcsId);
      console.log(_);
      setPCSStatus({ timestamp: dayjs(), data: _.data });
    }
  };

  // 「MAP・報告事項」への遷移
  const onJumpToMap = () => {
    const _companyId =
      user.current?.type === UserRoleType.SUB
        ? user.current?.company_id
        : companyId;

    const state = { companyId: _companyId, powerPlantId, mapId: '' };
    navigate('/monitor/map', { state });
  };

  const onChangeDate = async (value: string) => {
    console.log('change date : ' + value);
    spinner.current.setState(true);
    setTargetDate(value);
    await load(pcsId, value);
    spinner.current.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;

    if (user.current?.type === UserRoleType.MASTER) {
      (async () => {
        spinner.current.setState(true);
        try {
          const _company = await API.Company.list();
          if (_company.data) {
            setCompanyOptions(
              _company.data.map((v) => {
                return { label: v.company_name, value: v.id || '' };
              })
            );
          }
        } catch (e) {
          console.error(e);
        }
        spinner.current.setState(false);
      })();
    } else {
      // methods.setValue('company', user.current?.company_id!);
      (async () => {
        spinner.current.setState(true);
        try {
          const response = await API.PowerPlant.listForMe();
          if (response.data) {
            setPowerPlantOptions(
              response.data.map((v) => {
                return { label: v.name, value: v.id || '' };
              })
            );
            powerPlants.current = response.data;
          }
        } catch (e) {
          console.error(e);
        }
        spinner.current.setState(false);
      })();
    }
  }, [methods]);

  // 発電事業者選択を監視
  React.useEffect(() => {
    if (companyId) {
      if (companyId !== processedCompanyID.current) {
        processedCompanyID.current = companyId;
        (async () => {
          console.log('発電所リストの更新: ', companyId);
          spinner.current.setState(true);
          try {
            const response = await API.PowerPlant.listInCompany(companyId, true);
            if (response.data) {
              setPowerPlantOptions(
                response.data.map((v) => {
                  return { label: v.name, value: v.id || '' };
                })
              );
              powerPlants.current = response.data;

              if (location.state?.power_plant_id) {
                methods.setValue('powerPlant', location.state?.power_plant_id);
                delete location.state?.power_plant_id;
              } else {
                methods.setValue('powerPlant', '');
              }
            }
          } catch (e) {
            console.error(e);
          }
          spinner.current.setState(false);
        })();
      }
    } else {
      powerPlants.current = null;
      methods.setValue('powerPlant', '');
    }
  }, [companyId, location.state, methods]);

  // 発電所選択を監視
  React.useEffect(() => {
    if (powerPlantId) {
      if (powerPlantId !== processedPowerPlantID.current) {
        processedPowerPlantID.current = powerPlantId;
        (async () => {
          console.log('PCSリストの更新: ', powerPlantId);
          spinner.current.setState(true);
          try {
            const response = await API.AiPCSi.list(powerPlantId);
            if (response.data) {
              const tmp: Option[] = [];
              for (const aipcsi of response.data.aipcsis) {
                const response = await API.PCS.list(aipcsi.id);
                tmp.push(
                  ...response.data.map((v) => {
                    return { label: v.name, value: v.id! };
                  })
                );
              }
              tmp.sort((a, b) => {
                const labelA = a.label;
                const labelB = b.label;
                if (labelA < labelB) {
                  return -1;
                }
                if (labelA > labelB) {
                  return 1;
                }
                return 0;
              });
              setPcsOptions(tmp);

              if (location.state?.pcs_id) {
                methods.setValue('pcs', location.state?.pcs_id);
                delete location.state?.pcs_id;
              } else {
                methods.setValue('pcs', '');
              }
            }
          } catch (e) {
            console.error(e);
          }
          spinner.current.setState(false);
        })();
      }
    } else {
      methods.setValue('pcs', '');
    }

    if (powerPlants.current) {
      setPowerPlant(
        powerPlants.current.find((v) => v.id === powerPlantId) ?? null
      );
    } else {
      setPowerPlant(null);
    }
  }, [location.state, methods, powerPlantId]);

  // PCS選択を監視
  React.useEffect(() => {
    if (pcsId) {
      if (pcsId !== processedPCSID.current) {
        processedPCSID.current = pcsId;
        (async () => {
          console.log('グラフデータのロード: ', pcsId);
          spinner.current.setState(true);
          try {
            await load(pcsId);
          } catch (e) {
            console.error(e);
          }
          spinner.current.setState(false);
        })();
      } else {
        setData(null);
      }
    } else {
      processedPCSID.current = '';
      setData(null);
      setPCSStatus(null);
    }
  }, [pcsId]);

  return (
    <div className="w-full h-full bg-re-F3F7FA px-8 py-2 pb-20 flex flex-col gap-4">
      <Head title="PCS個別情報" />
      <Breadcrumbs items={[{ label: 'PCS個別情報' }]} />
      <div className="font-bold text-3xl text-re-063B67">
        PCS個別情報
      </div>

      <Card className="p-6">
        <div className="flex items-center justify-between flex-wrap">
          <div className="font-bold text-2xl text-re-063B67">
            PCSの検索
          </div>

          <div className="flex">
            <FormProvider {...methods}>
              {user.current?.type === UserRoleType.MASTER && (
                <SelectBox<string>
                  fieldName={'company'}
                  width="w-[260px]"
                  blank={''}
                  blankLabel="発電事業者を選択"
                  options={companyOptions}
                />
              )}

              <SelectBox<string>
                fieldName="powerPlant"
                width="w-[260px]"
                blank={''}
                blankLabel="発電所を選択"
                className="ml-3"
                options={powerPlantOptions}
              />

              <SelectBox<string>
                fieldName="pcs"
                width="w-[260px]"
                blank={''}
                blankLabel="PCSを選択"
                className="ml-3"
                options={pcsOptions}
              />
            </FormProvider>
          </div>
        </div>

        <div className="flex gap-8 mt-9">
          <div className="flex-1">
            <PCSStatus data={pcsStatus} />
          </div>
          <Card width="flex-1" className="p-6">
            <div className="flex justify-between">
              <div className="font-bold text-2xl text-re-063B67">現場情報</div>
              <div className="flex">
                <Button
                  text="発電所詳細"
                  size="sm"
                  outlined
                  width="w-[130px]"
                  link={`/manage/power_plant/detail/${powerPlantId}`}
                  disabled={!powerPlant}
                />
                <Button
                  text="MAP・報告"
                  size="sm"
                  outlined
                  width="w-[160px]"
                  className="ml-3"
                  onClick={onJumpToMap}
                  disabled={!powerPlant}
                />
              </div>
            </div>

            <div className="flex items-center mt-6">
              <div className="font-bold w-1/3">担当者名</div>
              <div className="font-bold w-2/3">
                {powerPlant
                  ? `${powerPlant.manager_last_name} ${powerPlant.manager_first_name}`
                  : '-'}
              </div>
            </div>

            <div className="flex items-center mt-7">
              <div className="font-bold w-1/3">発電所住所</div>
              <div className="font-bold w-2/3">
                {powerPlant ? powerPlant.address : '-'}
              </div>
            </div>
          </Card>
        </div>

        <GraphArea data={data} date={targetDate} onChangeDate={onChangeDate} />
      </Card>
    </div>
  );
};

export default MonitorFailurePage;
