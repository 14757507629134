import FormLabel from 'components/common/FormLabel';
import SelectBox from 'components/common/SelectBox';
import { getUserRoleTypeStr, UserRoleType } from 'enums';
import { UserModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Store } from 'store';
import Utils from 'utils';

type PROPS = {
  editable: boolean;
  onChangeRoleType?: () => void;
};

const UserEditFormBlock1ItemType: React.FC<PROPS> = (props) => {
  const user = Store.loadUserDara();

  const { formState, getValues } = useFormContext<UserModel>();

  const isCreate = !getValues().id;
  const type = getValues().type;

  const options = [
    { label: 'アドミン', value: UserRoleType.ADMIN },
    { label: 'メイン', value: UserRoleType.MAIN },
    { label: 'サブ', value: UserRoleType.SUB },
  ];
  if (isCreate && user?.type === UserRoleType.MASTER) {
    // マスターを選択できるのは新規登録時 かつ ユーザー権限がマスタの場合
    options.unshift({ label: 'マスター', value: UserRoleType.MASTER });
  }

  if (props.editable) {
    // 編集可能の場合
    return (
      <div className="flex gap-2">
        <FormLabel
          text="権限"
          required
          subtext={isCreate ? '※今後変更はできません' : undefined}
        />
        <div className="flex-1">
          <SelectBox
            blankLabel="権限を選択"
            fieldName="type"
            options={options}
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.type?.message}
          />
        </div>
      </div>
    );
  }

  // 編集不可の場合
  return (
    <div className="flex">
      <FormLabel text="権限" className="font-bold" />
      <div className="flex-1">{getUserRoleTypeStr(type)}</div>
    </div>
  );
};

export default UserEditFormBlock1ItemType;
