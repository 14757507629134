import axios from 'axios';
import { MapModel, MemoListModel } from 'models';

export async function getMap(map_id: string) {
  const response = await axios.get<MapModel>(`/map/${map_id}`);
  return response;
}

export async function createMapPin(map_id: string, data: FormData) {
  const response = await axios.post<MemoListModel>(
    `/map/${map_id}/memo/new`,
    data
  );
  return response;
}
