import CheckBox from 'components/common/CheckBox';
import { UserRoleType } from 'enums';
import { PowerPlantModel, UserModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type PROPS = {
  powerPlantList: PowerPlantModel[];
};

const UserEditFormBlock3: React.FC<PROPS> = (props) => {
  const { getValues, setValue } = useFormContext<UserModel>();

  const [values, setValues] = React.useState<string[]>([]);

  const type = getValues().type;

  const onChange = (_: React.SetStateAction<string[]>) => {
    console.log(_);
    setValues(_);
    setValue('power_plants', _ as string[]);
  };

  React.useEffect(() => {
    const tmp = getValues().power_plants;
    setValues(tmp ?? []);
  }, [getValues, props.powerPlantList]);

  if (type !== UserRoleType.MAIN && type !== UserRoleType.SUB) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="font-bold text-xl text-re-063B67">
        アクセスできる発電所
        {/* <span>{JSON.stringify(values)}</span> */}
      </div>

      {props.powerPlantList.map((powerPlant, index) => {
        return (
          <div key={index}>
            <CheckBox
              id={powerPlant.id!}
              label={powerPlant.name}
              value={values}
              checked={values.includes(powerPlant.id!)}
              setValue={onChange}
            />
          </div>
        );
      })}
    </div>
  );
};

export default UserEditFormBlock3;
