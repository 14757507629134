import axios from 'axios';
import { MemoListModel, MemoPageListModel, MemoPendingListModel } from 'models';

export async function getMemoList(map_id: string, pin_id: string) {
  const response = await axios.get<Array<MemoListModel>>(
    `/map/${map_id}/memo/${pin_id}`
  );
  return response;
}

export async function getMemoPageList() {
  const response = await axios.get<Array<MemoPageListModel>>('/memo');
  return response;
}

export async function getPendingMemoCount() {
  const response = await axios.get<string>('/memo/pending/count');
  return response;
}

export async function getPendingMemoList() {
  const response = await axios.get<Array<MemoPendingListModel>>(
    '/memo/pending'
  );
  return response;
}

export async function createMemo(
  map_id: string,
  pin_id: string,
  data: FormData
) {
  const response = await axios.post<MemoListModel>(
    `/map/${map_id}/memo/${pin_id}`,
    data
  );
  return response;
}

export async function updateMemo(id: string, data: FormData) {
  const response = await axios.put(`/memo/${id}`, data);
  return response;
}

export async function deleteMemo(id: string) {
  const response = await axios.delete(`/memo/${id}`);
  return response;
}

export async function approve(id: string) {
  const response = await axios.put(`/memo/${id}/approve`);
  return response;
}

export async function decline(id: string) {
  const response = await axios.put(`/memo/${id}/decline`);
  return response;
}
