import React from 'react';
import { getAlertTypeStr } from 'enums';

type PROPS = {
  type?: string;
  headerTitle?: string;
  headerClass?: string;
  width?: string;
  height?: string;
  className?: string;
  children: React.ReactNode;
};

const Card: React.FC<PROPS> = ({
  type,
  headerTitle,
  headerClass,
  width = 'w-full',
  height = 'h-full',
  className,
  children,
}: PROPS) => {
  /* TODO typeの値の確認 */
  const style = {
    container: `bg-white h-fit drop-shadow rounded-[10px]
      ${width} ${height}
    `,
    header: `flex items-center h-[38px] rounded-t-[10px] border-b ${headerClass}`,
    label: `flex items-center justify-center text-sm text-white rounded-tl-[10px] font-bold h-full w-[10%] min-w-[70px]
      ${
        type === 'trouble'
          ? 'bg-re-danger'
          : type === 'warning' || type === 'caution'
          ? 'bg-re-warn'
          : 'bg-re-normal'
      }
    `,
    title: `flex items-center text-sm leading-4 font-bold w-full h-full rounded-tr-[10px] px-3 ${
      type === 'trouble'
        ? 'bg-re-danger text-white'
        : type === 'warning' || type === 'caution'
        ? 'bg-re-warn-hover'
        : ''
    }`,
  };
  return (
    <div className={`${style.container} ${className}`}>
      {headerTitle && (
        <div className={style.header}>
          {type !== undefined && (
            <label className={style.label}>{getAlertTypeStr(type)}</label>
          )}

          <span className={style.title}>{headerTitle}</span>
        </div>
      )}

      {children}
    </div>
  );
};

export default Card;
