import API from 'api';
import axios from 'axios';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import UserEditFormBlock1 from 'components/manage/user/UserEditFormBlock1';
import UserEditFormBlock2 from 'components/manage/user/UserEditFormBlock2';
import UserEditFormBlock3 from 'components/manage/user/UserEditFormBlock3';
import dayjs from 'dayjs';
import { getUserRoleTypeStr, UserRoleType } from 'enums';
import useNavigate from 'hooks/use-navigate';
import {
  CompanyModel,
  OutletContextModel,
  PowerPlantModel,
  UserModel,
} from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { Store } from 'store';
import Utils from 'utils';

const ManageUserCreatePage: React.FC = () => {
  const user = Store.loadUserDara();
  const navigate = useNavigate();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const [companyList, setCompanyList] = React.useState<CompanyModel[]>([]);

  const [powerPlantList, setPowerPlantList] = React.useState<PowerPlantModel[]>(
    []
  );
  const [createdInfo, setCreatedInfo] = React.useState<{
    password: string;
  } | null>(null);

  const methods = useForm<UserModel>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      company_id: '',
      login_id: '',
      last_name: '',
      first_name: '',
      type: '',
      contractor_name: '',
      department: '',
      address: '',
      tel: '',
      email: '',
      power_plants: [],
      logined_at: null,
      locked_at: null,
      deleted_at: null,
    },
  });

  const getLoginID = () => {
    const login_id = methods.getValues().login_id;
    const company_id = methods.getValues().company_id;
    const company = companyList.find((v) => v.id === company_id);
    if (!company) {
      return login_id;
    }
    return `${login_id}@${company.domain}`;
  };

  const onCreate = async (data: UserModel) => {
    ctx.spinner.setState(true);
    try {
      const response = await API.User.create(data!);
      console.log(response);

      setCreatedInfo(response.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errors = e.response?.data.errors;
        if (errors && Array.isArray(errors)) {
          for (const error of errors) {
            methods.setError(error.param, { message: error.msg });
          }
        } else {
          console.error(e);
          const message = e.response?.data.error;
          if (message) {
            ctx.msgDialog.open(message);
          }
        }
      } else {
        console.error(e);
      }
    }
    ctx.spinner.setState(false);
  };

  const onCloseCreatedInfoDialog = async () => {
    const type = methods.getValues().type;
    let userName;
    if (type === UserRoleType.SUB) {
      userName = methods.getValues().contractor_name;
    } else {
      const lastName = methods.getValues().last_name;
      const firstName = methods.getValues().first_name;
      userName = lastName + firstName;
    }
    const roleType = getUserRoleTypeStr(type);
    let text = 'ユーザーアカウントを発行しました。\n\n';
    text += `対象ユーザー：${userName}\n`;
    text += '以下の内容を該当ユーザーに共有してください\n\n';
    text += `ユーザー情報\n権限：${roleType}\n`;
    text += `ユーザーID：${getLoginID()}\n`;
    text += `パスワード：${createdInfo?.password}`;

    // const text = `Password: ${createdInfo?.password}`;
    setCreatedInfo(null);
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    }
    ctx.msgDialog.open('コピーしました', () => {
      navigate('/manage/user/');
    });
  };

  React.useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (name === 'type') {
        const type = methods.getValues().type;
        if (type === UserRoleType.MASTER) {
          methods.setValue('company_id', '1');
        } else if (methods.getValues('company_id') === '1') {
          methods.setValue('company_id', '');
        }
      }
      if (name === 'company_id') {
        (async () => {
          const company_id = methods.getValues().company_id;
          if (!company_id) {
            return;
          }
          if (
            powerPlantList.length > 0 &&
            powerPlantList[0].company_id === company_id
          ) {
            return;
          }
          ctx.spinner.setState(true);
          try {
            const response = await API.PowerPlant.listInCompany(company_id);
            console.log(response);
            if (response.data) {
              setPowerPlantList(response.data);
            }
          } catch (e) {
            console.error(e);
          }
          ctx.spinner.setState(false);
        })();
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [ctx.spinner, methods, powerPlantList]);

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      if (user?.type === UserRoleType.MASTER) {
        // Userがマスタ権限の場合のみ、発電事業者一覧を取得する
        ctx.spinner.setState(true);
        try {
          const response = await API.Company.list();
          console.log(response);
          if (response.data) {
            // 削除済みの発電事業者は除外する
            const tmp = response.data.filter(v => {
              if (v.deleted_at && dayjs(v.deleted_at).isValid()) {
                return false;
              }
              return true;
            })
            setCompanyList(tmp);
          }
        } catch (e) {
          console.error(e);
        }
        ctx.spinner.setState(false);
      } else {
        // マスタ見解では無い場合は、ログインユーザーのcompanyIdを設定
        methods.setValue('company_id', user?.company_id!);
        // ドメインだけ取得
        ctx.spinner.setState(true);
        try {
          const response = await API.Company.domain();
          console.log(response);
          if (response.data) {
            methods.setValue('domain', response.data);
          }
        } catch (e) {
          console.error(e);
        }
        ctx.spinner.setState(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="アカウント新規登録" />
      <Breadcrumbs
        items={[
          { label: 'アカウント管理', path: '/manage/user/' },
          { label: 'アカウント新規登録' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">アカウント新規登録</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="max-w-[800px] mx-auto flex flex-col gap-7">
          <div className="font-bold text-2xl text-re-063B67">アカウント情報</div>
          <FormProvider {...methods}>
            <UserEditFormBlock1 companyList={companyList} />
            <UserEditFormBlock2 />
            <UserEditFormBlock3 powerPlantList={powerPlantList} />
          </FormProvider>
          <div className="flex justify-center gap-4">
            <Button text="戻る" outlined color="gray" link="/manage/user/" />
            <Button
              text="登録する"
              onClick={methods.handleSubmit(onCreate)}
              disabled={!methods.getValues().type}
            />
          </div>
        </div>
      </div>
      {/* 新規登録 発行情報ダイアログ */}
      <Dialog show={!!createdInfo} onClose={() => { }}>
        <div className="flex flex-col items-center">
          <div className="font-bold text-2xl text-re-063B67">
            ユーザーアカウントを発行しました
          </div>
          {methods.getValues().type === UserRoleType.SUB ? (
            <div className="my-6 font-bold text-xl text-re-063B67">
              対象ユーザー: {methods.getValues().contractor_name}
            </div>
          ) : (
            <div className="my-6 font-bold text-xl text-re-063B67">
              対象ユーザー: {methods.getValues().last_name}
              {methods.getValues().first_name}
            </div>
          )}

          <div>以下の内容を該当ユーザーに共有してください</div>

          <div className="w-full px-24 text-left">
            <div className="my-6 font-bold text-xl text-re-063B67">
              ユーザー情報
            </div>
            <div className="grid grid-cols-[2fr_3fr] gap-y-6">
              <div className="font-bold">権限</div>
              <div>{getUserRoleTypeStr(methods.getValues().type)}</div>
              <div className="font-bold">ユーザーID</div>
              <div>{getLoginID()}</div>
              <div className="font-bold">パスワード</div>
              <div>{createdInfo?.password}</div>
            </div>
          </div>

          <div className="mt-12">
            <Button
              text="登録情報をコピーして閉じる"
              onClick={onCloseCreatedInfoDialog}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ManageUserCreatePage;
