import API from 'api';
import axios from 'axios';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Head from 'components/common/Head';
import PCSEditFormBlock1 from 'components/manage/power_plant/pcs/PCSEditFormBlock1';
import PCSEditFormBlock2 from 'components/manage/power_plant/pcs/PCSEditFormBlock2';
import PCSEditFormBlock3 from 'components/manage/power_plant/pcs/PCSEditFormBlock3';
import useNavigate from 'hooks/use-navigate';
import { AiPCSiListAPIModel, OutletContextModel, PCSModel } from 'models';
import Error404 from 'pages/Error404';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';

const PCSCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const aipcsiList = React.useRef<AiPCSiListAPIModel>();

  const methods = useForm<PCSModel>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      aipcsi_id: '',
      name: '',
      maker: '',
      model: '',
      thresholds: [
        { name: '温度', caution: '10', trouble: '20' },
        { name: '振動', caution: '10', trouble: '20' },
        { name: 'U電流', caution: '10', trouble: '20' },
        { name: 'V電流', caution: '10', trouble: '20' },
        { name: 'W電流', caution: '10', trouble: '20' },
        { name: 'EMI', caution: '10', trouble: '20' },
      ],
    },
  });

  const onCreate = async (data: PCSModel) => {
    console.log('登録');
    console.log(data);

    ctx.spinner.setState(true);
    try {
      const response = await API.PCS.create(data!);
      console.log(response);
      ctx.msgDialog.open('登録しました', () => {
        navigate(`/manage/power_plant/detail/${id}/`);
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errors = e.response?.data.errors;
        if (errors && Array.isArray(errors)) {
          for (const error of errors) {
            methods.setError(error.param, { message: error.msg });
          }
        } else {
          console.error(e);
        }
      } else {
        console.error(e);
      }
    }
    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.AiPCSi.list(id);
        console.log(response);
        // 削除済みのAiPCSi情報は除外する
        response.data.aipcsis = response.data.aipcsis.filter(v => !v.deleted_at);
        aipcsiList.current = response.data;
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!id) {
    return <Error404 />;
  }

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="PCS新規登録" />
      <Breadcrumbs
        items={[
          { label: '発電所 管理', path: '/manage/power_plant/' },
          { label: '発電所 詳細', path: `/manage/power_plant/detail/${id}` },
          { label: 'PCS 新規登録' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">PCS 新規登録</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="max-w-[800px] mx-auto flex flex-col gap-7">
          <div className="font-bold text-2xl text-re-063B67">PCS 情報</div>
          <FormProvider {...methods}>
            <PCSEditFormBlock1
              powerPlantId={id}
              aipcsiList={aipcsiList.current}
            />
            <PCSEditFormBlock2 />
            <PCSEditFormBlock3 />
          </FormProvider>
          <div className="flex justify-center gap-4">
            <Button
              text="戻る"
              outlined
              color="gray"
              link={`/manage/power_plant/detail/${id}/`}
            />
            <Button text="登録する" onClick={methods.handleSubmit(onCreate)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PCSCreatePage;
