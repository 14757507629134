import { UserRoleType } from 'enums';
import { UserModel } from 'models';

export type UserData = {
  id: string;
  company_id: string;
  company_name: string;
  first_name: string;
  last_name: string;
  type: 'master' | 'admin' | 'main' | 'sub';
  contractor_name?: string;
};

export function saveToken(token: string) {
  localStorage.setItem('token', token);
}

export function saveUserData(data: UserData) {
  localStorage.setItem('user', JSON.stringify(data));
}

export function save(token: string, data: UserData) {
  saveToken(token);
  saveUserData(data);
}

export function loadToken() {
  return localStorage.getItem('token');
}

export function loadUserDara() {
  const tmp = localStorage.getItem('user');
  if (!tmp) {
    return null;
  }
  try {
    return JSON.parse(tmp) as UserData;
  } catch (e) {
    return null;
  }
}

export function updateUserDara(user: UserModel) {
  const data = loadUserDara();
  if (!data) {
    return;
  }
  data.type = user.type as any;
  data.last_name = user.last_name;
  data.first_name = user.first_name;
  if (user.type === UserRoleType.SUB) {
    data.contractor_name = user.contractor_name;
  }
  saveUserData(data);
}
