import { NavLink as L, useLocation } from 'react-router-dom';

type PROPS = {
  to: string;
  className: string;
  children: React.ReactNode;
};

const NavLink: React.FC<PROPS> = (props) => {
  const location = useLocation();

  let to = props.to;
  const q = new URLSearchParams(location.search);
  const pc = q.get('pc');
  if (pc !== undefined && pc !== null && pc !== 'false') {
    const tmp = to.split('?');
    if (tmp.length === 1) {
      to += '?pc';
    } else {
      const q = new URLSearchParams(tmp[1]);
      q.set('pc', '');
      to = tmp[0] + q.toString();
    }
  }

  return <L to={to}>{props.children}</L>;
};

export default NavLink;
