import FormLabel from 'components/common/FormLabel';
import TextField from 'components/common/TextField';
import { CompanyModel, UserModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {
  editable?: boolean;
  companyList: CompanyModel[];
};

const UserEditFormBlock1ItemLoginID: React.FC<PROPS> = (props) => {
  const { formState, getValues } = useFormContext<UserModel>();

  const getDomain = () => {
    const tmp = getValues().domain;
    if (tmp) {
      return tmp;
    }
    const company_id = getValues().company_id;
    const target = props.companyList.find((v) => v.id === company_id);
    if (target) {
      return target.domain;
    }

    return '';
  };

  if (props.editable) {
    // 編集可能の場合
    return (
      <div className="flex gap-2">
        <FormLabel text="ユーザーID" required subtext="※今後変更はできません" />
        <div className="flex-1 flex items-center gap-4">
          <TextField
            fieldName="login_id"
            placeholder="半角英数"
            validations={Utils.loginIDValidation()}
            errorMessage={formState.errors.login_id?.message}
          />
          <span>@{getDomain()}</span>
        </div>
      </div>
    );
  }

  // 編集不可の場合
  return (
    <div className="flex">
      <FormLabel text="ユーザーID" className="font-bold" />
      <div className="flex-1 ">
        {getValues().login_id}@{getDomain()}
      </div>
    </div>
  );
};

export default UserEditFormBlock1ItemLoginID;
