import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Head from 'components/common/Head';
import InformationDetail from 'components/information/InformationDetail';
import { InformationStatusType, getinformationStatusStr } from 'enums';
import { InformationModel, OutletContextModel } from 'models';
import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';

const InboxDetailPage: React.FC = () => {
  const { id } = useParams();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);
  const [data, setData] = React.useState<InformationModel>();
  const [status, setStatus] = React.useState('');

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.Information.get(Number(id));
        if (response.data) {
          setData(response.data);

          const _status = getinformationStatusStr(
            response.data?.delivery_on,
            response.data?.delivery_end
          );
          setStatus(_status);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuspend = async () => {
    try {
      if (id) {
        const response = await API.Information.hide(id);

        if (response.data) {
          setStatus(InformationStatusType.DELIVERING);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="w-full h-full bg-re-F3F7FA px-8 py-2 pb-20 flex flex-col gap-4">
      <Head title="お知らせ履歴詳細" />
      <Breadcrumbs
        items={[
          { label: 'お知らせ 履歴', path: '/inbox/' },
          { label: 'お知らせ 履歴詳細' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">お知らせ 履歴詳細</div>
      <InformationDetail data={data} status={status} onSuspend={onSuspend} />
    </div>
  );
};

export default InboxDetailPage;
