import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Head from 'components/common/Head';
import Link from 'components/common/Link';
import dayjs from 'dayjs';
import { CompanyModel, OutletContextModel } from 'models';
import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';

const ManageCompanyDetailPage: React.FC = () => {
  const { id } = useParams();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const [data, setData] = React.useState<CompanyModel>();

  const isDeleted = () => {
    if (!data) {
      return false;
    }
    return !!data.deleted_at && dayjs(data.deleted_at).isValid();
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.Company.get(id!);
        console.log(response);
        if (response.data) {
          setData(response.data);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="発電事業者詳細" />
      <Breadcrumbs
        items={[
          { label: '発電事業者 管理', path: '/manage/company/' },
          { label: '発電事業者 詳細' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">発電事業者 詳細</div>
      <div className="drop-shadow rounded bg-white p-6 flex flex-col gap-7">
        <div className="flex justify-between">
          <div className="font-bold text-2xl text-re-063B67">
            発電事業者 基本情報
          </div>
          {!isDeleted() && (
            <Button
              text="編集"
              size="sm"
              outlined
              link={`/manage/company/edit/${id}/`}
            />
          )}
        </div>

        <div className="flex flex-col gap-8">
          <div className="font-bold text-re-063B67 text-xl">法人 情報</div>
          <div className="flex">
            <div className="flex-1 font-bold">法人名</div>
            <div className="flex-[2]">{data?.company_name}</div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">法人代表者</div>
            <div className="flex-[2]">
              {data?.president_last_name}&nbsp;{data?.president_first_name}
            </div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">法人住所</div>
            <div className="flex-[2]">{data?.company_address}</div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">法人電話番号</div>
            <div className="flex-[2]">{data?.company_tel}</div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">共通ID</div>
            <div className="flex-[2]">
              {data?.domain ? `@${data?.domain}` : ''}
            </div>
          </div>

          <div className="font-bold text-re-063B67 text-xl">
            事業者 担当者情報
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">管理者名</div>
            <div className="flex-[2]">
              {data?.manager_last_name}&nbsp;{data?.manager_first_name}
            </div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">メールアドレス</div>
            <div className="flex-[2]">{data?.manager_email}</div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">所属・部署</div>
            <div className="flex-[2]">{data?.department}</div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">住所</div>
            <div className="flex-[2]">{data?.manager_address}</div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">電話番号</div>
            <div className="flex-[2]">{data?.manager_tel}</div>
          </div>
        </div>
      </div>
      <div className="drop-shadow rounded bg-white p-6 flex flex-col gap-7">
        <div className="font-bold text-2xl text-re-063B67">他、関連情報</div>
        <div className="flex justify-between">
          <div className="font-bold">ユーザー一覧</div>
          <Link to={`/manage/user/`}>
            <button className="border border-re-primary rounded">
              <div className="font-bold text-re-063B67 px-4 py-2">
                ユーザー一覧へ
              </div>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ManageCompanyDetailPage;
