import dayjs from 'dayjs';

/** ユーザーの権限種別 */
export const enum InformationStatusType {
  DELIVERY = '配信待ち/予約中',
  DELIVERING = '表示中',
  DELIVERED = '配信済',
}

/** 「ユーザーの権限種別」の文字列を返します。 */
export const getinformationStatusStr = (
  start: string,
  end: string | null
): string => {
  if (start) {
    if (end && dayjs().isAfter(end)) {
      return InformationStatusType.DELIVERED;
    } else if (dayjs().isAfter(start)) {
      return InformationStatusType.DELIVERING;
    } else {
      return InformationStatusType.DELIVERY;
    }
  } else {
    return '';
  }
};
