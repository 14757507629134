import React from 'react';

type PROPS = {
  type?: string;
  headerClass?: string;
  mainTitle?: string;
  mainClass?: string;
  subTitle?: string;
  subClass?: string;
  width?: string;
  height?: string;
  className?: string;
  children: React.ReactNode;
};

const PlantDetailCard: React.FC<PROPS> = ({
  headerClass,
  mainTitle,
  subTitle,
  mainClass,
  subClass,
  width = 'w-full',
  height = 'h-full',
  className,
  children,
}: PROPS) => {
  const style = {
    container: `bg-white h-fit drop-shadow rounded-[10px]
      ${width} ${height}
    `,
    header: `flex items-center min-h-[53px] rounded-t-[10px] border-b ${headerClass}`,
    title: `items-center font-bold w-full px-3 py-2`,
    subClass: `font-medium text-sm ${subClass}`,
    mainClass: `font-bold text-xl ${mainClass}`,
  };
  return (
    <div className={`${style.container} ${className}`}>
      <div className={style.header}>
        <div className={style.title}>
          <span className={style.subClass}>{subTitle}</span>
          <span className={style.mainClass}>&emsp;{mainTitle}</span>
        </div>
      </div>
      {children}
    </div>
  );
};

export default PlantDetailCard;
