import axios from 'axios';
import { InformationModel } from 'models';

export async function list() {
  const response = await axios.get<InformationModel[]>('/information');
  return response;
}

export async function presentList() {
  const response = await axios.get<InformationModel[]>('/information/present');
  return response;
}

export async function historyList() {
  const response = await axios.get<InformationModel[]>('/information/history');
  return response;
}

export async function get(id: number) {
  const response = await axios.get<InformationModel>(`/information/${id}`);
  return response;
}

export async function create(data: InformationModel) {
  const response = await axios.post<InformationModel>(`/information`, data);
  return response;
}

export async function update(id: string, data: InformationModel) {
  const response = await axios.put<InformationModel>(
    `/information/${id}`,
    data
  );
  return response;
}

export async function hide(id: string) {
  const response = await axios.put(`/information/${id}/hide`);
  return response;
}

export async function del(id: string) {
  const response = await axios.delete(`/information/${id}`);
  return response;
}
