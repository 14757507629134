import React from 'react';
import Button from '../Button';
import PaginationItem from './PaginationItem';

type PROPS = {
  current: number;
  count: number;
  onChange: (page: number) => void;
};

const Pagination: React.FC<PROPS> = (props) => {
  const current = props.current;
  const count = props.count;

  const [junpTo, setJumpTo] = React.useState('');

  const front = [];
  const pages = [current];
  const back = [];

  for (let i = 1; i < 5; i++) {
    let tmp = current - i;
    if (tmp >= 1) {
      pages.unshift(tmp);
    }
    tmp = current + i;
    if (tmp <= count) {
      pages.push(tmp);
    }
    if (pages.length >= count) {
      break;
    }
    if (pages.length >= 5) {
      break;
    }
  }

  if (pages[0] > 1) {
    front.push(1);
    if (pages[0] > 2) {
      front.push(2);
    }
  }
  const last = pages[pages.length - 1];
  if (last < count) {
    back.unshift(count);
    if (last < count - 1) {
      back.unshift(count - 1);
    }
  }

  if (count === 0) {
    return <></>;
  }

  return (
    <div className="flex items-center">
      <PaginationItem
        current={current}
        label={'|<'}
        toPage={1}
        onChange={props.onChange}
        disabled={current < 2}
      />
      <PaginationItem
        current={current}
        label={'<'}
        toPage={current - 1}
        onChange={props.onChange}
        disabled={current < 2}
      />
      {front.length > 0 && (
        <div className="flex">
          {front.map((page, index) => {
            return (
              <PaginationItem
                key={index}
                current={current}
                label={page}
                toPage={page}
                onChange={props.onChange}
              />
            );
          })}
          {pages[0] !== 2 && <span>...</span>}
        </div>
      )}
      <div className="flex">
        {pages.map((page, index) => {
          return (
            <PaginationItem
              key={index}
              current={current}
              label={page}
              toPage={page}
              onChange={props.onChange}
            />
          );
        })}
      </div>
      {back.length > 0 && (
        <div className="flex">
          {last !== count - 1 && <span>...</span>}
          {back.map((page, index) => {
            return (
              <PaginationItem
                key={index}
                current={current}
                label={page}
                toPage={page}
                onChange={props.onChange}
              />
            );
          })}
        </div>
      )}
      <PaginationItem
        current={current}
        label={'>'}
        toPage={current + 1}
        onChange={props.onChange}
        disabled={current > count - 1}
      />
      <PaginationItem
        current={current}
        label={'>|'}
        toPage={count}
        onChange={props.onChange}
        disabled={current > count - 1}
      />
      <div className="ml-8 flex items-center gap-4">
        <div className="flex items-center gap-2">
          <input
            className="px-2 py-1 border border-t-re-gray w-12"
            value={junpTo}
            onChange={(e) => {
              setJumpTo(e.target.value);
            }}
          />
          <div>/{count}</div>
        </div>
        <Button
          text="移動する"
          size="sm"
          onClick={() => {
            const value = parseInt(junpTo);
            setJumpTo('');
            props.onChange(value);
          }}
          disabled={(() => {
            const value = parseInt(junpTo);
            if (isNaN(value)) {
              return true;
            }
            if (value === current) {
              return true;
            }
            if (value < 1) {
              return true;
            }
            if (value > count) {
              return true;
            }
            return false;
          })()}
        />
      </div>
    </div>
  );
};

export default Pagination;
