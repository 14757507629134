import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import PCSEditFormBlock1 from 'components/manage/power_plant/pcs/PCSEditFormBlock1';
import PCSEditFormBlock2 from 'components/manage/power_plant/pcs/PCSEditFormBlock2';
import PCSEditFormBlock3 from 'components/manage/power_plant/pcs/PCSEditFormBlock3';
import useNavigate from 'hooks/use-navigate';
import { AiPCSiListAPIModel, OutletContextModel, PCSModel } from 'models';
import Error404 from 'pages/Error404';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';

const PCSEditPage: React.FC = () => {
  const navigate = useNavigate();
  const { id, pcs_id } = useParams();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const aipcsiList = React.useRef<AiPCSiListAPIModel>();
  const aipcsiID = React.useRef('');

  const [deleteConfirmDialogFlg, setDeleteConfirmDialogFlg] =
    React.useState(false);
  // AiPCSi変更時の警告ダイアログ
  const [changeAiPCSiAlertDialogFlg, setChangeAiPCSiAlertDialogFlg] =
    React.useState<boolean | null>(null);

  const methods = useForm<PCSModel>({
    reValidateMode: 'onSubmit',
  });

  const onUpdate = async (data: PCSModel) => {
    console.log('登録');
    // TODO: バリデーション処理
    console.log(data);

    ctx.spinner.setState(true);
    try {
      const response = await API.PCS.update(data!);
      console.log(response);
      ctx.msgDialog.open('更新しました', () => {
        navigate(`/manage/power_plant/detail/${id}/`);
      });
    } catch (e) {
      console.error(e);
    }
    ctx.spinner.setState(false);
  };

  const onDelete = () => {
    setDeleteConfirmDialogFlg(true);
  };

  const onCloseDeleteConfirm = async (result?: boolean) => {
    setDeleteConfirmDialogFlg(false);
    if (!result) {
      return;
    }
    ctx.spinner.setState(true);
    try {
      const response = await API.PCS.del(pcs_id!);
      console.log(response);
      ctx.msgDialog.open('削除しました', () => {
        navigate(`/manage/power_plant/detail/${id}/`);
      });
    } catch (e) {
      console.error(e);
    }

    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      // console.log(value, name, type);
      if (name === 'aipcsi_id') {
        if (
          changeAiPCSiAlertDialogFlg === null &&
          aipcsiID.current !== value.aipcsi_id
        ) {
          aipcsiID.current = value.aipcsi_id!;
          setChangeAiPCSiAlertDialogFlg(true);
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [changeAiPCSiAlertDialogFlg, methods]);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        {
          const response = await API.AiPCSi.list(id);
          console.log(response);
          aipcsiList.current = response.data;
        }
        {
          const response = await API.PCS.get(pcs_id);
          console.log(response);
          methods.setValue('id', response.data.id);
          aipcsiID.current = response.data.aipcsi_id;
          methods.setValue('aipcsi_id', response.data.aipcsi_id);
          methods.setValue('name', response.data.name);
          methods.setValue('maker', response.data.maker);
          methods.setValue('model', response.data.model);
          methods.setValue('thresholds', response.data.thresholds);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!id) {
    return <Error404 />;
  }

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="PCS編集" />
      <Breadcrumbs
        items={[
          { label: '発電所 管理', path: '/manage/power_plant/' },
          { label: '発電所 詳細', path: `/manage/power_plant/detail/${id}` },
          { label: 'PCS 編集' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">PCS 編集</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="max-w-[800px] mx-auto flex flex-col gap-7">
          <div className="font-bold text-2xl text-re-063B67">PCS 情報</div>
          <FormProvider {...methods}>
            <PCSEditFormBlock1
              powerPlantId={id}
              aipcsiList={aipcsiList.current}
            />
            <PCSEditFormBlock2 />
            <PCSEditFormBlock3 />
          </FormProvider>
          <div className="flex justify-center gap-4">
            <Button
              text="戻る"
              outlined
              color="gray"
              link={`/manage/power_plant/detail/${id}/`}
            />
            <Button text="更新する" onClick={methods.handleSubmit(onUpdate)} />
          </div>
          <div className="flex justify-center gap-4">
            <button
              className="text-re-danger hover:underline"
              onClick={onDelete}
            >
              PCS 削除
            </button>
          </div>
        </div>
      </div>
      {/* 接続AiPCSi変更 警告ダイアログ */}
      <Dialog
        width="w-[997px]"
        show={!!changeAiPCSiAlertDialogFlg}
        onClose={() => {
          // setChangeAiPCSiAlertDialogFlg(false);
        }}
      >
        <div className="flex flex-col items-center gap-8">
          <div className="font-bold text-xl text-re-danger">
            紐づけているAiPCSi変更
          </div>
          <div className="font-bold text-xl text-re-danger">AiPCSiの変更</div>
          <div className="text-center">
            <div>AiPCSiの変更操作をしようとしています。</div>
            <div>以前取得していた情報と違うデータが取得されます。</div>
          </div>

          <div className="flex justify-center gap-4">
            <Button
              text="了承"
              color="danger"
              onClick={() => {
                setChangeAiPCSiAlertDialogFlg(false);
              }}
            />
          </div>
        </div>
      </Dialog>
      {/* 削除確認ダイアログ */}
      <Dialog
        width="w-[997px]"
        show={deleteConfirmDialogFlg}
        onClose={onCloseDeleteConfirm}
      >
        <div className="flex flex-col items-center gap-8">
          <div className="font-bold text-xl text-re-danger">PCS 削除</div>
          <div className="font-bold text-xl text-re-danger">
            対象:{methods.getValues().name}
          </div>
          <div className="text-center">
            <div>
              PCSを削除すると、アドミン、メイン、サブには非表示になります。
            </div>
            <div>
              (データの取得は停止されますがマスターの画面には引き続き表示されます。)
            </div>
          </div>

          <div className="flex justify-center gap-4">
            <button
              className="w-56 border border-re-gray py-2 rounded-lg"
              onClick={() => {
                onCloseDeleteConfirm();
              }}
            >
              <span className="font-bold text-re-gray">しない</span>
            </button>
            <button
              className="w-56 bg-re-be1a1a py-2 rounded-lg hover:bg-re-danger-hover duration-100 transition-colors"
              onClick={() => {
                onCloseDeleteConfirm(true);
              }}
            >
              <span className="font-bold text-white">削除する</span>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PCSEditPage;
