import React from 'react';
import dayjs from 'dayjs';
import { MapPinModel, MemoListModel } from 'models';
import { CSSTransition } from 'react-transition-group';
import { createSlidingToBottomAnime } from 'styles/transition';
import Button from 'components/common/Button';
import ImageModal from 'components/common/ImageModal';
import { FiEdit } from 'react-icons/fi';
import { BsTrash } from 'react-icons/bs';
import { ApprovalDialogType, ApprovalStatusType } from 'enums';

type PROPS = {
  mapPin: MapPinModel | undefined;
  memoList: Array<MemoListModel>;
  setShowDialogType: React.Dispatch<React.SetStateAction<string>>;
  setMapPin: React.Dispatch<React.SetStateAction<MapPinModel | undefined>>;
  setMemo?: React.Dispatch<React.SetStateAction<MemoListModel | undefined>>;
};

const MapBottomMenu: React.FC<PROPS> = ({
  mapPin,
  memoList,
  setShowDialogType,
  setMapPin,
  setMemo,
}: PROPS) => {
  const nodeRef = React.useRef(null); // StrictModeエラーの対処用
  const slide = createSlidingToBottomAnime();
  const [imageSrc, setImageSrc] = React.useState('');

  return (
    <>
      <CSSTransition
        in={mapPin && mapPin.id !== ''}
        timeout={0}
        nodeRef={nodeRef}
      >
        {(state) => (
          <div
            className="absolute bottom-0 w-full h-screen top-[250px]
          bg-white z-[1] origin-bottom"
            style={slide[state]}
          >
            <div className="flex justify-center">
              <div
                className="h-[6px] w-1/2 my-3 bg-re-smoke rounded-full"
                onClick={() => {
                  setMapPin(undefined);
                }}
              />
            </div>

            <div className="flex flex-col items-center border-b border-b-1 my-3 mx-4">
              <h2 className="text-2xl text-start w-full font-bold text-re-primary">
                報告
              </h2>
              <Button
                text="新規コメント"
                className="my-6"
                onClick={() => setShowDialogType(ApprovalDialogType.CREATE)}
              />
            </div>

            <div className="mt-3 pb-6 px-4 bg-white">
              <h2 className="text-2xl text-start w-full font-bold text-re-primary">
                履歴
              </h2>

              {memoList &&
                memoList.map((v, i) => (
                  <div className="relative my-6" key={i}>
                    <div className="flex justify-between items-center">
                      <div className="text-xs flex">
                        <div className="w-[95px] whitespace-nowrap">
                          {dayjs(v.created_at).format('YYYY/MM/DD HH:mm')}
                        </div>
                      </div>

                      {v.status === ApprovalStatusType.DISPLAY && (
                        <div className="flex">
                          <FiEdit
                            className="text-re-gray cursor-pointer"
                            onClick={() => {
                              setShowDialogType &&
                                setShowDialogType(ApprovalDialogType.UPDATE);
                              setMemo && setMemo(v);
                            }}
                          />
                          <BsTrash
                            className="text-re-danger opacity-70 cursor-pointer ml-6"
                            onClick={() => {
                              setShowDialogType &&
                                setShowDialogType(ApprovalDialogType.DELETE);
                              setMemo && setMemo(v);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="line-clamp-1 break-all mt-2">
                      {v.user_name}
                    </div>
                    <p className="text-sm mt-1">{v.memo}</p>
                    {v.memo_file_url && (
                      <img
                        src={v.memo_file_url}
                        alt="comment_img"
                        className="max-h-[300px] object-contain"
                        onClick={() => {
                          setImageSrc(v.memo_file_url || '');
                        }}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </CSSTransition>

      <ImageModal
        show={imageSrc !== ''}
        src={imageSrc}
        onClose={() => {
          setImageSrc('');
        }}
      />
    </>
  );
};

export default MapBottomMenu;
