import axios from 'axios';
import { UserModel } from 'models';

export async function create(data: UserModel) {
  const response = await axios.post<{ password: string }>('/user', data);
  return response;
}

export async function list() {
  const response = await axios.get<UserModel[]>('/user');
  return response;
}

export async function get(id: string) {
  const response = await axios.get<UserModel>(`/user/${id}`);
  return response;
}

export async function update(data: UserModel) {
  const response = await axios.put(`/user/${data.id}`, data);
  return response;
}

export async function del(id: string) {
  const response = await axios.delete(`/user/${id}`);
  return response;
}

export async function unlock(id: string) {
  const response = await axios.put(`/user/${id}/unlock`);
  return response;
}

export async function addEmail(user_id: string, email: string) {
  const response = await axios.post(`/user/email`, {
    user_id,
    email,
  });
  return response;
}

export async function deleteEmail(user_id: string, email_id: string) {
  const response = await axios.delete(`/user/email`, {
    data: { user_id, email_id },
  });
  return response;
}

export async function changePassword(user_id: string, password: string) {
  const response = await axios.put(`/user/${user_id}/password`, {
    password,
  });
  return response;
}
