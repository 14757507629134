import React from 'react';
import { Path, FieldValues, useFormContext } from 'react-hook-form';

type PROPS<T> = {
  fieldName: Path<T>;
  validations?: object;
  errorMessage?: string;
  text?: string;
};

const FileInput = <T extends FieldValues = never>({
  fieldName,
  text = '写真を添付',
  validations,
  errorMessage,
}: PROPS<T>) => {
  const { register } = useFormContext();

  return (
    <>
      <div className="flex items-center">
        <label
          className="cursor-pointer text-re-primary hover:text-re-primary-hover bg-white font-bold
      border border-re-primary hover:border-re-primary-hover rounded-[6px]
      select-none duration-300 px-6 py-1"
        >
          <input
            type="file"
            className="hidden"
            accept="image/*"
            {...register(fieldName, validations)}
          />
          {text}
        </label>
      </div>
      <div className="text-re-danger">{errorMessage}</div>
    </>
  );
};

export default FileInput;
