import React from 'react';

/**
 * ex)
 * <CheckBox<T>
      id="1"
      label="test"
      value={array}
      checked={array.includes('1')}
      setValue={setArray}
    />
 */

type PROPS<T> = {
  id: string;
  value: T;
  checked: boolean;
  label?: string;
  disabled?: boolean;
  setValue: React.Dispatch<React.SetStateAction<T>>;
  className?: string;
};

const CheckBox = <T,>({
  id,
  value,
  checked,
  label,
  disabled,
  setValue,
  className,
}: PROPS<T>) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(value)) {
      if (value.includes(e.target.value)) {
        setValue(
          value.filter((checkedValue) => checkedValue !== e.target.value) as T
        );
      } else {
        setValue([...value, e.target.value] as T);
      }
    } else {
      if (e.target.checked) {
        setValue(e.target.value as T);
      } else {
        setValue('' as T);
      }
    }
  };

  return (
    <div className="flex">
      <input
        className={`w-[15px] cursor-pointer ${className}`}
        id={id}
        type="checkbox"
        value={id}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      {label && (
        <label
          htmlFor={id}
          className={`ml-[18px] ${!disabled && 'cursor-pointer'}`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
