import Button from 'components/common/Button';
import dayjs from 'dayjs';
import { AiPCSiModel, PCSModel } from 'models';
import React from 'react';
import { Store } from 'store';

type PROPS = {
  power_plant_id?: string;
  data?: AiPCSiModel;
  pcs?: PCSModel[];
};

const PowerPlantAiPCSiPanelItem: React.FC<PROPS> = (props) => {
  const user = Store.loadUserDara();

  const name = props.data?.name ?? 'ー';
  const power_plant_id = props.power_plant_id;
  const aipcsi_id = props.data?.id;

  const isDeleted = props.data && props.data.deleted_at;

  const [pcsData, setPCSData] = React.useState<PCSModel[]>([]);

  const getCaution = (data: PCSModel, sensorName: string) => {
    if (!data || data.thresholds.length === 0) {
      return '-';
    }
    const tmp = data.thresholds.find((v) => v.name === sensorName);
    return tmp ? tmp.caution : '-';
  };

  const getTrouble = (data: PCSModel, sensorName: string) => {
    if (!data || data.thresholds.length === 0) {
      return '-';
    }
    const tmp = data.thresholds.find((v) => v.name === sensorName);
    return tmp ? tmp.trouble : '-';
  };

  React.useEffect(() => {
    if (props.pcs && props.pcs.length > 0) {
      setPCSData(props.pcs);
    } else {
      setPCSData([
        {
          aipcsi_id: '0',
          name: 'ー',
          maker: 'ー',
          model: 'ー',
          thresholds: [],
          deleted_at: dayjs().toISOString(),
        },
      ]);
    }
  }, [props.pcs]);

  return (
    <table className="w-full my-4 border border-re-gray rounded-lg border-separate border-spacing-0 [& th]:p-2">
      <thead>
        <tr
          className={
            (isDeleted ? 'bg-re-gray' : 'bg-re-4D7FAF') +
            ' text-white [&>th]:p-2'
          }
        >
          <th className="rounded-tl-lg">{name}</th>
          <th colSpan={14}></th>
          <th className="rounded-tr-lg">
            <div className="flex justify-end">
              {props.data && !isDeleted && user?.type === 'master' && (
                <Button
                  text="編集"
                  size="sm"
                  outlined
                  link={`/manage/power_plant/detail/${power_plant_id}/aipcsi/edit/${aipcsi_id}/`}
                />
              )}
            </div>
          </th>
        </tr>
        <tr
          className={
            (isDeleted ? 'bg-re-gray' : 'bg-re-4D7FAF') +
            ' text-white [&>th]:p-1 [&>th]:border-t [&>th]:border-black'
          }
        >
          <th rowSpan={2}>PCS管理名称</th>
          <th rowSpan={2}>メーカー名</th>
          <th rowSpan={2}>PCSの型式</th>
          <th colSpan={2}>温度</th>
          <th colSpan={2}>振動</th>
          <th colSpan={2}>U電流</th>
          <th colSpan={2}>V電流</th>
          <th colSpan={2}>W電流</th>
          <th colSpan={2}>EMI</th>
          <th rowSpan={2}></th>
        </tr>
        <tr
          className={
            (isDeleted ? 'bg-re-gray' : 'bg-re-4D7FAF') +
            ' text-white [&>th]:p-1'
          }
        >
          <th>注意</th>
          <th>異常</th>
          <th>注意</th>
          <th>異常</th>
          <th>注意</th>
          <th>異常</th>
          <th>注意</th>
          <th>異常</th>
          <th>注意</th>
          <th>異常</th>
          <th>注意</th>
          <th>異常</th>
        </tr>
      </thead>
      <tbody>
        {pcsData.map((v, i) => {
          const isDeleted = !!v.deleted_at && dayjs(v.deleted_at).isValid();
          const isLast = i === pcsData.length - 1;

          return (
            <tr
              key={i}
              className={
                (isDeleted ? '[&>td]:bg-re-smoke [&>td]:text-re-gray' : '') +
                (isDeleted && isLast ? ' rounded-b-lg' : '') +
                ' [&>td]:p-2'
              }
            >
              <td>{v.name}</td>
              <td>{v.maker}</td>
              <td>{v.model}</td>
              <td>{getCaution(v, '温度')}</td>
              <td>{getTrouble(v, '温度')}</td>
              <td>{getCaution(v, '振動')}</td>
              <td>{getTrouble(v, '振動')}</td>
              <td>{getCaution(v, 'U電流')}</td>
              <td>{getTrouble(v, 'U電流')}</td>
              <td>{getCaution(v, 'V電流')}</td>
              <td>{getTrouble(v, 'V電流')}</td>
              <td>{getCaution(v, 'W電流')}</td>
              <td>{getTrouble(v, 'W電流')}</td>
              <td>{getCaution(v, 'EMI')}</td>
              <td>{getTrouble(v, 'EMI')}</td>

              <td>
                <div className="flex justify-end">
                  {v.id && !v.deleted_at && user?.type === 'master' && (
                    <Button
                      text="編集"
                      size="sm"
                      outlined
                      link={`/manage/power_plant/detail/${power_plant_id}/pcs/edit/${v.id}/`}
                    />
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PowerPlantAiPCSiPanelItem;
