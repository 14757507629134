import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Dialog from 'components/common/Dialog';
import dayjs from 'dayjs';
import { InformationStatusType } from 'enums';
import { InformationModel } from 'models';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

type PROPS = {
  data: InformationModel | undefined;
  status: string;
  onSuspend: () => void;
  onDelete?: () => void;
};

const InformationDetail: React.FC<PROPS> = ({
  data,
  status,
  onSuspend,
  onDelete,
}: PROPS) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = React.useState(false);

  const handleHide = () => {
    const path = location.pathname.split('/');

    if (path[1] === 'inbox') {
      navigate('/inbox');
    } else {
      navigate('/manage/information/');
    }
  };

  return (
    <>
      <Card className="p-6">
        {data && (
          <>
            <div className="flex items-center">
              <div className="flex-1 font-bold text-2xl text-re-063B67">
                配信内容
              </div>
              {status === InformationStatusType.DELIVERY && (
                <Button
                  text="編集"
                  size="sm"
                  outlined
                  link={`/manage/information/edit/${data.id}/`}
                />
              )}
            </div>
            <p className="my-4">
              指定の時刻にメール配信、ダッシュボードのHOME画面に表示をします
            </p>

            <div className="flex font-bold text-lg mb-4 text-re-063B67">
              内容
            </div>

            <div className="flex flex-col gap-8">
              <div className="flex">
                <div className="flex-1 font-bold">件名</div>
                <div className="flex-[2]">{data?.title}</div>
              </div>

              <div className="flex">
                <div className="flex-1 font-bold">本文</div>
                <div className="flex-[2] whitespace-pre-line">{data?.body}</div>
              </div>
            </div>

            <div className="flex font-bold text-lg mt-6 mb-4 text-re-063B67">
              配信方法
            </div>

            <div className="flex flex-col gap-8">
              <div className="flex">
                <div className="flex-1 font-bold">予約投稿</div>
                <div className="flex-[2]">
                  {data?.delivery_on && (
                    <>
                      {dayjs(data.delivery_on).format('YYYY-MM-DD HH:mm')}
                      &nbsp;&nbsp;ー&nbsp;&nbsp;
                    </>
                  )}

                  {data?.delivery_end
                    ? dayjs(data.delivery_end).format('YYYY-MM-DD HH:mm')
                    : '指定なし'}
                </div>
              </div>

              <div className="flex">
                <div className="flex-1 font-bold text-re-danger">
                  ステータス
                </div>
                <div className="flex-[2] text-re-danger">{status}
                {status && status === InformationStatusType.DELIVERY && (
                  <span className="ml-8">
                    ※掲載の開始、終了時間は、最大5分間の遅延があります
                  </span>
                )}</div>
              </div>

              {status && status !== InformationStatusType.DELIVERED && (
                <div className="flex justify-center my-3">
                  <Button
                    text={
                      status === InformationStatusType.DELIVERING
                        ? 'HOMEから下げる'
                        : '予約を取り消す'
                    }
                    color="danger"
                    outlined
                    onClick={() => {
                      if (status === InformationStatusType.DELIVERING) {
                        onSuspend();
                      }
                      setShowDialog(true);
                    }}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </Card>

      <Dialog show={showDialog}>
        {status === InformationStatusType.DELIVERING ? (
          <>
            <p>HOMEに表示中のお知らせを下げました</p>

            <div className="flex justify-end mt-4">
              <Button text="OK" size="sm" onClick={handleHide} />
            </div>
          </>
        ) : status === InformationStatusType.DELIVERY ? (
          <div className="flex flex-col items-center">
            <p className="text-2xl text-re-danger font-bold">
              お知らせ 取り消し
            </p>

            <p className="text-lg text-re-danger font-bold mt-6">
              予約中のお知らせを削除します
            </p>

            <p className="my-6">削除しますか？</p>

            <div className="flex justify-center gap-10">
              <Button
                text="しない"
                color="gray"
                outlined
                onClick={() => {
                  setShowDialog(false);
                }}
              />
              <Button
                text="削除する"
                color="danger"
                onClick={() => {
                  onDelete && onDelete();
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <p>削除しました</p>

            <div className="flex justify-end mt-4">
              <Button text="OK" size="sm" link="/manage/information/" />
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};

export default InformationDetail;
