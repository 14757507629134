import { AiPCSiModel, PCSModel } from 'models';
import React from 'react';
import MobilePowerPlantAiPCSiPanelItem from 'components/manage/power_plant/MobilePowerPlantAiPCSiPanelItem';
import Card from 'components/common/Card';

type PROPS = {
  power_plant_id: string;
  data: AiPCSiModel[] | undefined;
  pcsSet: { [aipcsi_id: string]: PCSModel[] };
  onCopyInfo?: () => void;
  onCSVDoanload?: () => void;
};

const MobilePowerPlantAiPCSiPanel: React.FC<PROPS> = (props) => {
  return (
    <div className="flex justify-center mt-6">
      <Card className="p-6">
        <div className="font-bold text-2xl text-re-063B67 mb-8">
          AiPCSi PCS情報
        </div>
        {props.data && props.data.length > 0 ? (
          props.data.map((AiPCSi, index) => {
            return (
              <MobilePowerPlantAiPCSiPanelItem
                key={index}
                data={AiPCSi}
                pcs={props.pcsSet[AiPCSi.id!]}
              />
            );
          })
        ) : (
          <MobilePowerPlantAiPCSiPanelItem />
        )}
      </Card>
    </div>
  );
};

export default MobilePowerPlantAiPCSiPanel;
