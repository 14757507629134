/** ユーザーの権限種別 */
export const enum UserRoleType {
  /**  */
  MASTER = 'master',
  /**  */
  ADMIN = 'admin',
  /**  */
  MAIN = 'main',
  /**  */
  SUB = 'sub',
}

/** 「ユーザーの権限種別」の文字列を返します。 */
export function getUserRoleTypeStr(type: string) {
  switch (type) {
    case UserRoleType.MASTER:
      return 'マスター';
    case UserRoleType.ADMIN:
      return 'アドミン';
    case UserRoleType.MAIN:
      return 'メイン';
    case UserRoleType.SUB:
      return 'サブ';
    default:
      return '';
  }
}
