import Button from 'components/common/Button';
import Card from 'components/common/Card';
import { AlertTreeAPIModel } from 'models';
import React from 'react';

type PROPS = {
  data?: AlertTreeAPIModel;
};

const MobileBasicInformationCard: React.FC<PROPS> = (props: PROPS) => {
  const [data, setData] = React.useState<AlertTreeAPIModel>();

  React.useEffect(() => {
    if (props.data) {
      setData(props.data);
    } else {
      setData(undefined);
    }
  }, [props.data]);

  return (
    <Card className="p-6">
      <div className="font-bold text-2xl text-re-063B67 mb-8">
        発電所 基本情報
      </div>
      <div className="mb-8 flex justify-center w-full">
        <Button
          text="発電所 詳細へ"
          link={`/manage/power_plant/detail/${data?.power_plant.id}/`}
        />
      </div>
      <div className="mb-6">
        <div className="font-bold text-xl text-re-063B67 mb-6">PCS情報</div>
        <div className="">
          <div className="grid grid-cols-2 flex items-center">
            <div className="col-span-1 font-medium text-sm mb-2">PCS名称</div>
            <div className="col-span-1 font-bold text-lg text-right mb-2">
              {data?.pcs.name}
            </div>
          </div>
          <div className="grid grid-cols-2 flex items-center">
            <div className="col-span-1 font-medium text-sm mb-2">
              メーカー名
            </div>
            <div className="col-span-1 font-bold text-lg text-right mb-2">
              {data?.pcs.maker}
            </div>
          </div>
          <div className="grid grid-cols-2 flex items-center">
            <div className="col-span-1 font-medium text-sm mb-2">PCS型式</div>
            <div className="col-span-1 font-bold text-lg text-right mb-2">
              {data?.pcs.model}
            </div>
          </div>
          <div className="grid grid-cols-2 flex items-center">
            <div className="col-span-1 font-medium text-sm mb-2">
              紐づいているAiPCSi
            </div>
            <div className="col-span-1 font-bold text-lg text-right mb-2">
              {data?.pcs.aipcsi_name}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="font-bold text-xl text-re-063B67 mb-6">発電所 情報</div>
        <div>
          <div className="mb-8">
            <div className="font-medium text-sm">発電所名</div>
            <div className="font-bold text-lg">{data?.power_plant.name}</div>
          </div>
          <div className="mb-8">
            <div className="font-medium text-sm">発電所住所</div>
            <div className="font-bold text-lg">{data?.power_plant.address}</div>
          </div>
        </div>
      </div>
      <div>
        <div className="font-bold text-xl text-re-063B67 mb-8">
          発電所 担当者情報
        </div>
        <div className="mb-6">
          <div className="font-medium text-sm">担当者名</div>
          <div className="font-bold text-lg">
            {data?.power_plant.manager_last_name}
            {data?.power_plant.manager_first_name}
          </div>
        </div>
        <div className="mb-8">
          <div className="font-medium text-sm">所属・部署</div>
          <div className="font-bold text-lg">
            {data?.power_plant.department}
          </div>
        </div>
        <div className="mb-8">
          <div className="font-medium text-sm">発電所住所</div>
          <div className="font-bold text-lg">
            {data?.power_plant.manager_address}
          </div>
        </div>
        <div className="mb-2">
          <div className="font-medium text-sm">電話番号</div>
          <div className="font-bold text-lg">
            {data?.power_plant.manager_tel}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MobileBasicInformationCard;
