import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { createFadingAnime } from 'styles/transition';
import Button from './Button';

type PROPS = {
  show: boolean;
  width?: string;
  confirmText?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  children?: React.ReactNode;
};

const Dialog: React.FC<PROPS> = ({
  show,
  width = 'w-[700px]',
  confirmText = 'OK',
  onClose,
  onConfirm,
  children,
}: PROPS) => {
  const nodeRef = React.useRef(null); // StrictModeエラーの対処用
  const fade = createFadingAnime(0.3);

  // モーダルの外側をクリックで閉じる
  const clickOutSideToClose = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget && onClose) onClose();
  };

  return (
    <>
      <CSSTransition
        in={show}
        timeout={{ enter: 0, exit: 100 }}
        unmountOnExit
        nodeRef={nodeRef}
      >
        {(state) => (
          <div
            className="fixed top-0 left-0 h-full w-full overflow-y-scroll sm:overflow-hidden bg-black bg-opacity-60 flex justify-center items-start z-[999] pb-9"
            style={fade[state]}
            onClick={(e) => {
              clickOutSideToClose(e);
            }}
          >
            <div
              className={`${width} bg-white mt-[80px] rounded-[8px] p-[24px]`}
            >
              {children}

              {onConfirm && (
                <div className="flex justify-end mt-[16px]">
                  <Button text={confirmText} size="sm" onClick={onConfirm} />
                </div>
              )}
            </div>
          </div>
        )}
      </CSSTransition>
    </>
  );
};

export default Dialog;
