import React from 'react';
import dayjs from 'dayjs';
import { MapPinModel, MemoListModel } from 'models';
import Button from 'components/common/Button';
import { FiEdit } from 'react-icons/fi';
import { BsTrash } from 'react-icons/bs';
import {
  getMemoStatusStr,
  ApprovalDialogType,
  ApprovalStatusType,
} from 'enums';

type PROPS = {
  mapPin: MapPinModel | undefined;
  memoList: Array<MemoListModel>;
  isApprovePage?: boolean;
  setShowDialogType?: React.Dispatch<React.SetStateAction<string>>;
  setMemo?: React.Dispatch<React.SetStateAction<MemoListModel | undefined>>;
  setImageSrc: React.Dispatch<React.SetStateAction<string>>;
};

const MapSideMenu: React.FC<PROPS> = ({
  mapPin,
  memoList,
  isApprovePage,
  setShowDialogType,
  setMemo,
  setImageSrc,
}: PROPS) => {
  return (
    <div>
      <div className="m-4">
        <span className="text-xs text-re-gray">ピンのタイトル</span>

        <p className="text-xl font-bold">{mapPin?.title}</p>
      </div>

      <div className="m-4">
        <div className="text-re-primary font-bold text-lg mt-4">
          {isApprovePage ? 'コメント' : 'コメント履歴'}
        </div>
        {memoList && (
          <div
            className={`${
              isApprovePage ? 'h-[400px]' : 'h-[525px]'
            } overflow-y-scroll pr-2`}
          >
            {memoList.map((v, i) => (
              <div className="relative my-3" key={i}>
                <div className="flex justify-between items-center">
                  <div className="text-xs flex">
                    <div className="w-[95px] whitespace-nowrap">
                      {dayjs(v.created_at).format('YYYY/MM/DD HH:mm')}
                    </div>
                    <div className="line-clamp-1 break-all mx-3">
                      {v.user_name}
                    </div>
                  </div>

                  {!isApprovePage &&
                    v.status === ApprovalStatusType.DISPLAY && (
                      <div className="flex">
                        <FiEdit
                          className="text-re-gray cursor-pointer"
                          onClick={() => {
                            setShowDialogType &&
                              setShowDialogType(ApprovalDialogType.UPDATE);
                            setMemo && setMemo(v);
                          }}
                        />
                        <BsTrash
                          className="text-re-danger opacity-70 cursor-pointer ml-6"
                          onClick={() => {
                            setShowDialogType &&
                              setShowDialogType(ApprovalDialogType.DELETE);
                            setMemo && setMemo(v);
                          }}
                        />
                      </div>
                    )}
                </div>
                {getMemoStatusStr(v.status) !== '' && (
                  <div className="text-xs bg-re-smoke w-fit mt-2 px-2 py-1">
                    「{getMemoStatusStr(v.status)}」承認待ち
                  </div>
                )}
                <p className="text-sm mt-2">
                  {isApprovePage && v.memo_pending ? v.memo_pending : v.memo}
                </p>
                {v.memo_file_url && (
                  <img
                    src={v.memo_file_url}
                    alt="comment_img"
                    className="max-h-[300px] object-contain cursor-pointer"
                    onClick={() => setImageSrc(v.memo_file_url || '')}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {mapPin && mapPin.id && (
        <>
          <div className="border border-b-1" />

          <div className="flex justify-center items-center mt-6">
            <Button
              text="新規コメント"
              onClick={() =>
                setShowDialogType &&
                setShowDialogType(ApprovalDialogType.CREATE)
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MapSideMenu;
