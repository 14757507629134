import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  ChartOptions,
  LinearScale,
  LineElement,
  PointElement,
} from 'chart.js';
import { PCSLogModel } from 'models';
import React from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

type PROPS = {
  isAIDiagnosis: boolean;
  display: string[];
  data?: PCSLogModel;
};

const Chart: React.FC<PROPS> = (props) => {
  const [data, setData] = React.useState<ChartData<'line', number[], string>>({
    labels: [],
    datasets: [],
  });

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        // labels,
        ticks: {
          autoSkip: false,
          stepSize: 60,
          maxRotation: 0,
        },
        grid: {
          display: false,
        },
      },
    },
    animation: false,
  };

  React.useEffect(() => {
    if (!props.data) {
      return;
    }

    const labels = [...Array(6 * 24)].map((_, i) => {
      if (i % 18 === 0) {
        return `${(i / 6).toString().padStart(2, '0')}:00`;
      } else {
        return '';
      }
    });

    const f = props.isAIDiagnosis
      ? (data: string[], color: string) => {
          datasets.push({
            data: data.filter((v, i) => i % 10 === 0).map((v) => Number(v)),
            borderColor: color,
            pointStyle: false,
          });
        }
      : (data: string[], color: string) => {
          datasets.push({
            data: data.filter((v, i) => i % 10 === 0).map((v) => Number(v)),
            borderColor: color,
            pointStyle: false,
          });
        };
    const datasets: ChartDataset<'line', number[]>[] = [];
    if (props.display.includes('1')) {
      f(props.data.indoor_temperature, '#1C8EC3');
    }
    if (props.display.includes('2')) {
      f(props.data.cooling_fan_vibration, '#E02522');
    }
    if (props.display.includes('3')) {
      f(props.data.inverter_u, '#60987E');
    }
    if (props.display.includes('4')) {
      f(props.data.inverter_v, '#E34E23');
    }
    if (props.display.includes('5')) {
      f(props.data.inverter_w, '#8494A7');
    }
    if (props.display.includes('6')) {
      f(props.data.emi_level, '#B08900');
    }
    setData({ labels, datasets });
  }, [props.display, props.data, props.isAIDiagnosis]);

  return (
    <div className="relative flex items-center justify-center h-[500px] border rounded-[4px]">
      <div className="absolute w-[98%]">
        <Line data={data} width={3500} height={450} options={options} />
      </div>
    </div>
  );
};

export default Chart;
