import Button from 'components/common/Button';
import React from 'react';

type PROPS = {
  changePWPagePath: string | null;
  isDeleted?: boolean;
};

const UserDetailPanel2: React.FC<PROPS> = (props) => {
  return (
    <div className="drop-shadow rounded bg-white p-6 flex flex-col gap-7">
      <div className="flex justify-between">
        <div className="font-bold text-2xl text-re-063B67">パスワード変更</div>
        {props.changePWPagePath && !props.isDeleted ? (
          <Button
            text="変更"
            size="sm"
            outlined
            link={props.changePWPagePath}
          />
        ) : (
          <></>
        )}
      </div>

      <div className="flex flex-col gap-8">
        <div className="flex">
          <div className="flex-1 font-bold">パスワード</div>
          <div className="flex-[2]">*********************</div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailPanel2;
