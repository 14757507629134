import Button from 'components/common/Button';
import Card from 'components/common/Card';
import { AlertTreeAPIModel } from 'models';
import React from 'react';

type PROPS = {
  data?: AlertTreeAPIModel;
};

const BasicInformationCard: React.FC<PROPS> = (props: PROPS) => {
  const [data, setData] = React.useState<AlertTreeAPIModel>();

  React.useEffect(() => {
    if (props.data) {
      setData(props.data);
    } else {
      setData(undefined);
    }
  }, [props.data]);

  return (
    <Card className="p-6 mb-3">
      <div className="flex justify-between items-center">
        <div className="font-bold text-2xl text-re-063B67">発電所基本情報</div>
        <Button
          text="発電所 詳細へ"
          outlined
          link={`/manage/power_plant/detail/${data?.power_plant.id}/`}
        />
      </div>

      <div className="font-bold text-medium text-re-063B67 pt-5 pb-3">
        PCS情報
      </div>
      <div className="flex items-center mb-3">
        <div className="flex-1 font-bold">PCSの名称</div>
        <div className="flex flex-[2] items-center">{data?.pcs.name}</div>
      </div>
      <div className="flex items-center mb-3">
        <div className="flex-1 font-bold">PCSのメーカー名</div>
        <div className="flex flex-[2] items-center">{data?.pcs.maker}</div>
      </div>
      <div className="flex items-center mb-3">
        <div className="flex-1 font-bold">PCSの型式</div>
        <div className="flex flex-[2] items-center">{data?.pcs.model}</div>
      </div>
      <div className="flex items-center mb-3">
        <div className="flex-1 font-bold">紐づいているAiPCSi</div>
        <div className="flex flex-[2] items-center">
          {data?.pcs.aipcsi_name}
        </div>
      </div>

      <div className="font-bold text-medium text-re-063B67 pt-5 pb-3">
        発電所 情報
      </div>
      <div className="flex items-center mb-3">
        <div className="flex-1 font-bold">発電所名</div>
        <div className="flex flex-[2] items-center">
          {data?.power_plant.name}
        </div>
      </div>
      <div className="flex items-center mb-3">
        <div className="flex-1 font-bold">発電所住所</div>
        <div className="flex flex-[2] items-center">
          {data?.power_plant.address}
        </div>
      </div>

      <div className="font-bold text-medium text-re-063B67 pt-5 pb-3">
        発電所 担当者情報
      </div>
      <div className="flex items-center mb-3">
        <div className="flex-1 font-bold">担当者名</div>
        <div className="flex flex-[2] items-center">
          {data?.power_plant.manager_last_name}
          {data?.power_plant.manager_first_name}
        </div>
      </div>
      <div className="flex items-center mb-3">
        <div className="flex-1 font-bold">所属・部署</div>
        <div className="flex flex-[2] items-center">
          {data?.power_plant.department}
        </div>
      </div>
      <div className="flex items-center mb-3">
        <div className="flex-1 font-bold">住所</div>
        <div className="flex flex-[2] items-center">
          {data?.power_plant.manager_address}
        </div>
      </div>
      <div className="flex items-center mb-3">
        <div className="flex-1 font-bold">電話番号</div>
        <div className="flex flex-[2] items-center">
          {data?.power_plant.manager_tel}
        </div>
      </div>
    </Card>
  );
};

export default BasicInformationCard;
