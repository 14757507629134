import API from 'api';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Head from 'components/common/Head';
import TextField from 'components/common/TextField';
import MobilePagination from 'components/common/pagination/MobilePagination';
import PlantCard from 'components/manage/power_plant/PlantCard';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { OutletContextModel, PowerPlantListModel } from '../../models';
import Utils from 'utils';

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;

const MobileManagePowerPlantPage: React.FC = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const [data, setData] = React.useState<PowerPlantListModel[]>([]);
  const [filtered, setFiltered] = React.useState<PowerPlantListModel[]>([]);
  const [items, setItems] = React.useState<PowerPlantListModel[]>([]);
  const [page, setPage] = React.useState(1);

  const onFilter = () => {
    let filtered = data;
    const filterText = methods.getValues().keyword;
    if (filterText !== '') {
      filtered = data.filter(
        (v) =>
          v.power_plant_name.includes(filterText) ||
          v.address.includes(filterText) ||
          v.manager_first_name.includes(filterText) ||
          v.manager_last_name.includes(filterText)
      );
    }
    setPage(1);
    setFiltered(filtered);
    setItems(filtered.slice(0, NUM_IN_PAGE));
  };

  const onChangePage = (page: number) => {
    setPage(page);
    if (!filtered) {
      setItems([]);
      return;
    }

    const start = NUM_IN_PAGE * (page - 1);
    const end = start + NUM_IN_PAGE;
    setItems(filtered.slice(start, end));
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.PowerPlant.list();
        console.log(response);
        if (response.data) {
          setData(response.data);
          setItems(response.data.slice(NUM_IN_PAGE * (page - 1), NUM_IN_PAGE));
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm<FormState>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      keyword: '',
    },
  });
  type FormState = {
    keyword: string;
  };
  return (
    <div className="w-full p-4 bg-re-F3F7FA">
      <Head title="発電所一覧" />
      <div className="mb-3 font-bold text-3xl text-re-063B67">発電所一覧</div>
      <div className="flex justify-center">
        <Card className="p-6">
          <div className="font-bold text-2xl text-re-063B67">発電所一覧</div>
          <div className="flex justify-center py-3 items-center">
            <FormProvider {...methods}>
              <TextField
                fieldName="keyword"
                width="w-full"
                placeholder="例： 発電所名 など"
              />
            </FormProvider>
            <div className="pl-4">
              <Button text="絞り込む" size="sm" onClick={onFilter} />
            </div>
          </div>
          {items.map((item, index) => {
            const isDeleted = !(
              item.deleted_at === undefined ||
              item.deleted_at === null ||
              typeof item.deleted_at === 'string'
            );
            return (
              <PlantCard
                key={index}
                className="mb-3"
                headerClass="bg-re-4D7FAF text-white font-bold"
                mainClass="truncate"
                mainTitle={item.power_plant_name}
                subTitle={item.company_name}
              >
                <div
                  className={`p-3 ${isDeleted ? 'bg-re-smoke text-re-gray' : ''
                    }`}
                >
                  <div className="gap-y-3 pb-3">
                    <div className="text-left font-medium text-sm mb-3 items-center">
                      発電所住所
                    </div>
                    <div className="text-left font-bold text-lg mb-8 items-center">
                      {item.address}
                    </div>
                    <div className="grid grid-cols-2 mb-8 items-center">
                      <div className="text-left font-medium text-sm">
                        AiPCSi稼働台数
                      </div>
                      <div className="font-bold text-right">
                        {item.aipcsi_count}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-8 items-center">
                      <div className="text-left font-medium text-sm">
                        PCS稼働台数
                      </div>
                      <div className="font-bold pl-2 text-right">
                        {item.pcs_count}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-8 items-center">
                      <div className="text-left font-medium text-sm">
                        発電所担当
                      </div>
                      <div className="font-bold pl-2 text-right">
                        {item.manager_last_name}&nbsp;{item.manager_first_name}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <Button
                      text="発電所詳細"
                      size="md"
                      link={`/manage/power_plant/detail/${item.id}/`}
                    />
                  </div>
                </div>
              </PlantCard>
            );
          })}
          <div>
            <MobilePagination
              current={page}
              count={Math.ceil(filtered.length / NUM_IN_PAGE)}
              onChange={onChangePage}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default MobileManagePowerPlantPage;
