import axios from 'axios';
import { AiPCSiListAPIModel, AiPCSiModel } from 'models';

export async function create(data: AiPCSiModel) {
  const response = await axios.post<AiPCSiModel>('/aipcsi', data);
  return response;
}

export async function list(power_plant_id: number | string | undefined) {
  const response = await axios.get<AiPCSiListAPIModel>(
    `/aipcsi/power_plant/${power_plant_id}`
  );
  return response;
}

export async function get(id?: string) {
  const response = await axios.get<AiPCSiModel>(`/aipcsi/${id}`);
  return response;
}

export async function update(data: AiPCSiModel) {
  const response = await axios.put(`/aipcsi/${data.id}`, data);
  return response;
}

export async function del(id: string) {
  const response = await axios.delete(`/aipcsi/${id}`);
  return response;
}
