import axios from 'axios';
import { CompanyModel } from 'models';

export async function create(data: CompanyModel) {
  const response = await axios.post<CompanyModel | null>(`/company`, data);
  return response;
}

export async function list() {
  const response = await axios.get<CompanyModel[]>('/company');
  return response;
}

export async function get(id: string) {
  const response = await axios.get<CompanyModel | null>(`/company/${id}`);
  return response;
}

export async function update(data: CompanyModel) {
  const response = await axios.put(`/company/${data.id}`, data);
  return response;
}

export async function del(id: string) {
  const response = await axios.delete(`/company/${id}`);
  return response;
}

export async function domain() {
  const response = await axios.get<string>(`/company/domain`);
  return response;
}
