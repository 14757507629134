import FormLabel from 'components/common/FormLabel';
import Link from 'components/common/Link';
import SelectBox from 'components/common/SelectBox';
import { AiPCSiListAPIModel, PCSModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {
  powerPlantId: string;
  aipcsiList?: AiPCSiListAPIModel;
};

const PCSEditFormBlock1: React.FC<PROPS> = (props) => {
  const { formState } = useFormContext<PCSModel>();

  const [options, setOptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  React.useEffect(() => {
    if (props.aipcsiList) {
      const tmp = props.aipcsiList.aipcsis.map((item) => {
        return { label: item.name, value: item.id! };
      });
      setOptions(tmp);
    } else {
      setOptions([]);
    }
  }, [props.aipcsiList]);

  return (
    <div className="flex flex-col gap-8">
      <div className="font-bold text-xl text-re-063B67">AiPCSi との紐付け</div>

      <div className="grid grid-cols-4 gap-y-8">
        <div className="col-span-1">
          <span className="font-bold">発電事業者</span>
        </div>
        <div className="col-span-3">{props.aipcsiList?.company_name}</div>

        <div className="col-span-1">
          <span className="font-bold">発電所</span>
        </div>
        <div className="col-span-3">{props.aipcsiList?.power_plant_name}</div>

        <FormLabel text="接続するAiPCSi" required />
        <div className="col-span-3">
          <SelectBox
            fieldName="aipcsi_id"
            options={options}
            blankLabel="接続させるAiPCSiを選択"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.aipcsi_id?.message}
          />
          <div className="mt-4 flex justify-end">
            <Link
              to={`/manage/power_plant/detail/${props.powerPlantId}/aipcsi/create`}
            >
              <span className="text-re-primary hover:underline">
                AiPCSiの新規登録をする
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PCSEditFormBlock1;
