import FormLabel from 'components/common/FormLabel';
import TextField from 'components/common/TextField';
import { AiPCSiModel, CompanyModel, PowerPlantModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {
  powerPlant?: PowerPlantModel;
  company?: CompanyModel;
};

const AiPCSiEditFormBlock: React.FC<PROPS> = (props) => {
  const { formState } = useFormContext<AiPCSiModel>();

  return (
    <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
      <div className="font-bold text-xl text-re-063B67">発電所 情報</div>

      <div className="flex">
        <div className="flex-1">
          <span className="font-bold">発電事業者</span>
        </div>
        <div className="flex-[3]">{props.company?.company_name}</div>
      </div>

      <div className="flex">
        <div className="flex-1">
          <span className="font-bold">発電所</span>
        </div>
        <div className="flex-[3]">{props.powerPlant?.name}</div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="AiPCSiの名称" required />
        <div className="flex-1">
          <TextField
            fieldName="name"
            placeholder="例：AiPCSi-001-北 (現場で管理しているAiPCSi名称)"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.name?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default AiPCSiEditFormBlock;
