import { Path, FieldValues, useFormContext } from 'react-hook-form';

type Props<T> = {
  fieldName: Path<T>; // fieldname

  validations?: object; // validation

  width?: string;

  placeholder?: string;

  disabled?: boolean;

  readonly?: boolean;

  label?: string;

  require?: boolean;

  labelWidth?: string;

  errorMessage?: string;

  fieldType?: string;

  handleBluer?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextField = <T extends FieldValues = never>({
  fieldName,
  validations,
  placeholder,
  disabled,
  readonly,
  label,
  width,
  require,
  labelWidth,
  errorMessage,
  fieldType,
  handleBluer,
}: Props<T>) => {
  const { register } = useFormContext();

  const style = {
    container: `flex w-full`,
    label: `${labelWidth ? labelWidth : 'w-1/4'}`,
    labelText: `whitespace-nowrap font-medium`,
    require: `whitespace-nowrap ml-[8px] ${
      require ? 'text-re-danger' : 'text-re-gray'
    }`,
    textFieldBox: `block ${
      label && !width ? 'w-3/4' : width ? width : 'w-full'
    }`,
    textField: `outline outline-1 px-[16px] placeholder-re-gray font-normal duration-200
      ${
        !readonly &&
        !errorMessage &&
        'focus:outline-re-blue200 focus:bg-re-blue100 focus:caret-re-blue200'
      }
      w-full h-[48px]
      ${
        disabled
          ? 'outline-re-gray bg-re-smoke'
          : errorMessage
          ? 'outline-re-danger bg-re-red50'
          : 'outline-re-gray bg-re-blue50'
      }`,
  };

  return (
    <div className={style.container}>
      {label && (
        <div className={style.label}>
          <label className={style.labelText}>{label}</label>
          <span className={style.require}>{require ? '必須' : '任意'}</span>
        </div>
      )}

      <div className={style.textFieldBox}>
        <input
          type={fieldType ?? 'text'}
          className={style.textField}
          placeholder={placeholder}
          readOnly={readonly}
          disabled={disabled}
          {...register(fieldName, validations)}
          onBlur={handleBluer}
        />
        <div className="text-re-danger">{errorMessage}</div>
      </div>
    </div>
  );
};

export default TextField;
