import Card from 'components/common/Card';
import dayjs from 'dayjs';
import { getAlertColor, getAlertTypeStr } from 'enums';
import { AlertTreeAPIModel, AlertTreeModel } from 'models';
import React from 'react';
import Utils from 'utils';

type PROPS = {
  data?: AlertTreeAPIModel;
};

const MobileAlertDetailCard: React.FC<PROPS> = ({ data }: PROPS) => {
  // 最新のアラート
  const [alert, setAlert] = React.useState<AlertTreeModel>();

  React.useEffect(() => {
    if (data && data.alert_tree.length > 0) {
      setAlert(data.alert_tree[0]);
    } else {
      setAlert(undefined);
    }
  }, [data]);

  return (
    <Card className="p-6 mb-3">
      <div className="font-bold text-2xl text-re-063B67 mb-8">アラート</div>
      <div className="mb-8">
        <div className="font-bold text-xl text-re-063B67 mb-6">現在のアラート</div>
        <div className="font-medium text-sm mb-2">
          {alert && dayjs(alert.captured_at).format('YYYY/MM/DD HH:mm:ss')}
        </div>
        <div className="flex-[2] items-center mb-8">
          <div className=" w-28 text-center">
            <div
              className={`px-6 p-1 font-bold
            ${getAlertColor(alert ? alert.type : '')} border-4`}
            >
              {getAlertTypeStr(alert ? alert.type : '')}
            </div>
          </div>
          <div className="font-bold text-lg mb-2">
            {Utils.Alert.getMessage(alert)}
          </div>
          <div className="font-medium text-sm">
            {Utils.Alert.getSubMessage(alert)}
          </div>
        </div>
        <div className="mb-8">
          <div className="font-bold text-xl text-re-063B67 mb-8">
            状況確認とアラートの解除
          </div>
          <div className="mb-8">
            <div className="font-bold text-lg mb-4">① 状態を確認</div>
            <div className="font-medium text-base mb-2">
              取得データや、現場の状態など確認をしてください
            </div>
            <div className="font-bold text-sm text-re-danger">
              ※AiPCSi故障予知の数値はPCでご確認ください
            </div>
          </div>
          <div className="mb-8">
            <div className="font-bold text-lg mb-4">② アラート解除</div>
            <div className="font-medium text-base mb-2">
              閾値より下回るとアラートを解除できます。アラートを解除をすると、該当のアラートはHOMEから非表示になります。
              ※サブの方は解除できません。上の権限の方に依頼してください
            </div>
            <div className="font-bold text-sm text-re-danger mb-2">
              ※アラート解除はPCから行えます
            </div>
          </div>
          <div className="mb-8">
            <div className="font-bold text-lg mb-4">③ アラート解除者</div>
            <div className="grid grid-cols-2">
              <div className="font-medium text-sm col-span-1">対応者</div>
              <div className="font-bold text-lg col-span-1 text-right">
                {alert && alert.canceled_by ? alert.canceled_by : '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MobileAlertDetailCard;
