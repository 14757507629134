import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import PowerPlantAiPCSiPanel from 'components/manage/power_plant/PowerPlantAiPCSiPanel';
import dayjs from 'dayjs';
import {
  AiPCSiModel,
  CompanyModel,
  OutletContextModel,
  PCSModel,
  PowerPlantModel,
} from 'models';
import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Store } from 'store';
import Utils from 'utils';

const ManagePowerPlantDetailPage: React.FC = () => {
  const { id } = useParams();

  const user = Store.loadUserDara();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const [data, setData] = React.useState<PowerPlantModel>();
  const [AiPCSis, setAiPCSis] = React.useState<AiPCSiModel[]>([]);
  const [pcsSet, setPCSSet] = React.useState<{ [id: string]: PCSModel[] }>({});

  const [email, setEmail] = React.useState('');
  const [emails, setEmails] = React.useState<
    { email_id: string; email: string }[]
  >([]);

  // AiPCSi/PCS 情報コピー用テキスト
  const [company, setCompany] = React.useState<CompanyModel>();
  const [copyText, setCopyText] = React.useState<string | null>(null);

  const isDeleted = () => {
    if (!data) {
      return false;
    }
    return !!data.deleted_at && dayjs(data.deleted_at).isValid();
  };

  const load = async () => {
    try {
      const response = await API.PowerPlant.get(id);
      console.log(response);
      if (response.data) {
        setData(response.data);
        setEmails(response.data.emails ?? []);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onCopyInfo = async () => {
    let companyName = company?.company_name;
    if (!companyName) {
      ctx.spinner.setState(true);
      try {
        const company_id = data?.company_id!;
        const response = await API.Company.get(company_id);
        console.log(response);
        if (response.data) {
          companyName = response.data.company_name;
          setCompany(response.data);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    }
    let text = `${companyName}\n`;
    text += `発電所名:${data?.name}\n`;
    text += `発電所住所:${data?.address}\n\n`;
    for (const aipcsi of AiPCSis) {
      text += `AiPCSi:${aipcsi.name}(${aipcsi.id})\n`;
      const pcss = pcsSet[aipcsi.id!] ?? [];
      if (pcss.length > 0) {
        for (const pcs of pcss) {
          text += `PCS:${pcs.name}(${pcs.id})\n`;
        }
      } else {
        text += `PCS:紐づけているPCSはありません\n`;
      }
      text += '\n';
    }
    setCopyText(text);
  };

  const onCSVDoanload = async () => {
    ctx.spinner.setState(true);
    try {
      const response = await API.PowerPlant.csvDownload(id!);
      console.log(response);
      if (response.data) {
        // console.log(response.data);
        const blob = response.data;
        const link = document.createElement('a');
        link.download = `${dayjs().format('YYYYMMDD')}_AiPCSi_PCS_ID_${
          data?.name
        }.csv`;
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
      }
    } catch (e) {
      console.error(e);
    }
    ctx.spinner.setState(false);
  };

  const onAddEmail = async () => {
    // バリデーション
    ctx.spinner.setState(true);
    try {
      const response = await API.PowerPlant.addEmail(id!, email);
      console.log(response);
      if (response.data) {
        await load();
        setEmail('');
      }
      ctx.msgDialog.open('登録しました');
    } catch (e) {
      console.error(e);
      ctx.msgDialog.open('登録できませんでした。入力に誤りがあります。');
    }
    ctx.spinner.setState(false);
  };
  const onRemoveEmail = async (email_id: string) => {
    ctx.spinner.setState(true);
    try {
      const response = await API.PowerPlant.deleteEmail(id!, email_id);
      console.log(response);
      if (response.data) {
        const idx = emails.findIndex((v) => v.email_id === email_id);
        emails.splice(idx, 1);
        setEmails([...emails]);
      }
      ctx.msgDialog.open('解除しました');
    } catch (e) {
      console.error(e);
      ctx.msgDialog.open('解除できませんでした。入力に誤りがあります。');
    }
    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      await load();
      try {
        const response = await API.AiPCSi.list(id);
        console.log(response);
        if (response.data) {
          const aipcsis = response.data.aipcsis;
          setAiPCSis(aipcsis);
          for (const aipcsi of aipcsis) {
            const response = await API.PCS.list(aipcsi.id);
            console.log(response);
            pcsSet[aipcsi.id!] = response.data;
            setPCSSet({ ...pcsSet });
          }
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="発電所詳細" />
      <Breadcrumbs
        items={[
          { label: '発電所 管理', path: '/manage/power_plant/' },
          { label: '発電所 詳細' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">発電所 詳細</div>
      <div className="drop-shadow rounded bg-white p-6 flex flex-col gap-7">
        <div className="flex justify-between">
          <div className="font-bold text-2xl text-re-063B67">
            発電所 基本情報
          </div>
          {user?.type === 'master' && !isDeleted() && (
            <Button
              text="編集"
              size="sm"
              outlined
              link={`/manage/power_plant/edit/${id}/`}
            />
          )}
        </div>

        <div className="flex flex-col gap-8">
          <div className="font-bold text-re-063B67 text-xl">法人 情報</div>
          <div className="flex">
            <div className="flex-1 font-bold">発電所名</div>
            <div className="flex-[2]">{data?.name}</div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">発電所住所</div>
            <div className="flex-[2]">{data?.address}</div>
          </div>

          <div className="font-bold text-re-063B67 text-xl">
            発電所 担当者情報
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">担当者名</div>
            <div className="flex-[2]">
              {data?.manager_last_name}&nbsp;{data?.manager_first_name}
            </div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">所属・部署</div>
            <div className="flex-[2]">{data?.department}</div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">住所</div>
            <div className="flex-[2]">{data?.manager_address}</div>
          </div>
          <div className="flex">
            <div className="flex-1 font-bold">電話番号</div>
            <div className="flex-[2]">{data?.manager_tel}</div>
          </div>
        </div>
      </div>

      <PowerPlantAiPCSiPanel
        power_plant_id={data?.id!}
        data={AiPCSis}
        pcsSet={pcsSet}
        onCopyInfo={onCopyInfo}
        onCSVDoanload={onCSVDoanload}
      />

      <div className="drop-shadow rounded bg-white p-6 flex flex-col gap-4">
        <div className="font-bold text-2xl text-re-063B67">
          通知を受け取るメールアドレス登録
        </div>
        <div className="text-sm">
          PCS故障予知アラート、定期レポート、システムに関するお知らせ、サブユーザーがMAP機能を操作した際の承認通知
        </div>
        <div className="flex gap-24">
          <div className="font-bold">メールアドレスの登録</div>
          <div className="flex flex-col gap-4">
            {emails.map((item, i) => {
              return (
                <div key={i} className="flex gap-4">
                  <div className="flex-1">{item.email}</div>
                  <Button
                    text="解除"
                    size="sm"
                    outlined
                    onClick={() => {
                      onRemoveEmail(item.email_id);
                    }}
                  />
                </div>
              );
            })}
            <div className="flex items-center gap-4">
              <div className="w-96">
                <input
                  className="w-full p-2 border-2 border-zinc-700 disabled:bg-re-smoke"
                  placeholder="例：xxxx-mailing-list@sample.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  disabled={isDeleted()}
                />
              </div>
              <Button
                text="登録"
                size="sm"
                onClick={onAddEmail}
                disabled={email.length === 0}
              />
            </div>
          </div>
        </div>
      </div>
      {/* AiPCSi/PCS 情報コピー ダイアログ */}
      <Dialog
        width="w-[841px]"
        show={copyText !== null}
        onClose={() => {
          setCopyText(null);
        }}
      >
        <div className="whitespace-pre-line">{copyText}</div>
        <div className="flex justify-center gap-4">
          <button
            className="w-56 bg-re-primary py-2 rounded-lg hover:bg-re-primary-hover duration-100 transition-colors"
            onClick={async () => {
              if (navigator.clipboard) {
                await navigator.clipboard.writeText(copyText!);
              }
              setCopyText(null);
            }}
          >
            <span className="font-bold text-white">コピーして閉じる</span>
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default ManagePowerPlantDetailPage;
