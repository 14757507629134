import API from 'api';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Head from 'components/common/Head';
import TextField from 'components/common/TextField';
import MobilePagination from 'components/common/pagination/MobilePagination';
import MobileAlertCard from 'components/monitor/alert/MobileAlertCard';
import dayjs from 'dayjs';
import { AlertModel, OutletContextModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { getAlertColor, getAlertTypeStr } from '../../enums';
import Utils from 'utils';

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;

type FormState = {
  keyword: string;
};

const MobileMonitorAlertPage: React.FC = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const methods = useForm<FormState>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      keyword: '',
    },
  });

  const [data, setData] = React.useState<AlertModel[]>([]);
  const [items, setItems] = React.useState<AlertModel[]>([]);
  const [filtered, setFiltered] = React.useState<AlertModel[]>([]);
  const [page, setPage] = React.useState(1);

  const getMessage = (item: AlertModel) => {
    switch (item.sensor_name) {
      case 'indoor_temperature_status':
        return '故障予兆: 室内温度';
      case 'cooling_fan_vibration_status':
        return '故障予兆: 冷却ファン';
      case 'inverter_current_u_status':
        return '故障予兆: U電流';
      case 'inverter_current_v_status':
        return '故障予兆: V電流';
      case 'inverter_current_w_status':
        return '故障予兆: W電流';
      case 'emi_level_status':
        return '故障予兆: EMI';
      case 'logger_status':
        return 'AiPCSiシステム';
      default:
        return '';
    }
  };

  const onFilter = () => {
    let filtered = data;
    const filterText = methods.getValues().keyword;
    if (filterText !== '') {
      filtered = data.filter(
        (v) =>
          v.power_plant_name.includes(filterText) ||
          v.pcs_name.includes(filterText)
      );
    }
    setPage(1);
    setFiltered(filtered);
    setItems(filtered.slice(0, NUM_IN_PAGE));
  };

  const onChangePage = (page: number) => {
    setPage(page);

    if (!filtered) {
      setItems([]);
      return;
    }

    const start = NUM_IN_PAGE * (page - 1);
    const end = start + NUM_IN_PAGE;
    setItems(filtered.slice(start, end));
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.Alert.log();
        if (response.data) {
          setData(response.data);
          setItems(response.data.slice(NUM_IN_PAGE * (page - 1), NUM_IN_PAGE));
          setFiltered(response.data);
        }
      } catch (e) {
        console.log(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDeleted = false;
  return (
    <div className="w-full p-4 bg-re-F3F7FA">
      <Head title="アラート一覧" />
      <div className="mb-3 font-bold text-3xl text-re-063B67">アラート一覧</div>
      <div className="flex justify-center">
        <Card className="p-6">
          <div className="font-bold text-2xl text-re-063B67">アラート履歴</div>
          <div className="flex justify-center py-3 items-center">
            <FormProvider {...methods}>
              <TextField
                fieldName="keyword"
                width="w-full"
                placeholder="例： 発電所名 など"
              />
              <div className="pl-4">
                <Button text="絞り込む" size="sm" onClick={onFilter} />
              </div>
            </FormProvider>
          </div>
          {items.map((v, index) => {
            return (
              <MobileAlertCard
                key={index}
                className="mb-3"
                headerClass="bg-re-4D7FAF text-white font-bold"
                mainClass="truncate"
                mainTitle={v.pcs_name}
                subTitle={v.power_plant_name}
              >
                <div
                  className={`p-3 ${isDeleted ? 'bg-re-smoke text-re-gray' : ''
                    }`}
                >
                  <div className="gap-y-3 pb-3">
                    <div className="grid grid-cols-7 mb-5 items-center">
                      <div className="col-span-3 text-left font-medium text-sm">
                        アラート発信時間
                      </div>
                      <div className="col-span-4 text-right font-bold text-sm whitespace-nowrap">
                        {dayjs(v.captured_at).format('YYYY/MM/DD HH:mm:ss')}
                      </div>
                    </div>
                    <div className="mb-2 items-center">
                      <div className="text-left font-medium text-sm">
                        <div
                          className={`w-20 y-5 text-center text-white ${getAlertColor(
                            v.type
                          )} border-4`}
                        >
                          <span className="whitespace-nowrap font-bold">
                            {getAlertTypeStr(v.type)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mb-5 items-center">
                      <div className="text-left font-bold text-lg">
                        {getMessage(v)}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-5 items-center">
                      <div className="text-left font-medium text-sm">
                        ステータス
                      </div>
                      <div className="font-bold pl-2 text-right">
                        {v.is_canceled ? (
                          <span>解除済</span>
                        ) : (
                          <span className="text-re-danger">未解除</span>
                        )}
                      </div>
                    </div>
                    {/* <div className="items-center">
                      <div className="mb-1 text-left font-medium text-sm">
                        発電事業者
                      </div>
                      <div className="font-bold text-left text-lg">
                        {v.company_name}
                      </div>
                    </div> */}
                  </div>
                  <div className="flex justify-center">
                    <Button
                      text="アラート詳細"
                      size="md"
                      link={`/monitor/alert/detail/${v.id}`}
                    />
                  </div>
                </div>
              </MobileAlertCard>
            );
          })}
          <div>
            <MobilePagination
              current={page}
              count={Math.ceil((filtered?.length ?? 0) / NUM_IN_PAGE)}
              onChange={onChangePage}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default MobileMonitorAlertPage;
