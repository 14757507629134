import React from 'react';

type PROPS = {
  type?: string;
  headerClass?: string;
  mainTitle?: string;
  mainClass?: string;
  subTitle?: string;
  subClass?: string;
  width?: string;
  height?: string;
  className?: string;
  children: React.ReactNode;
};

const MobileAlertCard: React.FC<PROPS> = ({
  headerClass,
  mainTitle,
  subTitle,
  mainClass,
  subClass,
  width = 'w-full',
  height = 'h-full',
  className,
  children,
}: PROPS) => {
  const style = {
    container: `bg-white h-fit drop-shadow rounded-[10px]
      ${width} ${height}
    `,
    header: `flex items-center min-h-[38px] rounded-t-[10px] border-b ${headerClass}`,
    title: `items-center font-bold w-full h-full px-3 py-1`,
    subClass: `text-sm ${subClass} my-2`,
    mainClass: `text-xl ${mainClass}`,
  };
  return (
    <div className={`${style.container} ${className}`}>
      <div className={style.header}>
        <span className={style.title}>
          <p className={style.subClass}>{subTitle}</p>
          <div className="flex my-2">
            <div className="text-center border w-[45px] mr-2">PCS</div>
            <div className={style.mainClass}>{mainTitle}</div>
          </div>
        </span>
      </div>
      {children}
    </div>
  );
};

export default MobileAlertCard;
