import React from 'react';
import { Store } from 'store';
import { FormProvider, useForm } from 'react-hook-form';
import SelectBox from 'components/common/SelectBox';
import Dialog from 'components/common/Dialog';
import { BsCaretDownFill } from 'react-icons/bs';
import { MdOutlineClose } from 'react-icons/md';
import { UserRoleType } from 'enums';

type FormState = {
  company: string;
  powerPlant: string;
  map: string;
};

type Option = {
  label: string;
  value: string;
};

type PROPS = {
  user: Store.UserData | null;
  companyOptions: Array<Option>;
  powerPlantOptions: Array<Option>;
  mapOptions: Array<Option>;
  setCompanyId: React.Dispatch<React.SetStateAction<string>>;
  setPowerPlantId: React.Dispatch<React.SetStateAction<string>>;
  setMapId: React.Dispatch<React.SetStateAction<string>>;
};

const MapTopMenuMobile: React.FC<PROPS> = ({
  user,
  companyOptions,
  powerPlantOptions,
  mapOptions,
  setCompanyId,
  setPowerPlantId,
  setMapId,
}: PROPS) => {
  const [powerPlantLabel, setPowerPlantLabel] = React.useState('');
  const [isShow, setIsShow] = React.useState(false);
  const methods = useForm<FormState>({
    defaultValues: {
      company: '',
      powerPlant: '',
      map: '',
    },
  });
  const companyId = methods.watch('company');
  const powerPlantId = methods.watch('powerPlant');
  const mapId = methods.watch('map');

  React.useEffect(() => {
    setCompanyId(companyId);
    methods.setValue('powerPlant', '');
    methods.setValue('map', '');
  }, [companyId, setCompanyId, methods]);

  React.useEffect(() => {
    setPowerPlantId(powerPlantId);
    const item = powerPlantOptions
      .filter((v) => {
        return v.value === powerPlantId;
      })
      .shift();
    setPowerPlantLabel(item ? item.label : '');
    methods.setValue('map', '');
  }, [powerPlantId, powerPlantOptions, setPowerPlantId, methods]);

  React.useEffect(() => {
    setMapId(mapId);
    setIsShow(false);
  }, [mapId, setMapId]);

  return (
    <>
      <div className="flex items-center mt-4 mx-3 mb-1">
        <h1 className="font-bold text-3xl line-clamp-1 break-all text-re-063B67 mr-3">
          MAP・報告
        </h1>

        <button
          className="flex justify-between items-center bg-white text-re-gray
            h-[30px] w-full border border-re-gray rounded-[4px] px-2"
          onClick={() => setIsShow(true)}
        >
          <span className="line-clamp-1 break-all">
            {powerPlantId ? powerPlantLabel : '発電所を選択'}
          </span>
          <BsCaretDownFill />
        </button>
      </div>

      <Dialog show={isShow} width="w-[350px]" onClose={() => setIsShow(false)}>
        <div className="flex justify-end text-re-gray text-2xl mr-[-15px] mt-[-15px]">
          <MdOutlineClose onClick={() => setIsShow(false)} />
        </div>

        <p className="text-xl text-center text-re-primary font-bold mb-9">
          表示する発電所(MAP)を指定
        </p>

        <FormProvider {...methods}>
          <div className="flex flex-col gap-6">
            {user?.type === UserRoleType.MASTER && (
              <SelectBox<string>
                fieldName={'company'}
                blank={''}
                blankLabel="発電事業者を選択"
                options={companyOptions}
              />
            )}

            <SelectBox<string>
              fieldName="powerPlant"
              blank={''}
              blankLabel="発電所を選択"
              options={powerPlantOptions}
            />

            <SelectBox<string>
              fieldName="map"
              blank={''}
              blankLabel="Mapを選択"
              options={mapOptions}
            />
          </div>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default MapTopMenuMobile;
