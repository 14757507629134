import React from 'react';
import NavLink from '../NavLink';

type PROPS = {
  label: string;
  icon: React.ReactNode;
  path: string;
};

const MenuItem: React.FC<PROPS> = (props) => {
  React.useEffect(() => {}, []);

  return (
    <NavLink className="[&.active>button]:bg-re-primary-active" to={props.path}>
      <button
        className="w-full min-h-[50px] flex flex-col justify-center px-4 hover:bg-re-primary-active transition-all duration-100 disabled:pointer-events-none"
        disabled={!props.path}
      >
        <div className="flex items-center gap-2">
          <div className="w-[20px] h-[20px] fill-white">{props.icon}</div>
          <div className="text-lg">{props.label}</div>
        </div>
      </button>
    </NavLink>
  );
};

export default MenuItem;
