import ArrowIcon from 'assets/icons/arrow-r.svg';
import Link from './Link';

type PROPS = {
  items: {
    label: string;
    path?: string;
  }[];
};

const Breadcrumbs: React.FC<PROPS> = (props) => {
  const tmp: ({
    label: string;
    path?: string;
  } | null)[] = [{ label: 'HOME', path: '/' }];
  for (const item of props.items) {
    tmp.push(null);
    tmp.push(item);
  }

  return (
    <div className="flex items-center font-bold">
      {tmp.map((item, index) => {
        if (item === null) {
          return (
            <img key={index} src={ArrowIcon} alt="" className="h-3 mx-4" />
          );
        }
        const isLast = index === tmp.length - 1;
        if (item.path && !isLast) {
          return (
            <Link
              key={index}
              to={item.path}
              className="text-re-gray hover:text-re-063B67 hover:underline"
            >
              {item.label}
            </Link>
          );
        } else {
          return (
            <div key={index} className="text-re-063B67">
              {item.label}
            </div>
          );
        }
      })}
    </div>
  );
};

export default Breadcrumbs;
