export * as Alert from './alert';
export * from './validations';

export const sleep = (time_ms: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, time_ms);
  });
};

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  });
};
