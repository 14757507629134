import { AlertTreeModel } from 'models';

export const getMessage = (alert?: AlertTreeModel) => {
  if (!alert) {
    return '';
  }
  if (alert.type === 'trouble') {
    return `故障予兆: ${alert.sensor_name}`;
  } else if (alert.type === 'caution') {
    return `故障予兆: ${alert.sensor_name}`;
  } else {
    return `正常: ${alert.sensor_name}`;
  }
};

export const getSubMessage = (alert?: AlertTreeModel) => {
  if (!alert) {
    return '';
  }
  if (alert.is_canceled) {
    return '(アラート解除対応済)';
  }
  if (alert.type === 'trouble' || alert.type === 'caution') {
    return '閾値を上回っています';
  }
  return '';
};
