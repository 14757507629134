import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Head from 'components/common/Head';
import TextField from 'components/common/TextField';
import Pagination from 'components/common/pagination/Pagination';
import StatusBox from 'components/monitor/pcs/StatusBox';
import dayjs, { Dayjs } from 'dayjs';
import { OutletContextModel, PCSStatusModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import Utils from 'utils';

type FormState = {
  keyword: string;
};

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;

const MonitorPCSPage: React.FC = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const methods = useForm<FormState>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      keyword: '',
    },
  });

  const [timestamp, setTimestamp] = React.useState<Dayjs>();
  const [data, setData] = React.useState<PCSStatusModel[]>([]);
  const [filtered, setFiltered] = React.useState<PCSStatusModel[]>([]);
  const [items, setItems] = React.useState<PCSStatusModel[]>([]);
  const [page, setPage] = React.useState(1);

  const getStatus = (data: PCSStatusModel, sensorName: string) => {
    if (!data || !data.status) {
      return null;
    }
    const target = data.status.find((v) => v.sensor_name === sensorName);
    return target ? target.type : null;
  };

  const onFilter = () => {
    let filtered = data;
    const filterText = methods.getValues().keyword;
    if (filterText !== '') {
      filtered = data.filter(
        (v) =>
          v.power_plant_name.includes(filterText) ||
          v.pcs_name.includes(filterText)
      );
    }
    setPage(1);
    setFiltered(filtered);
    setItems(filtered.slice(0, NUM_IN_PAGE));
  };

  const onChangePage = (page: number) => {
    setPage(page);
    if (!filtered) {
      setItems([]);
      return;
    }
    const start = NUM_IN_PAGE * (page - 1);
    const end = start + NUM_IN_PAGE;
    setItems(filtered.slice(start, end));
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.PCS.latest();
        console.log(response);
        if (response.data) {
          response.data.forEach((v) => {
            const aipcsi = getStatus(v, 'logger_status');
            if (aipcsi === null) {
              // AiPCSiの状態情報が無い場合、その他の状態情報は無視する
              v.status = [];
            } else if (aipcsi === 'trouble') {
              // AiPCSiの状態が"異常"の場合は、その他の状態情報は無視する
              v.status = [{ sensor_name: 'logger_status', type: 'trouble' }];
            }
          });

          // "注意"or"異常"を含むデータを前にソート
          response.data = response.data.sort((a, b) => {
            const aa = a.status.some(
              (v) => v.type === 'trouble' || v.type === 'caution'
            );
            const bb = b.status.some(
              (v) => v.type === 'trouble' || v.type === 'caution'
            );
            if (!aa && bb) {
              return 1;
            } else if (aa && !bb) {
              return -1;
            } else return 0;
          });

          setData(response.data);
          setFiltered(response.data);
          setItems(response.data.slice(NUM_IN_PAGE * (page - 1), NUM_IN_PAGE));
          setTimestamp(dayjs());
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4 max-w-[calc(max(100vw,1200px)_-_15rem)]">
      <Head title="PCSの状態" />
      <Breadcrumbs items={[{ label: 'PCSの状態' }]} />
      <div className="font-bold text-3xl text-re-063B67">PCSの状態</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="flex items-center gap-10">
          <div className="flex-1">
            <span className="font-bold text-2xl text-re-063B67">PCS一覧</span>
            <span className="ml-8">
              {timestamp?.format('YYYY年MM月DD日 HH:mm 現在')}
            </span>
          </div>

          <div className="flex items-center gap-1">
            <FormProvider {...methods}>
              <TextField
                fieldName="keyword"
                width="w-[320px]"
                placeholder="例： 発電所名 や インバーター など"
              />
            </FormProvider>
            <Button text="絞り込み" size="sm" onClick={onFilter} />
          </div>
        </div>

        <div className="my-4 w-full border border-re-gray rounded-t-lg overflow-x-auto">
          <table className="min-w-full border-separate border-spacing-0">
            <thead>
              <tr className="bg-re-4D7FAF text-white [&>*]:p-2 [&>th]:whitespace-nowrap">
                <th className="bg-re-4D7FAF rounded-tl-lg sticky left-0">
                  <div className="flex gap-4">
                    <div className="w-60">発電所</div>
                    <div className="flex-1 p-2 text-center">PCSの名称</div>
                  </div>
                </th>
                <th className="text-center">
                  <div>AiPCSiシステム</div>
                  <div>正常/異常</div>
                </th>
                <th>
                  <div>冷却ファンの</div>
                  <div>故障予兆</div>
                </th>
                <th>
                  <div>U電流の</div>
                  <div>故障予兆</div>
                </th>
                <th>
                  <div>V電流の</div>
                  <div>故障予兆</div>
                </th>
                <th>
                  <div>W電流の</div>
                  <div>故障予兆</div>
                </th>
                <th>温度</th>
                <th className="rounded-tr-lg">データ詳細</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                const isDeleted = !!item.deleted_at;
                return (
                  <tr
                    key={index}
                    className={`group ${
                      isDeleted ? 'bg-re-smoke text-re-gray' : ''
                    } [&>*]:p-4`}
                  >
                    <td
                      className={`font-bold group-last:rounded-bl-lg sticky left-0 ${
                        isDeleted ? 'bg-re-smoke' : 'bg-white'
                      }`}
                    >
                      <div className="flex gap-4">
                        <div className="w-60">{item.power_plant_name}</div>
                        <div className="p-2 whitespace-nowrap">
                          {item.pcs_name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <StatusBox type={getStatus(item, 'logger_status')} />
                    </td>
                    <td>
                      <StatusBox
                        type={getStatus(item, 'cooling_fan_vibration_status')}
                      />
                    </td>
                    <td>
                      <StatusBox
                        type={getStatus(item, 'inverter_current_u_status')}
                      />
                    </td>
                    <td>
                      <StatusBox
                        type={getStatus(item, 'inverter_current_v_status')}
                      />
                    </td>
                    <td>
                      <StatusBox
                        type={getStatus(item, 'inverter_current_w_status')}
                      />
                    </td>
                    <td>
                      <StatusBox
                        type={getStatus(item, 'indoor_temperature_status')}
                      />
                    </td>
                    <td>
                      <Button
                        text="詳細"
                        size="sm"
                        link="/monitor/failure"
                        linkState={{
                          company_id: item.company_id,
                          power_plant_id: item.power_plant_id,
                          pcs_id: item.pcs_id,
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <Pagination
            current={page}
            count={Math.ceil((filtered?.length ?? 0) / NUM_IN_PAGE)}
            onChange={onChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default MonitorPCSPage;
