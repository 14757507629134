import { ReactComponent as AipcsiIcon } from 'assets/icons/menu/aipcsi.svg';
import { ReactComponent as AlertIcon } from 'assets/icons/menu/alert.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/menu/company.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/menu/home.svg';
import { ReactComponent as InformationIcon } from 'assets/icons/menu/information.svg';
import { ReactComponent as MapIcon } from 'assets/icons/menu/map.svg';
import { ReactComponent as MyPage } from 'assets/icons/menu/mypage.svg';
import { ReactComponent as PcsIcon } from 'assets/icons/menu/pcs.svg';
import { ReactComponent as PowerplantIcon } from 'assets/icons/menu/powerplant.svg';
import { ReactComponent as UserIcon } from 'assets/icons/menu/user.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { UserRoleType, getUserRoleTypeStr } from 'enums';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Store } from 'store';
import MenuItem from './MenuItem';

type PROPS = { isMobile?: boolean };

const Menu: React.FC<PROPS> = (props) => {
  const mobile = props.isMobile;

  const navigate = useNavigate();

  const onLogout = () => {
    navigate('/login');
  };

  React.useEffect(() => { }, []);

  const onChangePCMode = () => {
    navigate('/?pc');
  };

  const user = Store.loadUserDara();

  return (
    <div className="bg-re-063B67 w-60 flex flex-col text-white font-bold min-h-screen">
      {!mobile ? (
        <div className="w-full flex justify-center py-8">
          <Logo className="fill-white w-[200px]" />
        </div>
      ) : (
        <div className="h-12"></div>
      )}
      <div className="my-2 px-4 py-2 bg-re-32587D">
        <div className="text-re-smoke font-normal text-sm">
          {user?.company_name}
        </div>
        <div className="text-white flex justify-between items-center">
          {user?.type === UserRoleType.SUB ? (
            <span>{user?.contractor_name}</span>
          ) : (
            <span>
              {user?.last_name}
              {user?.first_name}
            </span>
          )}
          <span className="text-sm bg-re-4D7FAF p-1">
            {getUserRoleTypeStr(user?.type ?? '')}
          </span>
        </div>
      </div>
      <div className="my-2">
        <MenuItem label="HOME" icon={<HomeIcon />} path="/" />
      </div>

      <div className="mx-2 border border-re-4D7FAF"></div>

      <div className="my-2">
        <div className="text-sm text-white m-4">AiPCSi解析状況</div>
        <MenuItem label="アラート一覧" icon={<AlertIcon />} path="/monitor/alert" />
        <MenuItem label="PCS一覧" icon={<PcsIcon />} path="/monitor/pcs" />
        {!mobile && (
          <MenuItem
            label="PCS個別情報"
            icon={<AipcsiIcon />}
            path="/monitor/failure"
          />
        )}
        <MenuItem
          label="MAP・報告"
          icon={<MapIcon />}
          path="/monitor/map"
        />
      </div>

      <div className="mx-2 border border-re-4D7FAF"></div>

      <div className="my-2">
        <div className="text-sm text-white m-4">ユーザー情報</div>
        {!mobile && user?.type === 'master' && (
          <MenuItem
            label="発電事業者一覧"
            icon={<CompanyIcon />}
            path="/manage/company"
          />
        )}
        <MenuItem
          label="発電所一覧"
          icon={<PowerplantIcon />}
          path="/manage/power_plant"
        />
        {!mobile && user?.type !== 'sub' && (
          <MenuItem
            label="アカウント管理"
            icon={<UserIcon />}
            path="/manage/user"
          />
        )}
        {!mobile && user?.type === 'master' && (
          <MenuItem
            label="お知らせ管理"
            icon={<InformationIcon />}
            path="/manage/information"
          />
        )}
      </div>

      <div className="mx-2 border border-re-4D7FAF"></div>

      {!mobile && (
        <div className="my-2">
          {/* <div className="text-sm text-white m-4">&nbsp;</div> */}
          <MenuItem
            label="マイページ"
            icon={<MyPage />}
            path="/setting/mypage"
          />
        </div>
      )}

      {mobile && (
        <div className="my-12 flex justify-center">
          <div
            className="border border-white rounded-full px-4"
            onClick={onChangePCMode}
          >
            <span className="text-sm">PC表示に切り替え</span>
          </div>
        </div>
      )}

      <div className="flex-1 flex flex-col justify-end">
        <button
          className="w-full mb-2 p-4 text-center hover:bg-re-primary-active transition-all duration-100"
          onClick={onLogout}
        >
          <span>ログアウト</span>
        </button>
      </div>
    </div>
  );
};

export default Menu;
