import axios from 'axios';
import {
  PowerPlantListModel,
  PowerPlantUploadModel,
  PowerPlantModel,
} from 'models';

export async function create(data: PowerPlantModel) {
  const response = await axios.post<PowerPlantModel>('/power_plant', data);
  return response;
}

export async function list() {
  const response = await axios.get<PowerPlantListModel[]>('/power_plant');
  return response;
}

export async function listInCompany(company_id: string, all?: boolean) {
  const response = await axios.get<PowerPlantModel[]>(
    `/power_plant/company/${company_id}${all ? '?all' : ''}`
  );
  return response;
}

export async function listForMe() {
  const response = await axios.get<PowerPlantModel[]>(`/power_plant/me`);
  return response;
}

export async function get(id: string | undefined) {
  const response = await axios.get<PowerPlantModel>(`/power_plant/${id}`);
  return response;
}

export async function getMapList(id: string) {
  const response = await axios.get<Array<{ id: string; label: string; }>>(
    `/power_plant/${id}/map`
  );
  return response;
}

export async function update(data: PowerPlantModel) {
  const response = await axios.put(`/power_plant/${data.id}`, data);
  return response;
}

export async function updateMap(id: string, map_id: string, data: FormData) {
  const response = await axios.put(`/power_plant/${id}/map/${map_id}`, data);
  return response;
}

export async function del(id: string) {
  const response = await axios.delete(`/power_plant/${id}`);
  return response;
}

export async function addEmail(power_plant_id: string, email: string) {
  const response = await axios.post<PowerPlantModel>(`/power_plant/email`, {
    power_plant_id,
    email,
  });
  return response;
}

export async function deleteEmail(power_plant_id: string, email_id: string) {
  const response = await axios.delete<PowerPlantModel>(`/power_plant/email`, {
    data: { power_plant_id, email_id },
  });
  return response;
}

export async function csvDownload(power_plant_id: string) {
  const response = await axios.get(`/power_plant/${power_plant_id}/csv`, {
    responseType: 'blob',
  });
  return response;
}

export async function upload(power_plant_id: string, data: FormData) {
  const response = await axios.post<PowerPlantUploadModel>(
    `/power_plant/${power_plant_id}/map`,
    data
  );
  return response;
}
