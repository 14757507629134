import Button from 'components/common/Button';
import { getUserRoleTypeStr, UserRoleType } from 'enums';
import { PowerPlantModel, UserModel } from 'models';
import React from 'react';

type PROPS = {
  data?: UserModel;
  editPagePath: string | null;
  powerPlantList: PowerPlantModel[];
  isDeleted?: boolean;
};

const UserDetailPanel1: React.FC<PROPS> = (props) => {
  const data = props.data;

  const [powerPlantList, setPowerPlantList] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!props.data || !props.powerPlantList) {
      setPowerPlantList([]);
    } else {
      const power_plants = props.data.power_plants ?? [];
      const tmp = power_plants.map((v) => {
        const _ = props.powerPlantList.find(
          (powerPlant) => powerPlant.id === v
        );
        return _ ? _.name : '';
      });
      setPowerPlantList(tmp);
    }
  }, [props.data, props.powerPlantList]);

  return (
    <div className="drop-shadow rounded bg-white p-6 flex flex-col gap-7">
      <div className="flex justify-between">
        <div className="font-bold text-2xl text-re-063B67">アカウント情報</div>
        {props.editPagePath && !props.isDeleted ? (
          <Button text="編集" size="sm" outlined link={props.editPagePath} />
        ) : (
          <></>
        )}
      </div>

      <div className="flex flex-col gap-8">
        <div className="font-bold text-re-063B67 text-xl">アカウント</div>
        {data?.type === UserRoleType.SUB ? (
          <div className="flex">
            <div className="flex-1 font-bold">企業名</div>
            <div className="flex-[2]">{data?.contractor_name}</div>
          </div>
        ) : (
          <div className="flex">
            <div className="flex-1 font-bold">ユーザー名</div>
            <div className="flex-[2]">
              {data?.last_name}
              {data?.first_name}
            </div>
          </div>
        )}
        <div className="flex">
          <div className="flex-1 font-bold">ユーザーID</div>
          <div className="flex-[2]">
            {data?.login_id}@{data?.domain}
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 font-bold">権限</div>
          <div className="flex-[2]">{getUserRoleTypeStr(data?.type!)}</div>
        </div>
        <div className="flex">
          <div className="flex-1 font-bold">発電事業者名</div>
          <div className="flex-[2]">{data?.company_name}</div>
        </div>
        {(data?.type === UserRoleType.ADMIN ||
          data?.type === UserRoleType.MAIN ||
          data?.type === UserRoleType.SUB) && (
            <>
              <div className="font-bold text-re-063B67 text-xl">所属情報</div>
              {data.type === UserRoleType.SUB ? (
                <>
                  <div className="flex">
                    <div className="flex-1 font-bold">担当者名</div>
                    <div className="flex-[2]">
                      {data?.last_name}
                      {data.first_name}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex-1 font-bold">メールアドレス</div>
                    <div className="flex-[2]">{data?.email}</div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="flex">
                <div className="flex-1 font-bold">所属・部署</div>
                <div className="flex-[2]">{data?.department}</div>
              </div>
              <div className="flex">
                <div className="flex-1 font-bold">住所</div>
                <div className="flex-[2]">{data?.address}</div>
              </div>
              <div className="flex">
                <div className="flex-1 font-bold">電話番号</div>
                <div className="flex-[2]">{data?.tel}</div>
              </div>
              {data.type !== UserRoleType.SUB ? (
                <div className="flex">
                  <div className="flex-1 font-bold">メールアドレス</div>
                  <div className="flex-[2]">{data?.email}</div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        {(data?.type === UserRoleType.MAIN ||
          data?.type === UserRoleType.SUB) &&
          powerPlantList.length > 0 && (
            <>
              <div className="font-bold text-re-063B67 text-xl">
                アクセスできる発電所
              </div>
              {powerPlantList.map((v, index) => {
                return (
                  <div key={index} className="flex">
                    <div className="flex-1 font-bold">{v}</div>
                    <div className="flex-[2]">{v}</div>
                  </div>
                );
              })}
            </>
          )}
      </div>
    </div>
  );
};

export default UserDetailPanel1;
