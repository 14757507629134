import Card from 'components/common/Card';
import dayjs from 'dayjs';
import { getAlertColor, getAlertTypeStr } from 'enums';
import { AlertTreeAPIModel, AlertTreeModel } from 'models';
import React from 'react';
import Utils from 'utils';

type PROPS = {
  data?: AlertTreeAPIModel;
  setShowAlertList: React.Dispatch<React.SetStateAction<boolean>>;
};

const AlertTransitionCard: React.FC<PROPS> = ({
  data,
  setShowAlertList,
}: PROPS) => {
  // 最新のアラート
  const [last3, setLast3] = React.useState<AlertTreeModel[]>([]);
  // 初回アラート
  const [firstAlert, setFirstAlert] = React.useState<AlertTreeModel>();

  React.useEffect(() => {
    setFirstAlert(undefined);
    if (data) {
      setLast3(data.alert_tree.slice(0, 3));
      if (data.alert_tree.length > 0 && data.alert_tree[0].first_alert_id) {
        const first_alert_id = data.alert_tree[0].first_alert_id;
        const target = data.alert_tree.find((v) => v.id === first_alert_id);
        if (target) {
          setFirstAlert(target);
        }
      }
    } else {
      setLast3([]);
    }
  }, [data]);

  return (
    <Card className="p-6 mb-3">
      <div className="font-bold text-2xl text-re-063B67">アラート 推移</div>

      <div className="font-bold text-lg text-re-063B67 py-5">アラートログ</div>
      <div className="flex flex-col gap-2">
        {last3.map((alert, index) => {
          return (
            <div key={index} className="flex items-center">
              <div className="flex-1">
                {dayjs(alert.captured_at).format('YYYY/MM/DD HH:mm:ss')}
              </div>
              <div className="flex flex-[2] items-center">
                <div
                  className={`px-6 p-1 font-bold
            ${getAlertColor(alert.type)} border-4`}
                >
                  {getAlertTypeStr(alert.type)}
                </div>
                <p className="ml-3">{Utils.Alert.getMessage(alert)}</p>
                <p className="ml-12">{Utils.Alert.getSubMessage(alert)}</p>
              </div>
            </div>
          );
        })}
      </div>
      <p
        className="mt-2 text-sm underline text-re-primary cursor-pointer"
        onClick={() => setShowAlertList(true)}
      >
        もっと見る
      </p>

      {firstAlert && (
        <>
          <div className="font-bold text-lg text-re-063B67 py-5">初回アラート</div>
          <div className="flex items-center">
            <div className="flex-1">
              {dayjs(firstAlert.captured_at).format('YYYY/MM/DD HH:mm:ss')}
            </div>
            <div className="flex flex-[2] items-center">
              <div
                className={`px-6 p-1 font-bold
            ${getAlertColor(firstAlert.type)} border-4`}
              >
                {getAlertTypeStr(firstAlert.type)}
              </div>
              <p className="ml-3">{Utils.Alert.getMessage(firstAlert)}</p>
              <p className="ml-12">{Utils.Alert.getSubMessage(firstAlert)}</p>
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

export default AlertTransitionCard;
