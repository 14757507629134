import axios from 'axios';
import { FailureModel, PCSModel, PCSStatusModel } from 'models';

export async function create(data: PCSModel) {
  const response = await axios.post<PCSModel>('/pcs', data);
  return response;
}

export async function list(aipcsi_id: number | string | undefined) {
  const response = await axios.get<PCSModel[]>(`/pcs/aipcsi/${aipcsi_id}`);
  return response;
}

export async function get(id: string | undefined) {
  const response = await axios.get<PCSModel>(`/pcs/${id}`);
  return response;
}

export async function update(data: PCSModel) {
  const response = await axios.put(`/pcs/${data.id}`, data);
  return response;
}

export async function del(id: string) {
  const response = await axios.delete(`/pcs/${id}`);
  return response;
}

export async function monitorData(id: string, date?: string) {
  const params: { date?: string } = {};
  if (date) {
    params.date = date;
  }
  const response = await axios.get<FailureModel>(`/pcs/${id}/monitor`, {
    params,
  });
  return response;
}

export async function latest() {
  const response = await axios.get<PCSStatusModel[]>(`/pcs/latest`);
  return response;
}

export async function status(id: string) {
  const response = await axios.get<
    {
      sensor_name: string;
      type: string;
    }[]
  >(`/pcs/${id}/status`);
  return response;
}
