import { AiPCSiModel, PCSModel } from 'models';
import React from 'react';
import PlantDetailCard from 'components/manage/power_plant/PlantDetailCard';

type PROPS = {
  data?: AiPCSiModel;
  pcs?: PCSModel[];
};

const MobilePowerPlantAiPCSiPanelItem: React.FC<PROPS> = (props) => {
  const name = props.data?.name ?? 'ー';
  const isDeleted = props.data && props.data.deleted_at;

  const [pcsData, setPCSData] = React.useState<PCSModel[]>([]);

  React.useEffect(() => {
    if (props.pcs && props.pcs.length > 0) {
      setPCSData(props.pcs);
    } else {
      setPCSData([
        {
          aipcsi_id: '0',
          name: 'ー',
          maker: 'ー',
          model: 'ー',
          thresholds: [],
          deleted_at: null,
        },
      ]);
    }
  }, [props.pcs]);

  return (
    <div>
      <PlantDetailCard
        key="1"
        className="mb-3"
        headerClass={`text-white font-bold ${
          isDeleted ? 'bg-re-gray' : 'bg-re-4D7FAF'
        }`}
        mainClass="truncate"
        mainTitle={name}
        subTitle="AiPCSi"
      >
        {pcsData.map((v, i) => {
          const isDeleted = v.deleted_at;
          const isLast = i === pcsData.length - 1;
          return (
            <div
              className={`p-3 border-t 
            ${isDeleted && 'bg-re-smoke text-re-gray'} ${
                isDeleted && isLast && 'rounded-b-[10px]'
              }`}
            >
              <div className="grid grid-cols-2 mb-8 items-center">
                <div className="font-medium text-sm">PCS名称</div>
                <div className="font-bold text-lg text-right">{v.name}</div>
              </div>
              <div className="grid grid-cols-2 mb-8 items-center">
                <div className="font-medium text-sm">メーカー名</div>
                <div className="font-bold text-lg text-right">{v.maker}</div>
              </div>
              <div className="grid grid-cols-2 mb-8 items-center">
                <div className="font-medium text-sm">PCS型式</div>
                <div className="font-bold text-lg text-right">{v.model}</div>
              </div>
              <div className="mb-2">
                <div className="font-medium text-sm">センサー閾値</div>
                <div className="font-bold text-lg">
                  {v.thresholds.length === 0 ? (
                    <div>温度ー、振動ー、U電流ー、W電流ー、EMIー</div>
                  ) : (
                    <div>
                      {v.thresholds.map((threshold, i) => {
                        return (
                          <span key={i}>
                            {threshold.name}({threshold.caution}、
                            {threshold.trouble})
                            {i < v.thresholds.length - 1 && <>、</>}
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </PlantDetailCard>
    </div>
  );
};

export default MobilePowerPlantAiPCSiPanelItem;
