import Button from 'components/common/Button';
import Card from 'components/common/Card';
import CheckBox from 'components/common/CheckBox';
import Switch from 'components/common/Switch';
import Chart from 'components/monitor/failure/Chart';
import dayjs from 'dayjs';
import { FailureModel } from 'models';
import React from 'react';
import { AiOutlineLineChart, AiOutlineTable } from 'react-icons/ai';
import { RxCaretLeft, RxCaretRight } from 'react-icons/rx';
import DatePicker from './DatePicker';
import Table from './Table';

type PROPS = {
  data?: FailureModel | null;
  date?: string;
  onChangeDate: (date: string) => void;
};

const GraphArea: React.FC<PROPS> = (props) => {
  const [isAIDiagnosis, setDiagnosis] = React.useState(true);
  const [checkItems, setCheckItems] = React.useState<Array<string>>([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
  ]);
  const [isChartView, setIsChartview] = React.useState(true);

  const [date, setDate] = React.useState(dayjs());

  const onYesterday = () => {
    const tmp = date.add(-1, 'day');
    props.onChangeDate(tmp.format('YYYY-MM-DD'));
  };
  const onTomorrow = () => {
    const tmp = date.add(1, 'day');
    props.onChangeDate(tmp.format('YYYY-MM-DD'));
  };
  const onToday = () => {
    const tmp = dayjs();
    props.onChangeDate(tmp.format('YYYY-MM-DD'));
  };
  const onChangeDate = (date: Date | null) => {
    console.log('aaa', date);
    if (date) {
      props.onChangeDate(dayjs(date).format('YYYY-MM-DD'));
    } else {
      // props.onChangeDate(tmp.format('YYYY-MM-DD'));
    }
  };

  React.useEffect(() => {
    if (props.data) {
      setDate(dayjs(props.data.date));
    } else if(props.date) {
      setDate(dayjs(props.date));
    } else {
      setDate(dayjs());
    }
  }, [props.data, props.date]);

  return (
    <Card className="p-6 mt-9">
      <div className="flex justify-between">
        <div className="font-bold text-2xl text-re-063B67">
          AiPCSi解析グラフ
        </div>
        <div className="flex">
          <Switch
            value={isAIDiagnosis}
            width="w-[360px]"
            leftLabel="AI診断"
            rightLabel="センサーデータ"
            setValue={setDiagnosis}
          />
        </div>
      </div>
      <div className="flex my-4">
        <div className="flex flex-1 items-center">
          <Button
            text="最新"
            outlined
            size="sm"
            onClick={onToday}
            disabled={!props.data}
          />

          <div className="flex ml-4 items-center">
            <button
              className="disabled:text-gray-400"
              onClick={onYesterday}
              disabled={!props.data}
            >
              <RxCaretLeft className="text-xl" />
            </button>
            <DatePicker
              value={date}
              onChange={onChangeDate}
              disabled={!props.data}
            />
            <button
              className="disabled:text-gray-400"
              onClick={onTomorrow}
              disabled={!props.data || date.isSame(dayjs(), 'day')}
            >
              <RxCaretRight className="text-xl" />
            </button>
          </div>
        </div>

        <div className="flex flex-2 items-center gap-6">
          {isChartView && (
            <>
              <CheckBox
                id="1"
                value={checkItems}
                label="室内温度"
                checked={checkItems.includes('1')}
                setValue={setCheckItems}
                className="accent-[#1C8EC3]"
              />
              <CheckBox
                id="2"
                value={checkItems}
                label="振動"
                checked={checkItems.includes('2')}
                setValue={setCheckItems}
                className="accent-[#E02522]"
              />
              <CheckBox
                id="3"
                value={checkItems}
                label="U電流"
                checked={checkItems.includes('3')}
                setValue={setCheckItems}
                className="accent-[#60987E]"
              />
              <CheckBox
                id="4"
                value={checkItems}
                label="V電流"
                checked={checkItems.includes('4')}
                setValue={setCheckItems}
                className="accent-[#E34E23]"
              />
              <CheckBox
                id="5"
                value={checkItems}
                label="W電流"
                checked={checkItems.includes('5')}
                setValue={setCheckItems}
                className="accent-[#8494A7]"
              />
              <CheckBox
                id="6"
                value={checkItems}
                label="EMI"
                checked={checkItems.includes('6')}
                setValue={setCheckItems}
                className="accent-[#B08900]"
              />
            </>
          )}
          <Switch
            value={isChartView}
            leftLabel={<AiOutlineLineChart />}
            rightLabel={<AiOutlineTable />}
            width="w-[80px]"
            height="h-[35px]"
            setValue={setIsChartview}
          />
        </div>
      </div>

      {isChartView ? (
        <Chart
          isAIDiagnosis={isAIDiagnosis}
          display={checkItems}
          data={
            isAIDiagnosis ? props.data?.ai_diagnosis : props.data?.sensor_data
          }
        />
      ) : (
        <Table
          isAIDiagnosis={isAIDiagnosis}
          date={props.data?.date}
          data={
            isAIDiagnosis ? props.data?.ai_diagnosis : props.data?.sensor_data
          }
        />
      )}
    </Card>
  );
};

export default GraphArea;
