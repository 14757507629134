import FormLabel from 'components/common/FormLabel';
import TextField from 'components/common/TextField';
import { CompanyModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {};

const CompanyEditFormBlock1: React.FC<PROPS> = (props) => {
  const { formState, getValues } = useFormContext<CompanyModel>();

  return (
    <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
      <div className="font-bold text-xl text-re-063B67">法人 情報</div>

      <div className="flex gap-2">
        <FormLabel text="法人名" required />
        <div className="flex-1">
          <TextField
            fieldName="company_name"
            placeholder="例：◯◯◯株式会社"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.company_name?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="法人代表者名" required={false} />
        <div className="flex-1 flex gap-4">
          <TextField
            fieldName="president_last_name"
            placeholder="苗字"
            validations={{}}
            errorMessage={formState.errors.president_last_name?.message}
          />
          <TextField
            fieldName="president_first_name"
            placeholder="名前"
            validations={{}}
            errorMessage={formState.errors.president_first_name?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="法人住所" required />
        <div className="flex-1">
          <TextField
            fieldName="company_address"
            placeholder="例：◯◯県◯◯市◯◯"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.company_address?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="法人電話番号" required />
        <div className="flex-1">
          <TextField
            fieldName="company_tel"
            placeholder="0000-0000-0000"
            validations={Utils.telValidation(true)}
            errorMessage={formState.errors.company_tel?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel
          text="発電事業者所属ユーザーの＠以降の共通ID"
          required
          subtext="※今後変更はできません"
        />
        <div className="flex-1">
          {!!getValues().id ? (
            <span>@{getValues().domain}</span>
          ) : (
            <div className="flex">
              <span className="mt-3">@</span>
              <TextField
                fieldName="domain"
                placeholder="半角英数"
                validations={Utils.domainValidation()}
                errorMessage={formState.errors.domain?.message}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyEditFormBlock1;
