import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Dialog from 'components/common/Dialog';
import FileInput from 'components/common/FileInput';
import Head from 'components/common/Head';
import SelectBox from 'components/common/SelectBox';
import TextField from 'components/common/TextField';
import { UserRoleType } from 'enums';
import { OutletContextModel } from 'models';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Store } from 'store';
import Utils from 'utils';

type FormState = {
  companyId: string;
  powerPlantId: string;
  mapId: string;
  mapName: string;
  file: FileList | undefined;
};

const MonitorMapSettingPage = () => {
  const navigate = useNavigate();
  const state: { companyId: string; powerPlantId: string; mapId: string } =
    useLocation().state;
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);
  const user = Store.loadUserDara();
  const methods = useForm<FormState>({
    defaultValues: {
      companyId: '',
      powerPlantId: '',
      mapId: '',
      mapName: '',
      file: undefined,
    },
  });
  const [showDialog, setShowDialog] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [companyOptions, setCompanyOptions] = React.useState<
    Array<{ label: string; value: string }>
  >([]); // 発電事業者リスト
  const [powerPlantOptions, setPowerPlantOptions] = React.useState<
    Array<{ label: string; value: string }>
  >([]); // 発電所リスト
  const [mapListOptions, setMapListOptions] = React.useState<
    Array<{ label: string; value: string }>
  >([]); // Mapリスト
  const [mapImage, setMapImage] = React.useState('');

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  /**
   * 発電事業者リストの設定
   */
  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        if (UserRoleType.MASTER === user?.type) {
          const _company = await API.Company.list();
          if (_company.data) {
            setCompanyOptions(
              _company.data.map((v) => {
                return { label: v.company_name, value: v.id || '' };
              })
            );
          }

          /**
           * /mapで選択した場合にoptionをセット
           */
          if (state.companyId) {
            methods.setValue('companyId', state.companyId);
            const _powerPlant = await API.PowerPlant.listInCompany(
              state.companyId
            );
            if (_powerPlant.data) {
              setPowerPlantOptions(
                _powerPlant.data.map((v) => {
                  return { label: v.name, value: v.id || '' };
                })
              );
            }
          }
        } else {
          methods.setValue('companyId', user ? user.company_id : '');
        }

        if (state.powerPlantId) {
          methods.setValue('powerPlantId', state.powerPlantId);
          const _mapList = await API.PowerPlant.getMapList(state.powerPlantId);
          if (_mapList.data) {
            setMapListOptions(
              _mapList.data.map((v, i) => {
                return { label: `${i + 1}枚目`, value: v.id || '' };
              })
            );
            setMapListOptions((prev) => [
              ...prev,
              {
                label: `${_mapList.data.length + 1}枚目(新規)`,
                value: '0',
              },
            ]);
          }
        }

        if (state.mapId) {
          methods.setValue('mapId', state.mapId);
          const _map = await API.Map.getMap(state.mapId);

          if (_map.data) {
            setMapImage(_map.data.url);
          }
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 発電事業者を変更した場合
   */
  const companyId = methods.watch('companyId');
  React.useEffect(() => {
    (async () => {
      setPowerPlantOptions([]);
      setMapListOptions([]);
      methods.setValue('powerPlantId', '');
      methods.setValue('mapId', '');

      try {
        if (companyId) {
          const _powerPlant = await API.PowerPlant.listInCompany(companyId);
          if (_powerPlant.data) {
            setPowerPlantOptions(
              _powerPlant.data.map((v) => {
                return { label: v.name, value: v.id || '' };
              })
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [companyId, methods]);

  /**
   * 発電所を変更した場合
   */
  const powerPlantId = methods.watch('powerPlantId');
  React.useEffect(() => {
    (async () => {
      try {
        setMapListOptions([]);
        methods.setValue('mapId', '');

        if (powerPlantId) {
          getMapPageList(powerPlantId);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [powerPlantId, methods]);

  /**
   * Mapを変更した場合
   */
  const mapId = methods.watch('mapId');
  React.useEffect(() => {
    (async () => {
      try {
        methods.setValue('file', undefined);
        if (mapId === '0' || !mapId) {
          setMapImage('');
          methods.setValue('mapName', '');
        } else if (mapId) {
          const _map = await API.Map.getMap(mapId);
          if (_map.data) {
            setMapImage(_map.data.url);
          }

          methods.setValue('mapName', _map.data.label);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [mapId, methods]);

  React.useEffect(() => {
    if (isSubmit && mapListOptions.length > 0 && mapId !== '') {
      const index =
        mapListOptions.length - 2 < 0 ? 0 : mapListOptions.length - 2;
      const currentId =
        Number(mapId) === 0 ? mapListOptions[index].value : Number(mapId);
      methods.setValue('mapId', String(currentId));

      setIsSubmit(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapId, mapListOptions]);

  /**
   * Fileを変更した場合
   */
  const file: FileList | undefined = methods.watch('file');
  React.useEffect(() => {
    if (file && file.length > 0) {
      setMapImage(window.URL.createObjectURL(file[0]));
    }
  }, [file]);

  const getMapPageList = async (id: string) => {
    const _mapList = await API.PowerPlant.getMapList(id);

    if (_mapList.data) {
      const data = _mapList.data.sort((a, b) => Number(a.id) - Number(b.id));
      setMapListOptions(
        data.map((v, i) => {
          return { label: `${i + 1}枚目`, value: v.id || '' };
        })
      );
      setMapListOptions((prev) => [
        ...prev,
        {
          label: `${_mapList.data.length + 1}枚目(新規)`,
          value: '0',
        },
      ]);
    }
  };

  /**
   * Map設定の登録
   */
  const handleSetMap: SubmitHandler<FormState> = async (data) => {
    const formData = new FormData();

    formData.append('label', data.mapName);
    data.file && formData.append('file', data.file[0]);

    try {
      if (data.mapId === '0') {
        /* Mapの新規登録*/

        if (data.file) {
          await API.PowerPlant.upload(data.powerPlantId, formData).then(
            (res) => {
              console.log(res);
            }
          );
        } else {
          methods.setError('file', {
            type: 'required',
            message: '必須項目です',
          });

          return;
        }
      } else if (data.mapId) {
        /* Mapの編集 */

        await API.PowerPlant.updateMap(data.powerPlantId, mapId, formData).then(
          (res) => {
            console.log(res);
          }
        );
      }

      getMapPageList(powerPlantId);
      setIsSubmit(true);
      setShowDialog(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="w-full h-full bg-re-F3F7FA px-8 py-2 pb-20 flex flex-col gap-4">
      <Head title="MAP設定" />
      <Breadcrumbs
        items={[
          { label: 'MAP・報告', path: '/monitor/map' },
          { label: 'MAP 設定' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">MAP 設定</div>
      <Card className="p-6">
        <div className="font-bold text-2xl text-re-063B67">MAP 画像登録</div>

        <div className="w-full flex items-center justify-center my-3 ">
          {mapImage ? (
            <img
              src={mapImage}
              alt="map_image"
              className="w-full max-h-[800px] object-contain"
            />
          ) : (
            <p className="flex items-center justify-center w-full h-[800px] bg-re-smoke object-contain">
              プレビュー
            </p>
          )}
        </div>

        <div className="flex justify-center my-3">
          <div className="flex flex-col  w-full max-w-[600px]">
            <div className="font-bold text-2xl text-re-063B67">
              MAP画像のアップロード
            </div>

            <div className="font-bold text-lg text-re-063B67 mt-6">
              発電所を選択
            </div>

            <FormProvider {...methods}>
              {user?.type === UserRoleType.MASTER && (
                <div className="flex items-center my-3">
                  <label className="w-[200px] text-sm">
                    発電事業者 <span className="text-re-danger">必須</span>
                  </label>

                  <SelectBox
                    fieldName="companyId"
                    blank={''}
                    blankLabel="発電事業者を選択"
                    options={companyOptions}
                    width="w-[400px]"
                    validations={Utils.requiredValidation()}
                    errorMessage={methods.formState.errors.companyId?.message}
                  />
                </div>
              )}

              <div className="flex items-center my-3">
                <label className="w-[200px] text-sm">
                  発電所 <span className="text-re-danger">必須</span>
                </label>

                <SelectBox
                  fieldName="powerPlantId"
                  blank={''}
                  blankLabel="発電所を選択"
                  options={powerPlantOptions}
                  width="w-[400px]"
                  validations={Utils.requiredValidation()}
                  errorMessage={methods.formState.errors.powerPlantId?.message}
                />
              </div>

              <div className="flex items-center my-3">
                <label className="flex w-[200px] text-sm">
                  アップロードする<br></br>MAP画像のページ{' '}
                  <span className="ml-2 text-re-danger">必須</span>
                </label>
                <SelectBox
                  fieldName="mapId"
                  blank={''}
                  blankLabel="MAP画像を選択"
                  options={mapListOptions}
                  width="w-[400px]"
                  validations={Utils.requiredValidation()}
                  errorMessage={methods.formState.errors.mapId?.message}
                />
              </div>

              <div className="flex items-center my-3">
                <label className="flex w-[200px] text-sm">
                  MAP画像名
                  <span className="ml-2 text-re-danger">必須</span>
                </label>

                <div className="w-[400px]">
                  <TextField
                    fieldName="mapName"
                    placeholder="例 : 平面図-北エリア"
                    validations={Utils.requiredValidation()}
                    errorMessage={methods.formState.errors.mapName?.message}
                  />
                </div>
              </div>

              <div className="flex flex-col items-center my-3">
                <FileInput
                  fieldName="file"
                  text="MAP画像のアップロード"
                  errorMessage={methods.formState.errors.file?.message}
                />
              </div>

              <div className="flex justify-center gap-10 my-6">
                <Button
                  text="戻る"
                  color="gray"
                  outlined
                  onClick={() => {
                    navigate('/monitor/map', {
                      state: {
                        companyId: companyId,
                        powerPlantId: powerPlantId,
                        mapId: mapId,
                      },
                    });
                  }}
                />
                <Button
                  text="登録する"
                  onClick={methods.handleSubmit(handleSetMap)}
                />
              </div>
            </FormProvider>
          </div>
        </div>
      </Card>

      <Dialog
        show={showDialog}
        onConfirm={() => {
          setShowDialog(false);
        }}
      >
        登録しました
      </Dialog>
    </div>
  );
};

export default MonitorMapSettingPage;
