import API from 'api';
import Button from 'components/common/Button';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import MobilePagination from 'components/common/pagination/MobilePagination';
import MobileAlertDetailCard from 'components/monitor/alert/MobileAlertDetailCard';
import MobileAlertTransitionCard from 'components/monitor/alert/MobileAlertTransitionCard';
import MobileBasicInformationCard from 'components/monitor/alert/MobileBasicInformationCard';
import dayjs from 'dayjs';
import { AlertTreeAPIModel, AlertTreeModel, OutletContextModel } from 'models';
import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';
import { getAlertColor, getAlertTypeStr } from '../../enums';

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;
const MobileMonitorAlertPage: React.FC = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const { id } = useParams();
  const [data, setData] = React.useState<AlertTreeAPIModel>();
  const [historyData, setHistoryData] = React.useState<AlertTreeModel[]>();
  const [historyDataPage, setHistoryDataPage] = React.useState(1);

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.Alert.tree(id!);
        console.log(response);
        if (response.data) {
          setData(response.data);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full p-4 bg-re-F3F7FA">
      <Head title="アラート詳細" />
      <div className="mb-3 font-bold text-3xl text-re-063B67">アラート詳細</div>
      <MobileAlertDetailCard data={data} />
      <MobileAlertTransitionCard
        data={data}
        setShowAlertList={() => {
          setHistoryDataPage(1);
          setHistoryData(data?.alert_tree.slice(0, NUM_IN_PAGE));
        }}
      />
      <MobileBasicInformationCard data={data} />
      {/* アラートログ一覧 */}
      <Dialog
        show={!!historyData}
        width="w-11/12"
        onClose={() => setHistoryData(undefined)}
      >
        <div className="font-bold text-2xl text-re-063B67 mb-6">アラート推移</div>

        {historyData?.map((alert, index) => {
          return (
            <div key={index} className="mb-6">
              <div className="font-medium text-sm mb-2">
                {dayjs(alert.captured_at).format('YYYY/MM/DD HH:mm:ss')}
              </div>
              <div className="font-medium text-sm mb-2 w-28 text-center">
                <div
                  className={`px-6 p-1 font-bold
            ${getAlertColor(alert.type)} border-4`}
                >
                  {getAlertTypeStr(alert.type)}
                </div>
              </div>
              <div className="font-bold text-lg mb-2">
                {Utils.Alert.getMessage(alert)}
              </div>
              <div className="font-medium text-sm">
                {Utils.Alert.getSubMessage(alert)}
              </div>
            </div>
          );
        })}
        {historyData && (
          <div className="mt-6">
            <MobilePagination
              current={historyDataPage}
              count={Math.ceil((data?.alert_tree.length ?? 0) / NUM_IN_PAGE)}
              onChange={(page: number) => {
                setHistoryDataPage(page);

                const start = NUM_IN_PAGE * (page - 1);
                const end = start + NUM_IN_PAGE;
                setHistoryData(data?.alert_tree.slice(start, end));
              }}
            />
          </div>
        )}

        <div className="flex justify-center mt-9">
          <Button
            text="閉じる"
            size="sm"
            onClick={() => setHistoryData(undefined)}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default MobileMonitorAlertPage;
