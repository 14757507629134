import React from 'react';
import { Store } from 'store';
import Utils from 'utils';
import Dialog from 'components/common/Dialog';
import Button from 'components/common/Button';
import TextField from 'components/common/TextField';
import TextArea from 'components/common/TextArea';
import FileInput from 'components/common/FileInput';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { MemoListModel } from 'models';
import { ApprovalStatusType, ApprovalDialogType, UserRoleType } from 'enums';

type FormState = {
  title: string;
  body: string;
  file: FileList | undefined;
};

type PROPS = {
  showType: string;
  isSubmit: boolean;
  title?: string;
  powerPlantId: string;
  user: Store.UserData | null;
  memo: MemoListModel | undefined;
  onCreate: (memo: MemoListModel, title?: string) => void;
  onUpdate: (memo: MemoListModel, title?: string) => void;
  onDelete: (id: string) => void;
  onClose: () => void;
};

const MemoDialog: React.FC<PROPS> = ({
  showType,
  isSubmit,
  title,
  user,
  memo,
  onCreate,
  onUpdate,
  onDelete,
  onClose,
}: PROPS) => {
  const methods = useForm<FormState>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      title: '',
      body: '',
    },
  });
  const file: FileList | undefined = methods.watch('file');
  const [previosImage, setPreviosImage] = React.useState('');

  React.useEffect(() => {
    methods.setValue('file', undefined);
    methods.setValue('title', title || '');
    setPreviosImage('');

    if (memo) {
      methods.setValue('body', memo.memo || '');
      setPreviosImage(memo.memo_file_url || '');
    }
  }, [showType, title, memo, methods]);

  const handleSubmitMemo: SubmitHandler<FormState> = async (data) => {
    const _data = {
      id: memo ? memo.id : '',
      status: 'display',
      user_name: `${user?.last_name}${user?.first_name}`,
      memo: data.body,
      memo_file: data.file && data.file[0],
      memo_file_url: memo && memo.memo_file_url ? previosImage : undefined,
    };

    if (showType === ApprovalDialogType.CREATE) {
      /* 報告新規登録 */
      if (user?.type === UserRoleType.SUB) {
        _data.status = ApprovalStatusType.PENDING;
      }

      onCreate(_data, data.title);
    } else if (showType === ApprovalDialogType.UPDATE) {
      /* 報告編集 */
      if (user?.type === UserRoleType.SUB) {
        _data.status = ApprovalStatusType.PENDINGUPDATE;
      }

      memo &&
        onUpdate(
          _data,
          user?.type === UserRoleType.SUB ? undefined : data.title
        );
    } else if (showType === ApprovalDialogType.DELETE) {
      /* 報告削除 */
      if (user?.type === UserRoleType.SUB) {
        _data.status = ApprovalStatusType.PENDINGDELETE;
      }
      memo && onDelete(memo.id);
    }

    methods.reset();
  };

  return (
    <Dialog
      show={showType !== ''}
      width="w-[350px] sm:w-[700px]"
      onClose={() => {
        methods.reset();
        onClose();
      }}
    >
      {isSubmit ? (
        <>
          {user?.type === UserRoleType.SUB ? (
            <p>承認フローに入りました。権限が上の方の承認後、反映されます。</p>
          ) : (
            <p>
              {showType === ApprovalDialogType.DELETE && '削除しました'}
              {showType === ApprovalDialogType.UPDATE && '更新しました'}
              {showType === ApprovalDialogType.CREATE && '登録しました'}
            </p>
          )}
          <div className="flex justify-end">
            <Button
              text="OK"
              size="sm"
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </>
      ) : (
        <FormProvider {...methods}>
          {showType === ApprovalDialogType.DELETE ? (
            <div className="text-center">
              <p className="text-2xl font-bold text-re-danger">
                コメントの削除
              </p>
              <p className="my-6 text-re-danger font-bold">
                コメントの削除をします
              </p>
              <p>削除しますか？</p>

              <div className="flex justify-center gap-6 my-6">
                <Button
                  text="戻る"
                  color="gray"
                  outlined
                  width="sm:w-[220px] w-[100px]"
                  onClick={() => {
                    methods.reset();
                    onClose();
                  }}
                />
                <Button
                  text="削除する"
                  color="danger"
                  width="sm:w-[220px] w-[100px]"
                  onClick={methods.handleSubmit(handleSubmitMemo)}
                />
              </div>
            </div>
          ) : (
            <>
              <p className="text-2xl text-center font-bold text-re-primary mb-3">
                {showType === ApprovalDialogType.UPDATE
                  ? 'コメント 編集'
                  : '新規コメント'}
              </p>
              <div className="mb-9">
                {user?.type === UserRoleType.SUB && title ? (
                  <p className="text-xl font-bold">{title || ''}</p>
                ) : (
                  <TextField
                    fieldName="title"
                    placeholder="例 ： ピンの場所や、タイトル"
                    require
                    validations={Utils.requiredValidation()}
                    errorMessage={methods.formState.errors.title?.message}
                  />
                )}
              </div>

              <TextArea
                fieldName="body"
                placeholder="例 ： こちらのパネルに破損がありました"
                require
                validations={Utils.requiredValidation()}
                errorMessage={methods.formState.errors.body?.message}
              />
              <div className="mt-6 mb-3">
                {previosImage && !file && (
                  <img
                    src={previosImage}
                    alt="comment_image"
                    className="max-h-[250px] object-contain"
                  />
                )}
                {file && file.length > 0 && (
                  <img
                    src={window.URL.createObjectURL(file[0])}
                    alt={file[0].name}
                    className="max-h-[250px] object-contain"
                  />
                )}
              </div>

              <div className="flex items-center">
                <FileInput fieldName="file" />
                {((file && file.length > 0) || previosImage) && (
                  <p
                    className="cursor-pointer text-xs text-re-blue200 hover:text-re-primary
                        duration-300 underline ml-6"
                    onClick={() => {
                      setPreviosImage('');
                      methods.setValue('file', undefined);
                    }}
                  >
                    画像添付を外す
                  </p>
                )}
              </div>

              <p className="mt-3 text-xs">
                記入者：&nbsp;
                {user?.type === UserRoleType.SUB ? (
                  <>{user?.contractor_name}</>
                ) : (
                  <>
                    {user?.last_name}
                    {user?.first_name}
                  </>
                )}
              </p>

              <div className="flex justify-center gap-6 my-6">
                <Button
                  text="戻る"
                  color="gray"
                  outlined
                  width="sm:w-[220px] w-[100px]"
                  onClick={() => {
                    methods.reset();
                    onClose();
                  }}
                />
                <Button
                  text={
                    showType === ApprovalDialogType.UPDATE
                      ? '更新する'
                      : '登録する'
                  }
                  width="sm:w-[220px] w-[100px]"
                  onClick={methods.handleSubmit(handleSubmitMemo)}
                />
              </div>
            </>
          )}
        </FormProvider>
      )}
    </Dialog>
  );
};

export default MemoDialog;
