import FormLabel from 'components/common/FormLabel';
import SelectBox from 'components/common/SelectBox';
import { CompanyModel, UserModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {
  editable: boolean;
  companyList: CompanyModel[];
};

const UserEditFormBlock1ItemCompanyID: React.FC<PROPS> = (props) => {
  const { formState, getValues } = useFormContext<UserModel>();

  const [options, setOptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  React.useEffect(() => {
    const tmp = props.companyList.map((c) => {
      return { label: c.company_name, value: c.id! };
    });
    setOptions(tmp);
  }, [props.companyList]);

  if (props.editable) {
    // 編集可能の場合
    return (
      <div className="flex gap-2">
        <FormLabel text="発電事業者" required subtext="※今後変更はできません" />
        <div className="flex-1">
          <SelectBox
            blankLabel="発電事業者を選択"
            fieldName="company_id"
            options={options}
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.company_id?.message}
          />
        </div>
      </div>
    );
  }

  // 編集不可の場合
  return (
    <div className="flex">
      <FormLabel text="発電事業者" className="font-bold" />
      <div className="flex-1 flex flex-wrap gap-2">
        {getValues().company_name}
      </div>
    </div>
  );
};

export default UserEditFormBlock1ItemCompanyID;
