import API from 'api';
import axios from 'axios';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import CompanyEditFormBlock1 from 'components/manage/company/CompanyEditFormBlock1';
import CompanyEditFormBlock2 from 'components/manage/company/CompanyEditFormBlock2';
import useNavigate from 'hooks/use-navigate';
import { CompanyModel, OutletContextModel } from 'models';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';

const ManageCompanyEditPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const [deleteConfirmDialogFlg, setDeleteConfirmDialogFlg] =
    React.useState(false);

  const methods = useForm<CompanyModel>({
    reValidateMode: 'onSubmit',
  });

  const onUpdate: SubmitHandler<CompanyModel> = async (data) => {
    console.log('更新', data);
    ctx.spinner.setState(true);
    try {
      const response = await API.Company.update(data!);
      console.log(response);

      ctx.msgDialog.open('更新しました', () => {
        navigate('/manage/company');
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errors = e.response?.data.errors;
        if (errors && Array.isArray(errors)) {
          for (const error of errors) {
            methods.setError(error.param, { message: error.msg });
          }
        } else {
          console.error(e);
        }
      } else {
        console.error(e);
      }
    }
    ctx.spinner.setState(false);
  };

  const onDelete = () => {
    setDeleteConfirmDialogFlg(true);
  };

  const onCloseDeleteConfirm = async (result?: boolean) => {
    setDeleteConfirmDialogFlg(false);
    if (!result) {
      return;
    }
    ctx.spinner.setState(true);
    try {
      const response = await API.Company.del(id!);
      console.log(response);
      ctx.msgDialog.open('削除しました', () => {
        navigate('/manage/company');
      });
    } catch (e) {
      console.error(e);
    }

    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.Company.get(id!);
        console.log(response);
        if (response.data) {
          methods.setValue('id', response.data.id);
          methods.setValue('company_name', response.data.company_name);
          methods.setValue(
            'president_first_name',
            response.data.president_first_name
          );
          methods.setValue(
            'president_last_name',
            response.data.president_last_name
          );
          methods.setValue('company_address', response.data.company_address);
          methods.setValue('company_tel', response.data.company_tel);
          methods.setValue('domain', response.data.domain);
          methods.setValue(
            'manager_first_name',
            response.data.manager_first_name
          );
          methods.setValue(
            'manager_last_name',
            response.data.manager_last_name
          );
          methods.setValue('manager_email', response.data.manager_email);
          methods.setValue('department', response.data.department);
          methods.setValue('manager_address', response.data.manager_address);
          methods.setValue('manager_tel', response.data.manager_tel);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="発電事業者編集" />
      <Breadcrumbs
        items={[
          { label: '発電事業者 管理', path: '/manage/company/' },
          { label: '発電事業者 編集' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">発電事業者 編集</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="max-w-[800px] mx-auto flex flex-col gap-7">
          <div className="font-bold text-2xl text-re-063B67">
            発電事業者 基本情報
          </div>
          <FormProvider {...methods}>
            <CompanyEditFormBlock1 />
            <CompanyEditFormBlock2 />
          </FormProvider>
          <div className="flex justify-center gap-4">
            <Button text="戻る" outlined color="gray" link="/manage/company/" />
            <Button text="更新する" onClick={methods.handleSubmit(onUpdate)} />
          </div>
          <div className="flex justify-center gap-4">
            <button
              className="text-re-danger hover:underline"
              onClick={onDelete}
            >
              発電事業者 削除
            </button>
          </div>
        </div>
      </div>
      {/* 削除確認ダイアログ */}
      <Dialog
        width="w-[997px]"
        show={deleteConfirmDialogFlg}
        onClose={onCloseDeleteConfirm}
      >
        <div className="flex flex-col items-center">
          <div className="font-bold text-xl text-re-be1a1a">警告</div>
          <div className="font-bold text-xl text-re-be1a1a">
            発電事業者の削除
          </div>
          <div className="my-10 text-center">
            <div className="font-bold text-xl text-re-be1a1a">
              発電事業者を削除すると、アドミン、メイン、サブからは閲覧できなくなります。
            </div>
            <div className="font-bold text-xl text-re-be1a1a">
              (マスターの画面には「アカウント削除」として表示が残ります)
            </div>
          </div>

          <div className="flex justify-center gap-4">
            <Button
              text="削除しない"
              outlined
              color="gray"
              onClick={() => onCloseDeleteConfirm()}
            />
            <Button
              text="削除"
              color="danger"
              onClick={() => {
                onCloseDeleteConfirm(true);
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ManageCompanyEditPage;
