type PROPS = {
  current: number;
  label: string | number;
  toPage: number;
  onChange: (page: number) => void;
  disabled?: boolean;
};

const MobilePaginationItem: React.FC<PROPS> = (props) => {
  const label = props.label.toString();

  if (props.disabled) {
    return (
      <div className="mx-1 px-0.5 rounded-full text-re-gray cursor-default">
        {label}
      </div>
    );
  }

  return (
    <button
      className={`mx-1 px-0.5 rounded-full hover:bg-re-smoke disabled:bg-re-smoke`}
      onClick={() => {
        props.onChange(props.toPage);
      }}
      disabled={props.current === props.label}
    >
      {label}
    </button>
  );
};

export default MobilePaginationItem;
