type PROPS = {
  text: string;
  required?: boolean;
  subtext?: string;
  className?: string;
  subtextClassName?: string;
};

const FormLabel: React.FC<PROPS> = (props) => {
  const { text, subtext, required, className, subtextClassName } = props;

  return (
    <div className={`form-label ${className}`}>
      <div className="flex">
        <label className="">
          {text}
          {required !== undefined && (
            <span
              className={`whitespace-nowrap ml-2 ${
                required ? 'text-re-danger' : 'text-re-gray'
              }`}
            >
              {required ? '必須' : '任意'}
            </span>
          )}
        </label>
      </div>
      {subtext && (
        <div
          className={`whitespace-pre-wrap ${
            subtextClassName ? subtextClassName : 'text-re-danger'
          }`}
        >
          {subtext}
        </div>
      )}
    </div>
  );
};

export default FormLabel;
