import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import CheckBox from 'components/common/CheckBox';
import DatePicker from 'components/common/DatePicker';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import TextArea from 'components/common/TextArea';
import TextField from 'components/common/TextField';
import TimePicker from 'components/common/TimePicker';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { getinformationStatusStr, InformationStatusType } from 'enums';
import useNavigate from 'hooks/use-navigate';
import { OutletContextModel } from 'models';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

type FormState = {
  title: string;
  body: string;
  deliveryOnDate: string;
  deliveryOnTime: string;
  deliveryEndDate: string | null;
  deliveryEndTime: string | null;
  is_master_only: boolean;
};

const ManageinformationEditPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);
  const methods = useForm<FormState>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      deliveryOnDate: dayjs().format('YYYY-MM-DD'),
      deliveryOnTime: dayjs().format('HH:mm'),
      is_master_only: false,
    },
  });
  const [isMasterOnly, setIsMasterOnly] = React.useState(
    methods.getValues('is_master_only')
  );
  const [showDialog, setShowDialog] = React.useState(false);
  const [status, setStatus] = React.useState('');

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.Information.get(Number(id));
        if (response.data) {
          methods.setValue('title', response.data.title);
          methods.setValue('body', response.data.body);
          methods.setValue(
            'deliveryOnDate',
            dayjs(response.data.delivery_on).format('YYYY-MM-DD')
          );
          methods.setValue(
            'deliveryOnTime',
            dayjs(response.data.delivery_on).format('HH:mm')
          );

          if (response.data.delivery_end) {
            methods.setValue(
              'deliveryEndDate',
              dayjs(response.data.delivery_end).format('YYYY-MM-DD')
            );
            methods.setValue(
              'deliveryEndTime',
              dayjs(response.data.delivery_end).format('HH:mm')
            );
          }

          methods.setValue(
            'is_master_only',
            response.data.is_master_only ?? false
          );
          setIsMasterOnly(methods.getValues('is_master_only'));

          const _status = getinformationStatusStr(
            response.data?.delivery_on,
            response.data?.delivery_end
          );
          setStatus(_status);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<FormState> = async (data) => {
    const currentTime = dayjs().format('YYYY-MM-DD HH:mm');
    const _deliveryOn = `${data.deliveryOnDate} ${data.deliveryOnTime}`;
    const _deliveryEnd =
      data.deliveryEndDate && data.deliveryEndTime
        ? `${data.deliveryEndDate} ${data.deliveryEndTime}`
        : '';

    // validation
    if (data.deliveryEndDate && !data.deliveryEndTime) {
      /** 掲載終了日があるが掲載終了時間が未指定のとき */
      methods.setError('deliveryEndTime', {
        message: '時間が指定されていません',
      });
    } else if (!data.deliveryEndDate && data.deliveryEndTime) {
      /** 掲載終了時間があるが掲載終了日が未指定のとき */
      methods.setError('deliveryEndDate', {
        message: '日付が指定されていません',
      });
    } else if (
      _deliveryEnd &&
      dayjs(_deliveryEnd).isSameOrBefore(_deliveryOn)
    ) {
      /** 掲載終了日時が掲載開始日時より以前のとき */
      methods.setError('deliveryEndTime', {
        message: '時間の範囲が不正です',
      });
    } else if (
      status === InformationStatusType.DELIVERY &&
      dayjs(currentTime).isAfter(_deliveryOn)
    ) {
      /** ステータスが配信待ち/予約中かつ掲載開始日時が現在時刻以前のとき */
      methods.setError('deliveryOnTime', {
        message: '現在時刻以前です',
      });
    } else {
      const _data = {
        title: data.title,
        body: data.body,
        delivery_on: dayjs(_deliveryOn).format(),
        delivery_end: _deliveryEnd ? dayjs(_deliveryEnd).format() : null,
        is_master_only: data.is_master_only,
      };

      try {
        console.log(_data);
        if (id) {
          const response = await API.Information.update(id, _data);

          if (response.data) {
            setShowDialog(true);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <div className="w-full h-full bg-re-F3F7FA px-8 py-2 pb-20 flex flex-col gap-4">
      <Head title="お知らせ編集" />
      <Breadcrumbs
        items={[
          { label: 'お知らせ 管理', path: '/manage/information/' },
          { label: 'お知らせ 編集' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">お知らせ 編集</div>
      <Card className="p-6">
        <div className="flex font-bold text-2xl text-re-063B67">
          お知らせ 編集
        </div>
        <p className="my-4">
          指定の時刻にメール配信、ダッシュボードのHOME画面に表示をします
        </p>
        <div className="flex font-bold text-lg mb-4 text-re-063B67">内容</div>

        <FormProvider {...methods}>
          <div className="mb-10">
            <TextField
              fieldName="title"
              label="件名"
              placeholder="件名記入 (メールの件名、ダッシュボードのHOMEのお知らせの表示文章にあたります)"
              require
              validations={Utils.characterLimitValidation(null, 255)}
              errorMessage={methods.formState.errors.title?.message}
            />
          </div>

          <TextArea
            fieldName="body"
            label="本文"
            placeholder="本文記入"
            require
            rows={9}
            validations={Utils.characterLimitValidation(null, 500)}
            errorMessage={methods.formState.errors.body?.message}
          />

          <div className="flex font-bold text-lg mt-10 mb-4 text-re-063B67">
            配信期間
          </div>

          <div className="flex items-start mb-10">
            <div className="w-1/4 max-w-[400px]">
              <label className="`whitespace-nowrap font-medium`">
                掲載開始日
              </label>
              <span className="`whitespace-nowrap text-re-danger ml-[8px]">
                必須
              </span>
            </div>

            <div className="flex w-3/4">
              <div className="w-[220px]">
                <DatePicker
                  fieldName="deliveryOnDate"
                  placeholder="掲載開始日の指定"
                  require
                  disabled={
                    status === InformationStatusType.DELIVERING ||
                    status === InformationStatusType.DELIVERED
                  }
                  width="w-[260px]"
                  minDate={dayjs().format('YYYY-MM-DD')}
                  validations={{
                    ...Utils.requiredValidation(),
                    ...Utils.dateRangeCheckValidation(
                      methods.getValues('deliveryOnDate'),
                      methods.getValues('deliveryEndDate')
                    ),
                  }}
                  errorMessage={
                    methods.formState.errors.deliveryOnDate?.message
                  }
                />
              </div>

              <div className="ml-6">
                <TimePicker
                  fieldName="deliveryOnTime"
                  width="w-[260px]"
                  disabled={
                    status === InformationStatusType.DELIVERING ||
                    status === InformationStatusType.DELIVERED
                  }
                  validations={Utils.requiredValidation()}
                  errorMessage={
                    methods.formState.errors.deliveryOnTime?.message
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex items-start mb-10">
            <div className="w-1/4 max-w-[400px]">
              <label className="`whitespace-nowrap font-medium`">
                掲載終了日
              </label>
              <span className="`whitespace-nowrap text-re-gray ml-[8px]">
                任意
              </span>
            </div>

            <div className="flex w-3/4">
              <div className="w-[220px]">
                <DatePicker
                  fieldName="deliveryEndDate"
                  placeholder="掲載終了日の指定"
                  width="w-[260px]"
                  minDate={dayjs().format('YYYY-MM-DD')}
                  validations={Utils.dateRangeCheckValidation(
                    methods.getValues('deliveryOnDate'),
                    methods.getValues('deliveryEndDate'),
                    false
                  )}
                  errorMessage={
                    methods.formState.errors.deliveryEndDate?.message
                  }
                />
              </div>

              <div className="ml-6">
                <TimePicker
                  fieldName="deliveryEndTime"
                  width="w-[260px]"
                  errorMessage={
                    methods.formState.errors.deliveryEndTime?.message
                  }
                />
              </div>
            </div>
          </div>
        </FormProvider>

        <div className="flex items-start mt-4 mb-10">
          <CheckBox
            id="is_master_only"
            label="マスターのみ表示"
            value={String}
            checked={isMasterOnly}
            setValue={(e) => {
              const tmp = methods.getValues('is_master_only');
              methods.setValue('is_master_only', !tmp);
              setIsMasterOnly(!tmp);
            }}
          />
        </div>

        <div className="mb-10">
          ※掲載の開始、終了時間は、最大5分間の遅延があります
        </div>

        <div className="flex justify-center gap-4">
          <Button
            text="戻る"
            outlined
            link={`/manage/information/detail/${id}/`}
          />
          <Button text="配信する" onClick={methods.handleSubmit(onSubmit)} />
        </div>
      </Card>

      <Dialog
        show={showDialog}
        onConfirm={() => navigate('/manage/information/')}
      >
        {`${methods.getValues('deliveryOnDate')} ${methods.getValues(
          'deliveryOnTime'
        )}` === dayjs().format('YYYY-MM-DD HH:mm')
          ? '配信しました'
          : '配信を予約しました'}
      </Dialog>
    </div>
  );
};

export default ManageinformationEditPage;
