import FormLabel from 'components/common/FormLabel';
import TextField from 'components/common/TextField';
import { UserRoleType } from 'enums';
import { UserModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {};

const UserEditFormBlock1: React.FC<PROPS> = (props) => {
  const { formState, getValues } = useFormContext<UserModel>();

  const type = getValues().type;

  if (!type) {
    return <></>;
  }
  if (type === UserRoleType.MASTER) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
      <div className="font-bold text-xl text-re-063B67">所属情報</div>

      {type === UserRoleType.SUB && (
        <div className="flex gap-2">
          <FormLabel text="担当者名" required />
          <div className="flex-1">
            <div className="flex-1 flex gap-4">
              <TextField
                fieldName="last_name"
                placeholder="苗字"
                validations={Utils.requiredValidation()}
                errorMessage={formState.errors.last_name?.message}
              />
              <TextField
                fieldName="first_name"
                placeholder="名前"
                validations={Utils.requiredValidation()}
                errorMessage={formState.errors.first_name?.message}
              />
            </div>
          </div>
        </div>
      )}

      {type === UserRoleType.SUB && (
        <div className="flex gap-2">
          <FormLabel text="メールアドレス" required />
          <div className="flex-1">
            <TextField
              fieldName="email"
              placeholder="例：xxxx@sample.com"
              validations={Utils.emailValidation(true)}
              errorMessage={formState.errors.email?.message}
            />
          </div>
        </div>
      )}

      <div className="flex gap-2">
        <FormLabel text="所属" required={false} />
        <div className="flex-1">
          <TextField
            fieldName="department"
            placeholder="例：◯◯◯事業部"
            validations={{}}
            errorMessage={formState.errors.department?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="住所" required={false} />
        <div className="flex-1">
          <TextField
            fieldName="address"
            placeholder="例：◯◯◯県◯◯◯市◯◯"
            validations={{}}
            errorMessage={formState.errors.address?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="電話番号" required={false} />
        <div className="flex-1">
          <TextField
            fieldName="tel"
            placeholder="例：0000-0000-0000"
            validations={Utils.telValidation(false)}
            errorMessage={formState.errors.tel?.message}
          />
        </div>
      </div>

      {type !== UserRoleType.SUB && (
        <div className="flex gap-2">
          <FormLabel text="メールアドレス" required />
          <div className="flex-1">
            <TextField
              fieldName="email"
              placeholder="例：xxxx@sample.com"
              validations={Utils.emailValidation(true)}
              errorMessage={formState.errors.email?.message}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserEditFormBlock1;
