import { TransitionStatus } from 'react-transition-group/Transition';
import React from 'react';

type Transition<T> = { [key in TransitionStatus]: T };

type Anime = {
  transition: string;
  opacity: number;
} & React.CSSProperties;

type TransformAnime = {
  transform: string;
} & Anime;

/**
 * フェードアニメーション
 */
export const createFadingAnime = (speed: number): Transition<Anime> => {
  return {
    entering: { transition: `all ${speed}s`, opacity: 0 },
    entered: { transition: `all ${speed}s`, opacity: 1 },
    exiting: { transition: `all ${speed}s`, opacity: 0 },
    exited: { transition: `all ${speed}s`, opacity: 0 },
    unmounted: { transition: '', opacity: 0 },
  };
};

/**
 * ドロップダウンアニメーション
 */
export const createDroppingDownAnime = (
  speed: number,
  pos: number
): Transition<TransformAnime> => {
  return {
    entering: {
      transition: `all ${speed}s`,
      transform: `translateY(${pos}px)`,
      opacity: 0,
    },
    entered: {
      transition: `all ${speed}s`,
      transform: 'translateY(0px)',
      opacity: 1,
    },
    exiting: {
      transition: `all ${speed}s`,
      transform: `translateY(${pos}px)`,
      opacity: 0,
    },
    exited: {
      transition: `all ${speed}s`,
      transform: `translateY(${pos}px)`,
      opacity: 0,
    },
    unmounted: { transition: '', transform: '', opacity: 0 },
  };
};

/**
 * bottomスライドアニメーション
 */
export const createSlidingToBottomAnime = (): Transition<TransformAnime> => {
  return {
    entering: {
      transition: 'all 0.3s',
      transform: 'scaleY(0)',
      opacity: 1,
    },
    entered: {
      transition: 'all 0.3s',
      transform: 'scaleY(1)',
      opacity: 1,
    },
    exiting: {
      transition: 'all 0.2s',
      transform: 'scaleY(1)',
      opacity: 1,
    },
    exited: {
      transition: 'all 0.2s',
      transform: 'scaleY(0)',
      opacity: 1,
    },
    unmounted: {
      transition: '',
      transform: '',
      opacity: 0,
    },
  };
};
