import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import ImageModal from 'components/common/ImageModal';
import MapSideMenu from 'components/monitor/map/MapSideMenu';
import Map from 'components/monitor/map/map/Map';
import { getMemoStatusMessageStr } from 'enums';
import { MapPinModel, MemoPendingListModel, OutletContextModel } from 'models';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import Utils from 'utils';

const MonitorMapApprovePage = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [dialogMessage, setDialogMessage] = React.useState('');
  const [memoList, setMemoList] = React.useState<Array<MemoPendingListModel>>(
    []
  );
  const [mapPinList, setMapPinList] = React.useState<Array<MapPinModel>>([]);
  const [imageSrc, setImageSrc] = React.useState('');

  const getPendingMemoList = async () => {
    const _memoList = await API.Memo.getPendingMemoList();

    if (_memoList.data) {
      setMemoList(_memoList.data);
      if (_memoList.data.length > 0) {
        setMapPinList([
          {
            title: _memoList.data[currentIndex].map_title,
            coord_x: _memoList.data[currentIndex].coord_x,
            coord_y: _memoList.data[currentIndex].coord_y,
          },
        ]);
      }
    }
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const _memoList = await API.Memo.getPendingMemoList();
        if (_memoList.data) {
          getPendingMemoList();
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (memoList[currentIndex]) {
      setMapPinList([
        {
          title: memoList[currentIndex].map_title,
          coord_x: memoList[currentIndex].coord_x,
          coord_y: memoList[currentIndex].coord_y,
        },
      ]);
    }
  }, [currentIndex, memoList]);

  /**
   * 承認
   */
  const handleApprove = async (id: string) => {
    try {
      await API.Memo.approve(id).then((res) => {
        setDialogMessage('承認しました');
      });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * 承認却下
   */
  const handleDecline = async (id: string) => {
    try {
      await API.Memo.decline(id).then((res) => {
        setDialogMessage('承認拒否しました');
      });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * ダイアログを閉じる
   */
  const onClose = () => {
    getPendingMemoList();
    setDialogMessage('');
  };

  return (
    <div className="w-full h-full bg-re-F3F7FA px-8 py-2 pb-20 flex flex-col gap-4">
      <Head title="サブユーザーの操作承認" />
      <Breadcrumbs
        items={[
          { label: 'MAP・報告', path: '/monitor/map' },
          { label: 'サブユーザーの操作承認' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">
        サブユーザーの操作承認
      </div>

      <Card className="p-6">
        <div className="font-bold text-2xl text-re-063B67">
          サブユーザーの承認依頼
        </div>

        {memoList.length > 0 ? (
          <>
            <div className="grid grid-cols-4 border mt-6 border-re-gray">
              <div className="flex col-span-3">
                <Map
                  mapPin={mapPinList[0]}
                  mapPinList={mapPinList}
                  src={
                    memoList.length > 0
                      ? memoList[currentIndex].map_file_url
                      : ''
                  }
                  height={600}
                />
              </div>

              <MapSideMenu
                isApprovePage
                mapPin={mapPinList[0]}
                memoList={[memoList[currentIndex]]}
                setImageSrc={setImageSrc}
              />
            </div>

            <p className="text-center mt-3">
              {getMemoStatusMessageStr(memoList[currentIndex].status)}
            </p>
            <div className="flex justify-center gap-9 mt-6">
              <Button
                text="承認しない"
                outlined
                onClick={() => handleDecline(memoList[currentIndex].id)}
              />
              <Button
                text="承認する"
                onClick={() => handleApprove(memoList[currentIndex].id)}
              />
            </div>

            {/* Pagination */}
            <div className="flex justify-center items-center mt-6">
              <span
                className={`mr-3 text-xs select-none cursor-pointer ${
                  currentIndex < 1 && 'text-re-smoke cursor-text'
                }`}
                onClick={() => {
                  currentIndex > 0 && setCurrentIndex((prev) => prev - 1);
                }}
              >
                ＜
              </span>
              <span className="select-none">
                {currentIndex + 1} &nbsp;/&nbsp; {memoList.length}
              </span>
              <span
                className={`ml-3 text-xs select-none cursor-pointer ${
                  currentIndex > memoList.length - 2 &&
                  'text-re-smoke cursor-text'
                }`}
                onClick={() => {
                  currentIndex < memoList.length - 1 &&
                    setCurrentIndex((prev) => prev + 1);
                }}
              >
                ＞
              </span>
            </div>
          </>
        ) : (
          <>
            <p className="mt-6">承認依頼はありません</p>
          </>
        )}
      </Card>

      <Dialog show={dialogMessage !== ''} onConfirm={onClose} onClose={onClose}>
        {dialogMessage}
      </Dialog>

      <ImageModal
        show={imageSrc !== ''}
        src={imageSrc}
        onClose={() => {
          setImageSrc('');
        }}
      />
    </div>
  );
};

export default MonitorMapApprovePage;
