import React from 'react';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendarEvent } from 'react-icons/bi';
import { Path, FieldValues, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
registerLocale('ja', ja);

type PROPS<T> = {
  fieldName: Path<T>; // fieldname
  validations?: object; // validation
  label?: string;
  labelWidth?: string;
  require?: boolean;
  width?: string;
  disabled?: boolean;
  placeholder?: string;
  minDate?: string;
  maxDate?: string;
  errorMessage?: string;
};

const DatePicker = <T extends FieldValues = never>({
  fieldName,
  validations,
  label,
  labelWidth,
  require,
  width,
  disabled,
  placeholder = '----/--/--',
  minDate,
  maxDate,
  errorMessage,
}: PROPS<T>) => {
  const { register, getValues, setValue } = useFormContext();

  // validationの発火
  React.useEffect(() => {
    register(fieldName, {
      ...validations,
    });
  }, [register, fieldName, validations]);

  const style = {
    container: `flex w-full`,
    label: `${labelWidth ? labelWidth : 'w-1/4'}`,
    labelText: `whitespace-nowrap font-medium`,
    require: `ml-[8px] whitespace-nowra ${
      require ? 'text-re-danger' : 'text-re-gray'
    }`,
    datePickerArea: `block ${
      label && !width ? 'w-3/4' : width ? width : 'w-full'
    }`,
    datePickerBox: `w-full relative flex items-center cursor-pointer text-re-gray z-1
    ${
      disabled
        ? 'bg-re-smoke'
        : errorMessage
        ? 'bg-re-red50'
        : 'bg-re-blue50 focus-within:bg-re-blue100 focus-within:text-re-primary'
    }`,
    datePicker: `w-full h-[40px] pl-[16px] rounded-[8px] cursor-pointer
    bg-transparent outline outline-1
      ${
        disabled
          ? 'outline-re-gray !cursor-default text-black'
          : errorMessage
          ? 'outline-re-danger text-re-danger'
          : 'outline-re-gray text-black focus:text-re-primary focus:outline-re-blue200'
      }`,
    clearBox: `text-center w-full`,
    clear: `cursor-pointer inline-block bg-[#f0f0f0] w-[90%] my-1 py-1
      duration-200 hover:bg-[#e2e2e2]`,
    icon: `absolute text-xl right-[10px] select-none`,
  };

  return (
    <div className={style.container}>
      {label && (
        <div className={style.label}>
          <label className={style.labelText}>{label}</label>
          <span className={style.require}>{require ? '必須' : '任意'}</span>
        </div>
      )}

      <div className={style.datePickerArea}>
        <div className={style.datePickerBox}>
          <BiCalendarEvent className={style.icon} />
          <ReactDatePicker
            className={style.datePicker}
            dateFormat="yyyy/MM/dd"
            disabled={disabled}
            selected={
              getValues(fieldName)
                ? dayjs(getValues(fieldName)).toDate()
                : undefined
            }
            peekNextMonth
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            locale="ja"
            minDate={minDate ? dayjs(minDate).toDate() : undefined}
            maxDate={maxDate ? dayjs(maxDate).toDate() : undefined}
            placeholderText={placeholder}
            onChange={(selectedDate) => {
              if (selectedDate) {
                setValue(
                  fieldName,
                  dayjs(selectedDate).format('YYYY-MM-DD') as any,
                  {
                    shouldValidate: true,
                  }
                );
              } else {
                setValue(fieldName, null as any, { shouldValidate: true });
              }
            }}
          >
            <div className={style.clearBox}>
              <div
                className={style.clear}
                onClick={() => {
                  setValue(fieldName, null as any, { shouldValidate: true });
                }}
              >
                Clear
              </div>
            </div>
          </ReactDatePicker>
        </div>

        <div className="text-re-danger">{errorMessage}</div>
      </div>
    </div>
  );
};

export default DatePicker;
