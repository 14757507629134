import API from 'api';
import axios from 'axios';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import AiPCSiEditFormBlock from 'components/manage/power_plant/aipcsi/AiPCSiEditFormBlock';
import useNavigate from 'hooks/use-navigate';
import {
  AiPCSiModel,
  CompanyModel,
  OutletContextModel,
  PowerPlantModel,
} from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';

const AiPSCiEditPage: React.FC = () => {
  const navigate = useNavigate();
  const { id, aipcsi_id } = useParams();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const company = React.useRef<CompanyModel>();
  const powerPlant = React.useRef<PowerPlantModel>();

  const [deleteConfirmDialogFlg, setDeleteConfirmDialogFlg] =
    React.useState(false);

  const methods = useForm<AiPCSiModel>({
    reValidateMode: 'onSubmit',
    defaultValues: {},
  });

  const onUpdate = async (data: AiPCSiModel) => {
    console.log(data);

    ctx.spinner.setState(true);
    try {
      const response = await API.AiPCSi.update(data!);
      console.log(response);
      ctx.msgDialog.open('更新しました', () => {
        navigate(`/manage/power_plant/detail/${id}/`);
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errors = e.response?.data.errors;
        if (errors && Array.isArray(errors)) {
          for (const error of errors) {
            methods.setError(error.param, { message: error.msg });
          }
        } else {
          console.error(e);
        }
      } else {
        console.error(e);
      }
    }
    ctx.spinner.setState(false);
  };

  const onDelete = () => {
    setDeleteConfirmDialogFlg(true);
  };

  const onCloseDeleteConfirm = async (result?: boolean) => {
    setDeleteConfirmDialogFlg(false);
    if (!result) {
      return;
    }
    ctx.spinner.setState(true);
    try {
      const response = await API.AiPCSi.del(aipcsi_id!);
      console.log(response);
      ctx.msgDialog.open('削除しました', () => {
        navigate(`/manage/power_plant/detail/${id}/`);
      });
    } catch (e) {
      console.error(e);
    }

    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        {
          const response = await API.PowerPlant.get(id);
          console.log(response);
          if (response.data) {
            powerPlant.current = response.data;
            {
              const companyId = powerPlant.current.company_id;
              const response = await API.Company.get(companyId);
              console.log(response);
              if (response.data) {
                company.current = response.data;
              }
            }
          }
        }
        const response = await API.AiPCSi.get(aipcsi_id);
        console.log(response);
        if (response.data) {
          methods.setValue('id', response.data.id);
          methods.setValue('power_plant_id', response.data.power_plant_id);
          methods.setValue('name', response.data.name);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="AiPCSi編集" />
      <Breadcrumbs
        items={[
          { label: '発電所 管理', path: '/manage/power_plant/' },
          { label: '発電所 詳細', path: `/manage/power_plant/detail/${id}` },
          { label: 'AiPCSi 編集' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">AiPCSi 編集</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="max-w-[800px] mx-auto flex flex-col gap-7">
          <div className="font-bold text-2xl text-re-063B67">AiPCSi 情報</div>
          <FormProvider {...methods}>
            <AiPCSiEditFormBlock
              powerPlant={powerPlant.current}
              company={company.current}
            />
          </FormProvider>
          <div className="flex justify-center gap-4">
            <Button
              text="戻る"
              outlined
              color="gray"
              link={`/manage/power_plant/detail/${id}/`}
            />
            <Button text="更新する" onClick={methods.handleSubmit(onUpdate)} />
          </div>
          <div className="flex justify-center gap-4">
            <button
              className="text-re-danger hover:underline"
              onClick={onDelete}
            >
              AiPCSi 削除
            </button>
          </div>
        </div>
      </div>
      {/* 削除確認ダイアログ */}
      <Dialog
        width="w-[997px]"
        show={deleteConfirmDialogFlg}
        onClose={onCloseDeleteConfirm}
      >
        <div className="flex flex-col items-center gap-8">
          <div className="font-bold text-xl text-re-danger">AiPCSi 削除</div>
          <div className="font-bold text-xl text-re-danger">
            対象:{methods.getValues().name}
          </div>
          <div className="text-center">
            <div>
              AiPCSiを削除すると、アドミン、メイン、サブには非表示になります。
            </div>
            <div>紐づくPCSも、削除対象になります。</div>
            <div>
              (データの取得は停止されますがマスターの画面には引き続き表示されます。)
            </div>
          </div>

          <div className="flex justify-center gap-4">
            <button
              className="w-56 border border-re-gray py-2 rounded-lg"
              onClick={() => {
                onCloseDeleteConfirm();
              }}
            >
              <span className="font-bold text-re-gray">しない</span>
            </button>
            <button
              className="w-56 bg-re-be1a1a py-2 rounded-lg hover:bg-re-danger-hover duration-100 transition-colors"
              onClick={() => {
                onCloseDeleteConfirm(true);
              }}
            >
              <span className="font-bold text-white">削除する</span>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AiPSCiEditPage;
