import { Link as L, useLocation } from 'react-router-dom';

type PROPS = {
  to: string;
  state?: any;
  children: React.ReactNode;
  className?: string;
  target?: React.HTMLAttributeAnchorTarget;
};

const Link: React.FC<PROPS> = (props) => {
  const location = useLocation();

  let to = props.to;
  const q = new URLSearchParams(location.search);
  const pc = q.get('pc');
  if (pc !== undefined && pc !== null && pc !== 'false') {
    const tmp = to.split('?');
    if (tmp.length === 1) {
      to += '?pc';
    } else {
      const q = new URLSearchParams(tmp[1]);
      q.set('pc', '');
      to = tmp[0] + q.toString();
    }
  }

  return (
    <L
      to={to}
      state={props.state}
      target={props.target}
      className={props.className}
    >
      {props.children}
    </L>
  );
};

export default Link;
