import FormLabel from 'components/common/FormLabel';
import TextField from 'components/common/TextField';
import { PCSModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {};

const PCSEditFormBlock3: React.FC<PROPS> = (props) => {
  const { formState, getValues } = useFormContext<PCSModel>();

  const thresholds = getValues().thresholds ?? [];

  return (
    <div className="[&_.form-label]:w-1/4">
      <div className="font-bold text-xl text-re-063B67">
        センサーデータ取得 閾値
      </div>

      <div className="mt-4 flex gap-2">
        <div className="form-label"></div>
        <div className="flex gap-8">
          <div className="w-24 text-center">注意閾値</div>
          <div className="w-24 text-center">異常閾値</div>
        </div>
      </div>

      <div className="flex flex-col gap-8">
        {thresholds.map((threshold, index) => {
          let errorMessageCaution = '';
          let errorMessageTrouble = '';
          const tmp = formState.errors.thresholds;
          if (tmp && tmp.length && tmp.length > index) {
            errorMessageCaution = tmp[index]?.caution?.message ?? '';
            errorMessageTrouble = tmp[index]?.trouble?.message ?? '';
          }

          return (
            <div key={index} className="flex gap-2">
              <FormLabel
                text={threshold.name}
                required
                subtext={'注意閾値デフォルト10\n異常閾値デフォルト20'}
                subtextClassName="text-gray-400 text-sm"
              />
              <div className="flex gap-8">
                <div className="flex-1 flex flex-col">
                  <TextField
                    fieldName={`thresholds.${index}.caution`}
                    width="w-24"
                    validations={Utils.numValidation()}
                    errorMessage={errorMessageCaution ? ' ' : undefined}
                  />
                  <div className="text-re-danger">{errorMessageCaution}</div>
                </div>
                <div className="flex-1 flex flex-col">
                  <TextField
                    fieldName={`thresholds.${index}.trouble`}
                    width="w-24"
                    validations={Utils.numValidation()}
                    errorMessage={errorMessageTrouble ? ' ' : undefined}
                  />
                  <div className="text-re-danger">{errorMessageTrouble}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PCSEditFormBlock3;
