import Button from 'components/common/Button';
import Card from 'components/common/Card';
import dayjs from 'dayjs';
import { UserRoleType, getAlertColor, getAlertTypeStr } from 'enums';
import { AlertTreeAPIModel, AlertTreeModel } from 'models';
import React from 'react';
import { Store } from 'store';
import Utils from 'utils';

type PROPS = {
  data?: AlertTreeAPIModel;
  setShowAlertCancel: React.Dispatch<React.SetStateAction<boolean>>;
};

const AlertCard: React.FC<PROPS> = ({ data, setShowAlertCancel }: PROPS) => {
  const user = Store.loadUserDara();

  // 最新のアラート
  const [alert, setAlert] = React.useState<AlertTreeModel>();

  // アラート解除可能かどうか
  const isCancelable = (alert?: AlertTreeModel) => {
    if (!alert) {
      return false;
    }
    if (alert.is_canceled) {
      // アラート解除済
      return false;
    }
    if (alert.is_cancelable === false) {
      return false;
    }
    if (user?.type === UserRoleType.SUB) {
      // サブ権限ではアラート解除できない
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    if (data && data.alert_tree.length > 0) {
      setAlert(data.alert_tree[0]);
    } else {
      setAlert(undefined);
    }
  }, [data]);

  return (
    <Card className="p-6 mb-3">
      <div className="font-bold text-2xl text-re-063B67">アラート</div>

      <div className="font-bold text-lg text-re-063B67 py-5">現在の状態</div>
      <div className="flex items-center">
        <div className="flex-1">
          {alert && dayjs(alert.captured_at).format('YYYY/MM/DD HH:mm:ss')}
        </div>
        <div className="flex flex-[2] items-center">
          <div
            className={`px-6 p-1 font-bold
            ${getAlertColor(alert ? alert.type : '')} border-4`}
          >
            {getAlertTypeStr(alert ? alert.type : '')}
          </div>
          <p className="ml-3">{Utils.Alert.getMessage(alert)}</p>
          <p className="ml-12">{Utils.Alert.getSubMessage(alert)}</p>
        </div>
      </div>

      <div className="font-bold text-lg text-re-063B67 py-5">
        状況確認とアラートの解除
      </div>
      <div className="flex items-center mb-6">
        <div className="flex-1 font-bold">① 状態を確認</div>
        <div className="flex flex-[2] gap-6 items-center justify-between">
          <p className="flex-1">
            取得データや、現場の状態など確認をしてください
          </p>
          <Button
            text="AiPCSi解析データを確認"
            outlined
            link="/monitor/failure"
            linkState={{
              company_id: data?.power_plant.company_id,
              power_plant_id: data?.power_plant.id,
              pcs_id: data?.pcs.id,
            }}
          />
        </div>
      </div>

      <div className="flex items-center mb-6">
        <div className="flex-1 font-bold">② アラート解除</div>
        <div className="flex flex-[2] gap-6 items-center">
          <p className="flex-1">
            閾値より下回るとアラートを解除できます。アラートを解除をすると、該当のアラートはHOMEから非表示になります。
            <br></br>
            ※サブの方は解除できません。上の権限の方に依頼してください
          </p>

          {alert?.is_canceled ? (
            <span className="w-[220px] font-bold text-center">アラート解除済</span>
          ) : (
            <Button
              text="アラート解除"
              color="danger"
              outlined
              disabled={!isCancelable(alert)}
              onClick={() => setShowAlertCancel(true)}
            />
          )}
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex-1 font-bold">③ アラート解除者</div>
        <div className="flex flex-[2] items-center">
          <span className="font-bold">対応者</span>
          <p className="ml-4">
            {alert && alert.canceled_by ? alert.canceled_by : '-'}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default AlertCard;
