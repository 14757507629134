import { getAlertColor, getAlertTypeStr } from 'enums';
import React from 'react';

type PROPS = {
  type: '' | 'normal' | 'caution' | 'trouble' | null;
};

const StatusBox: React.FC<PROPS> = (props) => {
  if (props.type === null) {
    return (
      <div className="w-24 py-2 text-center text-re-gray">
        <span className="font-bold">ー</span>
      </div>
    );
  }
  return (
    <div
      className={`w-24 py-2 text-center text-white ${getAlertColor(
        props.type
      )} border-4`}
    >
      <span className="whitespace-nowrap font-bold">
        {getAlertTypeStr(props.type)}
      </span>
    </div>
  );
};

export default StatusBox;
