import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Head from 'components/common/Head';
import Link from 'components/common/Link';
import TextField from 'components/common/TextField';
import Pagination from 'components/common/pagination/Pagination';
import { CompanyModel, OutletContextModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import Utils from 'utils';

type FormState = {
  keyword: string;
};

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;

const ManageCompanyPage: React.FC = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const methods = useForm<FormState>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      keyword: '',
    },
  });

  const [data, setData] = React.useState<CompanyModel[]>([]);
  const [filtered, setFiltered] = React.useState<CompanyModel[]>([]);
  const [items, setItems] = React.useState<CompanyModel[]>([]);
  const [page, setPage] = React.useState(1);

  const onFilter = () => {
    let filtered = data;
    const filterText = methods.getValues().keyword;
    if (filterText !== '') {
      filtered = data.filter(
        (v) =>
          v.company_name.includes(filterText) ||
          v.domain.includes(filterText) ||
          v.manager_first_name.includes(filterText) ||
          v.manager_last_name.includes(filterText)
      );
    }
    setPage(1);
    setFiltered(filtered);
    setItems(filtered.slice(0, NUM_IN_PAGE));
  };

  const onChangePage = (page: number) => {
    setPage(page);
    if (!filtered) {
      setItems([]);
      return;
    }

    const start = NUM_IN_PAGE * (page - 1);
    const end = start + NUM_IN_PAGE;
    setItems(filtered.slice(start, end));
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.Company.list();
        console.log(response);
        if (response.data) {
          setData(response.data);
          setFiltered(response.data);
          setItems(response.data.slice(NUM_IN_PAGE * (page - 1), NUM_IN_PAGE));
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="発電事業者一覧" />
      <Breadcrumbs items={[{ label: '発電事業者一覧' }]} />
      <div className="font-bold text-3xl text-re-063B67">発電事業者一覧</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="flex items-center gap-10">
          <div className="flex-1 font-bold text-2xl text-re-063B67">
            発電事業者一覧
          </div>
          <div className="flex items-center gap-1">
            <FormProvider {...methods}>
              <TextField
                fieldName="keyword"
                width="w-[320px]"
                placeholder="例： 発電事業者名 など"
              />
            </FormProvider>
            <Button text="絞り込み" size="sm" onClick={onFilter} />
          </div>
          <Link to={'/manage/company/create'}>
            <button className="bg-re-primary text-white rounded-lg font-bold hover:bg-re-primary-hover duration-100 transition-colors">
              <div className="mx-8 my-3">発電事業者新規登録</div>
            </button>
          </Link>
        </div>

        <table className="w-full my-4 border border-re-gray rounded-lg border-separate border-spacing-0">
          <thead>
            <tr className="bg-re-4D7FAF text-white">
              <th className="p-2 rounded-tl-lg">発電事業者名</th>
              <th className="p-2">共通ID</th>
              <th className="p-2">登録している発電所</th>
              <th className="p-2">発電事業者 管理者</th>
              <th className="p-2 rounded-tr-lg">発電事業者詳細</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              const isDeleted = !!item.deleted_at;
              return (
                <tr
                  key={index}
                  className={`group ${isDeleted ? 'bg-re-smoke text-re-gray' : ''
                    }`}
                >
                  <td className="p-4 font-bold group-last:rounded-bl-lg">
                    {item.company_name}
                  </td>
                  <td className="p-4">{item.domain}</td>
                  <td className="p-4 text-center">{item.power_plant_count}</td>
                  <td className="p-4">
                    {item.manager_last_name}&nbsp;{item.manager_first_name}
                  </td>
                  <td className="p-4 text-center group-last:rounded-br-lg">
                    <Link to={`/manage/company/detail/${item.id}/`}>
                      <button className="bg-re-primary text-white rounded-lg font-bold hover:bg-re-primary-hover duration-100 transition-colors">
                        <div className="mx-6 my-2">詳細</div>
                      </button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <Pagination
            current={page}
            count={Math.ceil(filtered.length / NUM_IN_PAGE)}
            onChange={onChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageCompanyPage;
