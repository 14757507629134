import axios from 'axios';
import { AlertTreeAPIModel, HomeAlertAPIModel } from 'models';
import { AlertModel } from 'models';

export async function now() {
  const response = await axios.get<HomeAlertAPIModel>('/alert/now');
  return response;
}

export async function log() {
  const response = await axios.get<AlertModel[]>(`/alert/log`);
  return response;
}

export async function tree(id: string) {
  const response = await axios.get<AlertTreeAPIModel>(`/alert/${id}/tree`);
  return response;
}

export async function cancel(id: string) {
  const response = await axios.put(`/alert/${id}/cancel`);
  return response;
}
