import API from 'api';
import axios from 'axios';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Head from 'components/common/Head';
import AiPCSiEditFormBlock from 'components/manage/power_plant/aipcsi/AiPCSiEditFormBlock';
import useNavigate from 'hooks/use-navigate';
import {
  AiPCSiModel,
  CompanyModel,
  OutletContextModel,
  PowerPlantModel,
} from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';

const AiPSCiCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const company = React.useRef<CompanyModel>();
  const powerPlant = React.useRef<PowerPlantModel>();

  const methods = useForm<AiPCSiModel>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      power_plant_id: id,
      name: '',
    },
  });

  const onCreate = async (data: AiPCSiModel) => {
    ctx.spinner.setState(true);
    try {
      const response = await API.AiPCSi.create(data!);
      console.log(response);

      ctx.msgDialog.open('登録しました', () => {
        navigate(`/manage/power_plant/detail/${id}/`);
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errors = e.response?.data.errors;
        if (errors && Array.isArray(errors)) {
          for (const error of errors) {
            methods.setError(error.param, { message: error.msg });
          }
        } else {
          console.error(e);
        }
      } else {
        console.error(e);
      }
    }
    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.PowerPlant.get(id);
        console.log(response);
        if (response.data) {
          powerPlant.current = response.data;
          {
            const companyId = powerPlant.current.company_id;
            const response = await API.Company.get(companyId);
            console.log(response);
            if (response.data) {
              company.current = response.data;
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="AiPCSi新規登録" />
      <Breadcrumbs
        items={[
          { label: '発電所 管理', path: '/manage/power_plant/' },
          { label: '発電所 詳細', path: `/manage/power_plant/detail/${id}` },
          { label: 'AiPCSi 新規登録' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">AiPCSi 新規登録</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="max-w-[800px] mx-auto flex flex-col gap-7">
          <div className="font-bold text-2xl text-re-063B67">AiPCSi 情報</div>
          <FormProvider {...methods}>
            <AiPCSiEditFormBlock
              powerPlant={powerPlant.current}
              company={company.current}
            />
          </FormProvider>
          <div className="flex justify-center gap-4">
            <Button
              text="戻る"
              outlined
              color="gray"
              link={`/manage/power_plant/detail/${id}/`}
            />
            <Button text="登録する" onClick={methods.handleSubmit(onCreate)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiPSCiCreatePage;
