import { useLocation, useNavigate as _ } from 'react-router-dom';

const useNavigate = () => {
  const navigate = _();
  const location = useLocation();

  return (url: string) => {
    const q = new URLSearchParams(location.search);
    const pc = q.get('pc');
    if (pc !== undefined && pc !== null && pc !== 'false') {
      const tmp = url.split('?');
      if (tmp.length === 1) {
        navigate(tmp[0] + '?pc');
      } else {
        const q = new URLSearchParams(tmp[1]);
        q.set('pc', '');
        navigate(tmp[0] + q.toString());
      }
    } else {
      navigate(url);
    }
  };
};

export default useNavigate;
