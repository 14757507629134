import React from 'react';
import dayjs from 'dayjs';
import Card from 'components/common/Card';
import Button from 'components/common/Button';
import { getAlertTypeStr, getAlertColor } from 'enums';
import { HomeAlertModel, HomeAlertPowerPlantModel } from 'models';

type PROPS = {
  company_name: string;
  data: HomeAlertPowerPlantModel;
};

const AlertCard: React.FC<PROPS> = ({ company_name, data }: PROPS) => {
  const getMessage = (data: HomeAlertModel) => {
    if (data.sensor_name === 'logger_status') {
      return 'AiPCSiシステム';
    }

    let sensor = '';
    switch (data.sensor_name) {
      case 'indoor_temperature_status':
        sensor = '室内温度';
        break;
      case 'cooling_fan_vibration_status':
        sensor = '冷却ファン';
        break;
      case 'inverter_current_u_status':
        sensor = 'U電流';
        break;
      case 'inverter_current_v_status':
        sensor = 'V電流';
        break;
      case 'inverter_current_w_status':
        sensor = 'W電流';
        break;
      case 'emi_level_status':
        sensor = 'EMI';
        break;
      default:
        sensor = data.sensor_name;
    }
    if (data.type === 'normal') {
      return `正常 : ${sensor}`;
    } else {
      return `故障予兆 : ${sensor}`;
    }
  };

  return (
    <Card
      headerTitle={`【${company_name}】 ${data.name}`}
      headerClass="bg-re-4D7FAF text-white font-bold"
    >
      <div className="p-3">
        {!data.count ? (
          <p className="text-re-gray font-bold">アラートは出ていません</p>
        ) : (
          <>
            <p className="font-bold mb-3">
              アラート未解除 <span className="text-re-danger">{data.count}件</span>
            </p>

            <div className="grid grid-cols-2 xl:grid-cols-3 gap-x-3 gap-y-6 ">
              {data.pcs.map((pcs, index) =>
                pcs.alerts.map((alert, index2) => (
                  <div key={`${index}_${index2}`}>
                    <Card type={alert.type} headerTitle={getMessage(alert)}>
                      <div className="p-3">
                        <p className="text-sm">
                          {dayjs(alert.captured_at).format(
                            'YYYY/MM/DD HH:mm:ss'
                          )}
                        </p>
                        <div className="flex items-center mt-1 mb-3">
                          <div className="text-center border w-[45px]">PCS</div>
                          <p className="font-bold pl-2">{pcs.name}</p>
                        </div>
                        <div className="flex items-end">
                          <div className="flex-1">
                            {alert.first_alert ? (
                              <div>
                                <p className="text-sm">初回のアラート</p>
                                <div className="flex 2xl:flex-row 2xl:items-end xl:flex-col xl:items-start md:flex-row md:items-end text-sm">
                                  <span>
                                    {dayjs(
                                      alert.first_alert.captured_at
                                    ).format('YYYY/MM/DD HH:mm:ss')}
                                  </span>
                                  <div
                                    className={`text-white text-xs py-1 px-2 ml-3 ${getAlertColor(
                                      alert.first_alert.type
                                    )} border-2`}
                                  >
                                    {getAlertTypeStr(alert.first_alert.type)}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <p className="text-sm">&nbsp;</p>
                                <div className="2xl:flex xl:block md:flex">
                                  <div className="flex 2xl:flex-row 2xl:items-end xl:flex-col xl:items-start md:flex-row md:items-end text-sm">
                                    <span>&nbsp;</span>
                                    <div className="text-xs py-1 px-2 ml-3">
                                      &nbsp;
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <Button
                            text="確認する"
                            size="sm"
                            link={`/monitor/alert/detail/${alert.id}`}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default AlertCard;
