import FormLabel from 'components/common/FormLabel';
import TextField from 'components/common/TextField';
import { PCSModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {};

const PCSEditFormBlock2: React.FC<PROPS> = (props) => {
  const { formState } = useFormContext<PCSModel>();

  return (
    <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
      <div className="font-bold text-xl text-re-063B67">PCS 情報</div>

      <div className="flex gap-2">
        <FormLabel text="PCSの管理名称" required />
        <div className="flex-1">
          <TextField
            fieldName="name"
            placeholder="例：PCS-001-北 (現場で管理しているPCS名称)"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.name?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="PCSメーカー名" required />
        <div className="flex-1">
          <TextField
            fieldName="maker"
            placeholder="例：◯◯◯製造 (メーカー名を入力)"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.maker?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="PCSの型式" required />
        <div className="flex-1">
          <TextField
            fieldName="model"
            placeholder="例：AB-123 (PCSの型式を入力)"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.model?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default PCSEditFormBlock2;
