import dayjs from 'dayjs';
/**
 * 必須validation
 */
export const requiredValidation = () => {
  return {
    required: '必須項目です',
  };
};

export const loginIDValidation = () => {
  return {
    required: '必須項目です',
    validate: (v: string) => {
      if (v.includes('@')) {
        return '@は使用できません';
      }
    },
  };
};

/**
 * 文字数制限validation
 */
export const characterLimitValidation = (
  min: number | null,
  max: number | null,
  required: boolean = true
) => {
  return {
    required: required && '必須項目です',
    minLength: min && { value: min, message: `${min}文字以上` },
    maxLength: max && { value: max, message: `${max}文字まで` },
  };
};

/**
 * 日付範囲整合性チェックvalidation
 */
export const dateRangeCheckValidation = (
  start: string | null,
  end: string | null,
  required: boolean = true
) => {
  return {
    required: required && '必須項目です',
    validate: () => {
      if (start && end) {
        if (dayjs(end).isBefore(start)) {
          return '日付の範囲が不正です';
        }
      }
    },
  };
};

/**
 * メールアドレスの形式validation
 */
export const emailValidation = (required: boolean = true) => {
  return {
    required: required && '必須項目です',
    pattern: {
      value:
        /^([\w!#$%&'*+\-/=?^`{|}~]+(\.[\w!#$%&'*+\-/=?^`{|}~]+)*|"([\w!#$%&'*+\-/=?^`{|}~. ()<>[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
      message: 'メールアドレスの形式が不正です',
    },
  };
};

/**
 * 電話番号の形式validation
 */
export const telValidation = (required: boolean = true) => {
  return {
    required: required && '必須項目です',
    pattern: {
      value: /^0[0-9]+-[0-9]{1,4}-[0-9]{4}$/,
      message: '電話番号の形式が不正です',
    },
  };
};

/**
 * 共通IDの形式validation
 */
export const domainValidation = () => {
  return {
    required: '必須項目です',
    pattern: {
      value: /^[a-z0-9][a-z0-9-.]*[a-z0-9]$/,
      message: '共通IDは半角英数字とハイフン、ドットのみ入力可能です',
    },
  };
};

export const numValidation = (required: boolean = true) => {
  return {
    required: required && '必須項目です',
    // valueAsNumber: true,
    validate: (v: number) => {
      if (isNaN(v)) {
        return '数値のみ入力可能です';
      }
    },
  };
};
