import API from 'api';
import Card from 'components/common/Card';
import Head from 'components/common/Head';
import MobileAlertCard from 'components/home/MobileAlertCard';
import { HomeAlertPowerPlantModel, OutletContextModel } from 'models';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import Utils from 'utils';

type ListData = {
  company_name: string;
  data: HomeAlertPowerPlantModel;
};

const MobileHomePage: React.FC = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);
  const alertCount = React.useRef(0);
  const [data, setData] = React.useState<ListData[]>([]);

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const alerts = await API.Alert.now();
        if (alerts.data) {
          const tmp: ListData[] = [];
          // 表示用にデータ構造を変換
          for (let data of alerts.data) {
            for (let powerPlant of data.power_plants) {
              // 発電所ごとのアラートの数を設定
              powerPlant.count = powerPlant.pcs.reduce(
                (sum, pcs) => sum + pcs.alerts.length,
                0
              );
              tmp.push({
                company_name: data.company_name,
                data: powerPlant,
              });
            }
          }
          // 全アラートの数を設定
          alertCount.current = tmp.reduce(
            (sum, item) => sum + (item.data.count ?? 0),
            0
          );

          // アラートがある発電所のデータを前にソート
          const tmp2 = tmp.sort((a, b) => {
            const aCount = a.data.count ?? 0;
            const bCount = b.data.count ?? 0;
            if (aCount === 0 && bCount > 0) {
              return 1;
            } else if (aCount > 0 && bCount === 0) {
              return -1;
            } else {
              return 0;
            }
          });

          setData(tmp2);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full bg-re-F3F7FA px-8 py-2 pb-20 flex flex-col">
      <Head title="HOME" />
      <div className="my-2 font-bold text-3xl text-re-063B67">HOME</div>
      <Card className="p-6">
        <>
          <div className="flex items-center">
            <div className="font-bold text-2xl text-re-063B67">
              未確認のアラート一覧
            </div>
          </div>

          <p className="mt-4 mb-3 font-bold">
            アラート未解除:{' '}
            <span className="text-re-danger">{alertCount.current}件</span>
          </p>

          <div className="flex flex-col gap-4">
            {data?.map((alert, index) => {
              return (
                <div key={index}>
                  <MobileAlertCard
                    company_name={alert.company_name}
                    data={alert.data}
                  />
                </div>
              );
            })}
          </div>
        </>
      </Card>
    </div>
  );
};

export default MobileHomePage;
