import { ReactComponent as Logo } from 'assets/logo.svg';
import Spinner from 'components/common/Spinner';
import Menu from 'components/common/menu/Menu';
import { useEffect, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Store } from 'store';

const DefaultMobileLayout: React.FC = () => {
  const location = useLocation();

  const [spinner, setSpinner] = useState(false);
  const [menu, setMenu] = useState(false);

  const onMenuOn = () => {
    setMenu(true);
  };
  const onMenuOff = () => {
    setMenu(false);
  };

  useEffect(() => {
    setMenu(false);
  }, [location.pathname]);

  const token = Store.loadToken();
  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA flex flex-col">
      {menu && (
        <div
          className="absolute w-screen h-screen bg-zinc-600 opacity-50 z-20"
          onClick={onMenuOff}
        ></div>
      )}
      <div
        className={`absolute max-h-screen overflow-y-auto z-20 ${
          menu ? 'translate-x-0' : '-translate-x-full'
        } transition-all duration-100`}
      >
        <Menu isMobile={true} />
      </div>
      <div className="bg-re-063B67 p-4 pt-10 flex justify-between items-center">
        <AiOutlineMenu className="text-white text-4xl" onClick={onMenuOn} />
        <Logo className="fill-white h-9" />
        <AiOutlineMenu className="text-4xl invisible" />
      </div>
      <div className="flex-1">
        <Outlet context={{ spinner: { flg: spinner, setState: setSpinner } }} />
      </div>
      <Spinner show={spinner} />
    </div>
  );
};

export default DefaultMobileLayout;
