import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Head from 'components/common/Head';
import UserDetailPanel1 from 'components/manage/user/UserDetailPanel1';
import UserDetailPanel2 from 'components/manage/user/UserDetailPanel2';
import UserDetailPanel3 from 'components/manage/user/UserDetailPanel3';
import { UserRoleType } from 'enums';
import { OutletContextModel, PowerPlantModel, UserModel } from 'models';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Store } from 'store';
import Utils from 'utils';

const SettingMaypagePage: React.FC = () => {
  const userData = Store.loadUserDara();
  const id = userData?.id ?? '';

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const [data, setData] = React.useState<UserModel>();
  const [powerPlantList, setPowerPlantList] = React.useState<PowerPlantModel[]>(
    []
  );

  const getEditPagePath = () => {
    if (
      userData?.type === UserRoleType.MASTER ||
      userData?.type === UserRoleType.ADMIN
    ) {
      return '/setting/mypage/edit';
    } else {
      return null;
    }
  };
  const changePWPagePath = () => {
    if (
      userData?.type === UserRoleType.MASTER ||
      userData?.type === UserRoleType.ADMIN
    ) {
      return `/setting/mypage/changepw/${data?.id}/`;
    } else {
      return null;
    }
  };

  const load = async () => {
    try {
      let user: UserModel | null = null;
      const response = await API.User.get(id!);
      console.log(response);
      if (response.data) {
        user = response.data;
        setData(user);
        return user;
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  };
  const onAddEmail = async (email: string) => {
    // バリデーション
    ctx.spinner.setState(true);
    try {
      const response = await API.User.addEmail(id!, email);
      console.log(response);
      if (response.data) {
        await load();
      }
      ctx.spinner.setState(false);
      ctx.msgDialog.open('登録しました');
      return true;
    } catch (e) {
      console.error(e);
      ctx.spinner.setState(false);
      ctx.msgDialog.open('登録できませんでした。入力に誤りがあります。');
      return false;
    }
  };

  const onRemoveEmail = async (emailID: string) => {
    ctx.spinner.setState(true);
    try {
      const response = await API.User.deleteEmail(id!, emailID);
      console.log(response);
      await load();
      ctx.msgDialog.open('解除しました');
    } catch (e) {
      console.error(e);
      ctx.msgDialog.open('解除できませんでした。入力に誤りがあります。');
    }
    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      const user = await load();
      if (user) {
        const response = await API.PowerPlant.listInCompany(user.company_id);
        if (response.data) {
          setPowerPlantList(response.data);
        }
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="マイページ" />
      <Breadcrumbs items={[{ label: 'マイページ' }]} />
      <div className="font-bold text-3xl text-re-063B67">ユーザー 詳細</div>

      <UserDetailPanel1
        data={data}
        editPagePath={getEditPagePath()}
        powerPlantList={powerPlantList}
      />
      <UserDetailPanel2 changePWPagePath={changePWPagePath()} />
      <UserDetailPanel3
        data={data}
        onAddEmail={onAddEmail}
        onRemoveEmail={onRemoveEmail}
      />
    </div>
  );
};

export default SettingMaypagePage;
