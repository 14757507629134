import API from 'api';
import axios from 'axios';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import CompanyEditFormBlock1 from 'components/manage/company/CompanyEditFormBlock1';
import CompanyEditFormBlock2 from 'components/manage/company/CompanyEditFormBlock2';
import useNavigate from 'hooks/use-navigate';
import { CompanyModel, OutletContextModel } from 'models';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import Utils from 'utils';

const ManageCompanyCreatePage: React.FC = () => {
  const navigate = useNavigate();

  const ctx = useOutletContext<OutletContextModel>();

  const [data, setData] = React.useState<CompanyModel>();

  const methods = useForm<CompanyModel>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      company_name: '',
      domain: '',
      president_first_name: '',
      president_last_name: '',
      company_address: '',
      company_tel: '',
      manager_first_name: '',
      manager_last_name: '',
      manager_email: '',
      department: '',
      manager_address: '',
      manager_tel: '',
    },
  });

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  const onCreate: SubmitHandler<CompanyModel> = async (data) => {
    setData(data);
  };

  const onCloseCreateConfirm = async (result?: boolean) => {
    const tmp = data;
    setData(undefined);
    if (!result || !tmp) {
      return;
    }

    ctx.spinner.setState(true);
    try {
      const response = await API.Company.create(tmp);
      console.log(response);

      ctx.msgDialog.open('登録しました', () => {
        navigate('/manage/company');
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errors = e.response?.data.errors;
        if (errors && Array.isArray(errors)) {
          for (const error of errors) {
            methods.setError(error.param, { message: error.msg });
          }
        } else {
          console.error(e);
        }
      } else {
        console.error(e);
      }
    }
    ctx.spinner.setState(false);
  };

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="発電事業者新規登録" />
      <Breadcrumbs
        items={[
          { label: '発電事業者 管理', path: '/manage/company/' },
          { label: '発電事業者 新規登録' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">
        発電事業者新規登録
      </div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="max-w-[800px] mx-auto flex flex-col gap-7">
          <div className="font-bold text-2xl text-re-063B67">
            発電事業者 基本情報
          </div>
          <FormProvider {...methods}>
            <CompanyEditFormBlock1 />
            <CompanyEditFormBlock2 />
          </FormProvider>
          <div className="flex justify-center gap-4">
            <Button text="戻る" outlined color="gray" link="/manage/company/" />
            <Button text="登録する" onClick={methods.handleSubmit(onCreate)} />
          </div>
        </div>
      </div>
      {/* 新規登録確認ダイアログ */}
      <Dialog show={!!data} onClose={onCloseCreateConfirm}>
        <div className="flex flex-col items-center">
          <div className="font-bold text-2xl text-re-063B67">
            発電事業者新規登録
          </div>
          <div className="my-7 font-bold text-xl text-re-063B67">
            共通IDは、今後変更ができません
          </div>
          <div className="mb-4">共通ID : {data?.domain}</div>
          <div className="mb-10">発電事業者の新規登録してよろしいですか？</div>
          <div className="flex justify-center gap-4">
            <Button
              text="戻る"
              outlined
              color="gray"
              onClick={() => onCloseCreateConfirm()}
            />
            <Button
              text="登録する"
              onClick={() => {
                onCloseCreateConfirm(true);
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ManageCompanyCreatePage;
