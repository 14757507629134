import { AiOutlineLoading } from 'react-icons/ai';

type PROPS = { show: boolean };

const Spinner: React.FC<PROPS> = (props) => {
  return (
    <div
      className={`fixed top-0 left-0 bg-zinc-600 bg-opacity-40 transition-all duration-100 ${
        props.show ? 'visible' : 'invisible'
      }`}
    >
      <div className="w-screen h-screen flex justify-center items-center">
        <AiOutlineLoading className="text-5xl animate-spin" />
      </div>
    </div>
  );
};

export default Spinner;
