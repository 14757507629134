import { UserRoleType } from 'enums';
import { CompanyModel, UserModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import UserEditFormBlock1ItemCompanyID from './UserEditFormBlock1ItemCompanyID';
import UserEditFormBlock1ItemLoginID from './UserEditFormBlock1ItemLoginID';
import UserEditFormBlock1ItemType from './UserEditFormBlock1ItemType';
import UserEditFormBlock1ItemUsername from './UserEditFormBlock1ItemUsername';
import { Store } from 'store';

type PROPS = {
  initRoleType?: UserRoleType | undefined;
  companyList: CompanyModel[];
};

const UserEditFormBlock1: React.FC<PROPS> = (props) => {
  const user = Store.loadUserDara();

  const { getValues } = useFormContext<UserModel>();

  const id = getValues().id;
  const type = getValues().type;

  if (id) {
    // 編集
    if (type === UserRoleType.MASTER) {
      return (
        <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
          <div className="font-bold text-xl text-re-063B67">アカウント</div>
          <UserEditFormBlock1ItemType editable={false} />
          <UserEditFormBlock1ItemLoginID companyList={props.companyList} />
          <UserEditFormBlock1ItemUsername />
        </div>
      );
    } else if (type === UserRoleType.ADMIN) {
      return (
        <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
          <div className="font-bold text-xl text-re-063B67">アカウント</div>
          <UserEditFormBlock1ItemCompanyID
            editable={false}
            companyList={props.companyList}
          />
          <UserEditFormBlock1ItemLoginID companyList={props.companyList} />
          <UserEditFormBlock1ItemType editable />
          <UserEditFormBlock1ItemUsername />
        </div>
      );
    } else if (type === UserRoleType.MAIN) {
      return (
        <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
          <div className="font-bold text-xl text-re-063B67">アカウント</div>
          <UserEditFormBlock1ItemCompanyID
            editable={false}
            companyList={props.companyList}
          />
          <UserEditFormBlock1ItemLoginID companyList={props.companyList} />
          <UserEditFormBlock1ItemType editable />
          <UserEditFormBlock1ItemUsername />
        </div>
      );
    } else if (type === UserRoleType.SUB) {
      return (
        <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
          <div className="font-bold text-xl text-re-063B67">アカウント</div>
          <UserEditFormBlock1ItemCompanyID
            editable={false}
            companyList={props.companyList}
          />
          <UserEditFormBlock1ItemLoginID companyList={props.companyList} />
          <UserEditFormBlock1ItemType
            editable={
              props.initRoleType === UserRoleType.ADMIN ||
              props.initRoleType === UserRoleType.MAIN
            }
          />
          <UserEditFormBlock1ItemUsername />
        </div>
      );
    } else {
      return <></>;
    }
  } else {
    // 新規登録
    if (!type) {
      return (
        <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
          <div className="font-bold text-xl text-re-063B67">アカウント</div>
          <UserEditFormBlock1ItemType editable />
        </div>
      );
    } else if (type === UserRoleType.MASTER) {
      return (
        <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
          <div className="font-bold text-xl text-re-063B67">アカウント</div>
          <UserEditFormBlock1ItemType editable />
          <UserEditFormBlock1ItemUsername />
          <UserEditFormBlock1ItemLoginID
            editable
            companyList={props.companyList}
          />
        </div>
      );
    } else if (type === UserRoleType.ADMIN || type === UserRoleType.MAIN) {
      return (
        <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
          <div className="font-bold text-xl text-re-063B67">アカウント</div>
          <UserEditFormBlock1ItemType editable />
          {user?.type === UserRoleType.MASTER && (
            <UserEditFormBlock1ItemCompanyID
              editable
              companyList={props.companyList}
            />
          )}
          <UserEditFormBlock1ItemUsername />
          <UserEditFormBlock1ItemLoginID
            editable
            companyList={props.companyList}
          />
        </div>
      );
    } else if (type === UserRoleType.SUB) {
      return (
        <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
          <div className="font-bold text-xl text-re-063B67">アカウント</div>
          <UserEditFormBlock1ItemType editable />
          {user?.type === UserRoleType.MASTER && (
            <UserEditFormBlock1ItemCompanyID
              editable
              companyList={props.companyList}
            />
          )}
          <UserEditFormBlock1ItemUsername />
          <UserEditFormBlock1ItemLoginID
            editable
            companyList={props.companyList}
          />
        </div>
      );
    } else {
      return <></>;
    }
  }
};

export default UserEditFormBlock1;
