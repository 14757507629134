import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { createDroppingDownAnime } from 'styles/transition';
import { BsCaretDownFill } from 'react-icons/bs';
import { useFormContext } from 'react-hook-form';

type PROPS<T> = {
  fieldName: string; // fieldname

  validations?: object; // validation

  options: Array<itemProps<T>>; // リスト

  type?: 'md' | 'sm';

  width?: string;

  blank?: '' | null; // 空欄選択肢の設定

  blankLabel?: string; // 空欄選択肢のラベル

  errorMessage?: string;

  className?: string;
};

type itemProps<T> = {
  value: T;
  label: string;
};

const SelectBox = <T,>({
  fieldName,
  validations,
  options,
  type = 'md',
  width = 'w-full',
  errorMessage,
  blank,
  blankLabel = '選択してください',
  className,
}: PROPS<T>) => {
  const { register, getValues, setValue } = useFormContext();
  const [items, setItems] = React.useState<Array<itemProps<T>>>(options);
  const nodeRef = React.useRef(null); // StrictModeエラーの対処用
  const selfRef = React.useRef<HTMLDivElement | null>(null);
  const [isShownItem, setIsShownItem] = React.useState(false);
  const dropDown = createDroppingDownAnime(0.1, -20);

  // 初期表示の値
  const initialLabel = items?.find(
    (i) => i.value === getValues(fieldName)
  )?.label;

  // validationの発火
  React.useEffect(() => {
    register(fieldName, {
      ...validations,
    });
  }, [register, fieldName, validations]);

  React.useEffect(() => {
    blank !== undefined
      ? setItems([{ value: blank as T, label: blankLabel }, ...options])
      : setItems(options);
  }, [blank, blankLabel, options]);

  // 領域外クリックでメニューを閉じる
  React.useEffect(() => {
    const el = selfRef.current;

    const hundleClickOutside = (e: MouseEvent) => {
      if (!el?.contains(e.target as Node)) {
        setIsShownItem(false);
      }
    };

    document.addEventListener('click', hundleClickOutside);

    return () => {
      document.removeEventListener('click', hundleClickOutside);
    };
  }, [selfRef]);

  const styles = {
    container: `relative ${className}`,
    selectBox: `cursor-pointer border-box flex items-center rounded-[6px] px-[16px]
      border
      ${width} ${type === 'md' ? 'h-[40px]' : 'h-[30px]'}
      ${
        errorMessage
          ? 'bg-re-red50 border-re-danger text-re-danger'
          : isShownItem
          ? 'border-re-blue200 bg-re-blue100 text-re-blue200'
          : type === 'md'
          ? 'border-re-gray bg-re-blue50 text-re-gray'
          : 'border-re-gray bg-white text-re-gray'
      }
    `,
    value: `w-[95%] line-clamp-1 break-all font-medium`,
    iconArea: `duration-200 ease-out ${isShownItem && '-scale-100'}`,
    optionsContainer: `absolute z-10 ${width} bg-white drop-shadow`,
    optionsBox: `flex items-center h-[50px] duration-200 px-[16px]
      hover:bg-re-blue100 cursor-pointer`,
  };

  return (
    <div
      className={styles.container}
      onClick={() => {
        setIsShownItem(true);
      }}
    >
      <div className={styles.selectBox} ref={selfRef}>
        <div className={styles.value}>{initialLabel}</div>
        <div className={styles.iconArea}>
          <BsCaretDownFill />
        </div>
      </div>
      <div className="text-re-danger">{errorMessage}</div>

      <CSSTransition
        in={isShownItem}
        timeout={{ enter: 0, exit: 100 }}
        unmountOnExit
        nodeRef={nodeRef}
      >
        {(state) => (
          <div className={styles.optionsContainer} style={dropDown[state]}>
            {items?.map((v, i) => (
              <div
                key={i}
                className={`${styles.optionsBox} ${
                  getValues(fieldName) === v.value && ' bg-re-blue100'
                }`}
                onClick={() => {
                  setValue(fieldName, v.value as any, { shouldValidate: true });
                  setIsShownItem(false);
                }}
              >
                <span className="cursor-pointer line-clamp-1 break-all">
                  {v.label}
                </span>
              </div>
            ))}
          </div>
        )}
      </CSSTransition>
    </div>
  );
};

export default SelectBox;
