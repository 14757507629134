import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import Pagination from 'components/common/pagination/Pagination';
import AlertCard from 'components/monitor/alert/AlertCard';
import AlertTransitionCard from 'components/monitor/alert/AlertTransitionCard';
import BasicInformationCard from 'components/monitor/alert/BasicInformationCard.tsx';
import dayjs from 'dayjs';
import { getAlertColor, getAlertTypeStr } from 'enums';
import { AlertTreeAPIModel, AlertTreeModel, OutletContextModel } from 'models';
import React from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Utils from 'utils';

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;

const MonitorAlertDetailPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const [data, setData] = React.useState<AlertTreeAPIModel>();

  const [historyData, setHistoryData] = React.useState<AlertTreeModel[]>();
  const [historyDataPage, setHistoryDataPage] = React.useState(1);

  const [showAlertCancel, setShowAlertCancel] = React.useState(false);

  const onCancel = async () => {
    // console.log('アラート解除');
    ctx.spinner.setState(true);
    try {
      const response = await API.Alert.cancel(id!);
      console.log(response);
      ctx.msgDialog.open('アラートを解除しました', () => {
        navigate(`/`);
      });
    } catch (e) {
      console.error(e);
      const error = (e as any).response?.data?.error;
      if (error) {
        ctx.msgDialog.open(error);
      }
    }
    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.Alert.tree(id!);
        console.log(response);
        if (response.data) {
          setData(response.data);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="アラート詳細" />
      <Breadcrumbs
        items={[
          { label: 'アラート一覧', path: '/monitor/alert/' },
          { label: 'アラート詳細' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">アラート詳細</div>
      <AlertCard data={data} setShowAlertCancel={setShowAlertCancel} />
      <AlertTransitionCard
        data={data}
        setShowAlertList={() => {
          setHistoryDataPage(1);
          setHistoryData(data?.alert_tree.slice(0, NUM_IN_PAGE));
        }}
      />
      <BasicInformationCard data={data} />

      {/* アラートログ一覧 */}
      <Dialog
        show={!!historyData}
        width="w-2/3"
        onClose={() => setHistoryData(undefined)}
      >
        <div className="font-bold text-2xl text-re-063B67 mb-6">アラート推移</div>

        {historyData?.map((alert, index) => {
          return (
            <div key={index} className="flex items-center my-3">
              <div className="flex-1">
                {dayjs(alert.captured_at).format('YYYY/MM/DD HH:mm:ss')}
              </div>
              <div className="flex flex-[2] items-center">
                <div
                  className={`px-6 p-1 font-bold
            ${getAlertColor(alert.type)} border-4`}
                >
                  {getAlertTypeStr(alert.type)}
                </div>
                <p className="ml-3">{Utils.Alert.getMessage(alert)}</p>
                <p className="ml-12">{Utils.Alert.getSubMessage(alert)}</p>
              </div>
            </div>
          );
        })}
        {historyData && (
          <div>
            <Pagination
              current={historyDataPage}
              count={Math.ceil((data?.alert_tree.length ?? 0) / NUM_IN_PAGE)}
              onChange={(page: number) => {
                setHistoryDataPage(page);

                const start = NUM_IN_PAGE * (page - 1);
                const end = start + NUM_IN_PAGE;
                setHistoryData(data?.alert_tree.slice(start, end));
              }}
            />
          </div>
        )}

        <div className="flex justify-center mt-9">
          <Button
            text="閉じる"
            size="sm"
            onClick={() => setHistoryData(undefined)}
          />
        </div>
      </Dialog>

      {/* アラート解除 */}
      <Dialog show={showAlertCancel} onClose={() => setShowAlertCancel(false)}>
        <div className="font-bold text-2xl text-center text-re-063B67 mb-6">
          アラート解除
        </div>
        <p className="text-center font-bold text-re-063B67">アラートを解除します</p>
        <p className="text-center my-6">
          内容(数値・現場など)を確認できましたか？<br></br>
          アラートを解除するとHOME画面から非表示になります。
        </p>

        <div className="flex justify-center gap-10">
          <Button
            text="しない"
            color="gray"
            outlined
            onClick={() => setShowAlertCancel(false)}
          />
          <Button text="アラート 解除" color="primary" onClick={onCancel} />
        </div>
      </Dialog>
    </div>
  );
};

export default MonitorAlertDetailPage;
