import FormLabel from 'components/common/FormLabel';
import SelectBox from 'components/common/SelectBox';
import TextField from 'components/common/TextField';
import { CompanyModel, PowerPlantModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = { companyList: CompanyModel[] };

const PowerPlantEditFormBlock1: React.FC<PROPS> = (props) => {
  const { formState } = useFormContext<PowerPlantModel>();

  const [companySelectOptions, setCompanySelectOptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  React.useEffect(() => {
    const tmp = props.companyList.map((v) => {
      return { label: v.company_name, value: v.id! };
    });
    setCompanySelectOptions(tmp);
  }, [props.companyList]);

  return (
    <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
      <div className="font-bold text-xl text-re-063B67">発電所 情報</div>

      <div className="flex gap-2">
        <FormLabel text="発電事業者" required />
        <div className="flex-1">
          <SelectBox
            blankLabel="発電事業者を選択"
            fieldName="company_id"
            options={companySelectOptions}
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.company_id?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="発電所名" required />
        <div className="flex-1">
          <TextField
            fieldName="name"
            placeholder="例：◯◯発電所"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.name?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="発電所住所" required />
        <div className="flex-1">
          <TextField
            fieldName="address"
            placeholder="例：◯◯県◯◯市◯◯"
            validations={Utils.requiredValidation()}
            errorMessage={formState.errors.address?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default PowerPlantEditFormBlock1;
