import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Card from 'components/common/Card';
import Head from 'components/common/Head';
import ImageModal from 'components/common/ImageModal';
import Link from 'components/common/Link';
import MapSideMenu from 'components/monitor/map/MapSideMenu';
import MapTopMenu from 'components/monitor/map/MapTopMenu';
import MemoDialog from 'components/monitor/map/MemoDialog';
import Map from 'components/monitor/map/map/Map';
import { ApprovalDialogType, UserRoleType } from 'enums';
import {
  MapModel,
  MapPinModel,
  MemoListModel,
  OutletContextModel,
} from 'models';
import React from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { Store } from 'store';
import Utils from 'utils';

const MonitorMapPage: React.FC = () => {
  const state: { companyId: string; powerPlantId: string; mapId: string } =
    useLocation().state;
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);
  const user = Store.loadUserDara();
  const [showDialogType, setShowDialogType] = React.useState(''); // コメントダイアログの表示フラグ
  const [pendingMemoCount, setPendingMemoCount] = React.useState('');
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState('');

  /*
   * 発電事業者リストの取得
   */
  const [companyId, setCompanyId] = React.useState(''); // 発電事業者ID
  const [companyOptions, setCompanyOptions] = React.useState<
    Array<{ label: string; value: string }>
  >([]); // 発電事業者リスト

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        if (user?.type === UserRoleType.MASTER) {
          // 発電事業者リストの取得
          const _company = await API.Company.list();
          if (_company.data) {
            setCompanyOptions(
              _company.data.map((v) => {
                return { label: v.company_name, value: v.id || '' };
              })
            );
          }
        } else {
          setCompanyId(user?.company_id || '');
        }

        if (user?.type !== UserRoleType.SUB) {
          // 認証待ちコメント数の取得
          const _count = await API.Memo.getPendingMemoCount();
          if (_count.data) {
            setPendingMemoCount(_count.data);
          }
        }

        if (state) {
          setCompanyId(state.companyId);
          setPowerPlantId(state.powerPlantId);
          setMapId(state.mapId);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 発電所リストの取得
   */
  const [powerPlantId, setPowerPlantId] = React.useState<string>(''); // 発電所ID
  const [powerPlantOptions, setPowerPlantOptions] = React.useState<
    Array<{ label: string; value: string }>
  >([]); // 発電所リスト
  React.useEffect(() => {
    (async () => {
      setPowerPlantOptions([]);
      setMapListOptions([]);
      setMapData(undefined);
      try {
        if (companyId) {
          const _powerPlant = await API.PowerPlant.listInCompany(companyId);
          if (_powerPlant.data) {
            setPowerPlantOptions(
              _powerPlant.data.map((v) => {
                return { label: v.name, value: v.id || '' };
              })
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [companyId]);

  /**
   * Mapリストの取得
   */
  const [mapId, setMapId] = React.useState(''); // マップID
  const [mapListOptions, setMapListOptions] = React.useState<
    Array<{ label: string; value: string }>
  >([]); // マップリスト
  React.useEffect(() => {
    (async () => {
      try {
        setMapListOptions([]);
        setMapData(undefined);
        if (powerPlantId) {
          const _mapList = await API.PowerPlant.getMapList(powerPlantId);
          if (_mapList.data) {
            setMapListOptions(
              _mapList.data.map((v) => {
                return { label: v.label, value: v.id || '' };
              })
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [powerPlantId]);

  /**
   * マップ情報の設定
   */
  const [mapData, setMapData] = React.useState<MapModel>(); // マップ情報
  const [mapPin, setMapPin] = React.useState<MapPinModel>(); // 選択したPinデータ
  const [memo, setMemo] = React.useState<MemoListModel>(); // 選択した報告
  const [memoList, setMemoList] = React.useState<Array<MemoListModel>>([]); // 報告リスト
  React.useEffect(() => {
    (async () => {
      setMapPin(undefined);
      setMapData(undefined);
      setMemo(undefined);
      setMemoList([]);
      try {
        if (mapId) {
          const _map = await API.Map.getMap(mapId);
          if (_map.data) {
            setMapData(_map.data);
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [mapId]);

  /**
   * マップピンクリック時にコメントリストを取得
   */
  React.useEffect(() => {
    (async () => {
      if (mapId && mapPin) {
        if (mapPin.id) {
          try {
            const _memoList = await API.Memo.getMemoList(mapId, mapPin.id);
            if (_memoList.data) {
              setMemoList(_memoList.data);

              // コメントが0件のときはピンがないためMAP情報を再取得
              if (_memoList.data.length === 0) {
                const _map = await API.Map.getMap(mapId);
                if (_map.data) {
                  setMapPin(undefined);
                  setMapData(_map.data);
                }
              }
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          setMemoList([]);
          setShowDialogType(ApprovalDialogType.CREATE);
        }
      }
    })();
  }, [mapId, mapPin, isSubmit]);

  /**
   * コメント登録
   */
  const createMemo = async (data: MemoListModel, title?: string) => {
    if (mapPin) {
      mapPin.title = title || '';
      const formData = new FormData();
      formData.append('coord_x', String(mapPin.coord_x));
      formData.append('coord_y', String(mapPin.coord_y));
      formData.append('memo', String(data.memo));
      data.memo_file && formData.append('file', data.memo_file);

      if (mapPin.id) {
        formData.append(
          'title',
          user?.type === UserRoleType.SUB ? '' : mapPin.title
        );

        /**
         * 既存ピンへのコメント
         */
        try {
          const response = await API.Memo.createMemo(
            mapId,
            mapPin.id,
            formData
          );
          if (response) {
            const _memoList = await API.Memo.getMemoList(mapId, mapPin.id);
            if (_memoList.data) {
              setMemoList(_memoList.data);
              const _map = await API.Map.getMap(mapId);

              if (_map.data) {
                setMapData(_map.data);
              }
            }

            setIsSubmit(true);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        formData.append('title', mapPin.title);
        /**
         * マップピンの新規登録
         */
        try {
          const response = await API.Map.createMapPin(mapId, formData);
          if (response) {
            const _map = await API.Map.getMap(mapId);
            if (_map.data) {
              setIsSubmit(true);
              setMapData(_map.data);
            }
            setMapPin(undefined);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  /**
   * コメント更新
   */
  const updateMemo = async (data: MemoListModel, title?: string) => {
    const formData = new FormData();
    title && formData.append('title', title);
    formData.append('memo', String(data.memo));
    data.memo_file && formData.append('file', data.memo_file);

    // 画像差し替えの場合
    if (!data.memo_file && data.memo_file_url === '') {
      formData.append('file_delete', 'true');
    }

    try {
      const response = await API.Memo.updateMemo(data.id, formData);

      if (response) {
        if (title) {
          const _map = await API.Map.getMap(mapId);

          if (_map.data) {
            setMapData(_map.data);
            setMapPin((prev) => (prev ? { ...prev, title: title } : undefined));
          }
        }
        setMemo(undefined);
        setIsSubmit(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * コメント削除
   */
  const deleteMemo = async (id: string) => {
    try {
      await API.Memo.deleteMemo(id).then((res) => {
        setIsSubmit(true);
      });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * ダイアログを閉じる
   */
  const onClose = () => {
    setMemo(undefined);
    setShowDialogType('');
    setIsSubmit(false);
  };

  return (
    <div className="w-full h-full bg-re-F3F7FA px-8 py-2 pb-20 flex flex-col gap-4">
      <Head title="MAP・報告" />
      <Breadcrumbs items={[{ label: 'MAP・報告' }]} />

      <div className="font-bold text-3xl text-re-063B67">MAP・報告</div>

      {user?.type !== UserRoleType.SUB && (
        <Card className="p-6">
          {Number(pendingMemoCount) > 0 ? (
            <>
              認証待ちのコメントが&nbsp;{pendingMemoCount}
              &nbsp;件あります&nbsp;&nbsp;
              <Link
                to="/monitor/map/approve"
                className="text-re-primary underline"
              >
                内容確認
              </Link>
            </>
          ) : (
            <p>承認待ちのコメントはありません</p>
          )}
        </Card>
      )}

      <Card className="p-6">
        <MapTopMenu
          user={user}
          navigateState={state}
          companyOptions={companyOptions}
          powerPlantOptions={powerPlantOptions}
          mapOptions={mapListOptions}
          setCompanyId={setCompanyId}
          setPowerPlantId={setPowerPlantId}
          setMapId={setMapId}
        />

        <div className="grid grid-cols-4 border border-re-gray">
          <div className="flex col-span-3">
            {powerPlantId ? (
              <Map
                mapPin={mapPin}
                mapPinList={mapData ? mapData.pins : []}
                src={mapData ? mapData.url : ''}
                height={750}
                setMapPin={setMapPin}
              />
            ) : (
              <div className="relative w-full bg-re-smoke overflow-hidden z-[1] h-[750px]">
                <div className="flex h-full justify-center items-center">
                  <p>プレビュー</p>
                </div>
              </div>
            )}
          </div>

          <MapSideMenu
            mapPin={mapPin}
            memoList={memoList}
            setShowDialogType={setShowDialogType}
            setMemo={setMemo}
            setImageSrc={setImageSrc}
          />
        </div>
      </Card>

      <MemoDialog
        showType={showDialogType}
        isSubmit={isSubmit}
        title={mapPin?.title}
        powerPlantId={powerPlantId}
        user={user}
        memo={memo}
        onCreate={createMemo}
        onUpdate={updateMemo}
        onDelete={deleteMemo}
        onClose={onClose}
      />

      <ImageModal
        show={imageSrc !== ''}
        src={imageSrc}
        onClose={() => {
          setImageSrc('');
        }}
      />
    </div>
  );
};

export default MonitorMapPage;
