import Card from 'components/common/Card';
import dayjs from 'dayjs';
import { getAlertColor, getAlertTypeStr } from 'enums';

const StatusBox = ({
  name,
  data,
}: {
  name: string;
  data?:
  | {
    sensor_name: string;
    type: string;
  }[];
}) => {
  const target = data?.find((v) => v.sensor_name === name);
  const type = target ? target.type : '';

  if (type !== '') {
    return (
      <div
        className={`px-6 p-1 font-bold ${getAlertColor(
          type
        )} border-4 w-[90px] text-center`}
      >
        {getAlertTypeStr(type)}
      </div>
    );
  } else {
    return (
      <div className="bg-re-smoke text-center text-white py-2 w-[90px]">-</div>
    );
  }
};

type PROPS = {
  data: {
    timestamp: dayjs.Dayjs;
    data: {
      sensor_name: string;
      type: string;
    }[];
  } | null;
};

const PCSStatus = (props: PROPS) => {
  const timestamp = () => {
    if (props.data?.timestamp) {
      return props.data.timestamp.format('YYYY年MM月DD日 HH:mm');
    } else {
      return '----年--月--日 --:--';
    }
  };

  return (
    <Card className="p-6">
      <div className="flex justify-between gap-4">
        <div className="font-bold text-2xl text-re-063B67 whitespace-nowrap">
          PCSの状況
        </div>
        <p className="whitespace-nowrap">{timestamp()} 現在</p>
      </div>

      <div className="mt-4 flex gap-4 flex-wrap">
        <div className="flex-1 flex flex-col gap-4">
          <div className="flex justify-between items-center gap-4">
            <div className="font-bold whitespace-nowrap">U電流派</div>
            <StatusBox
              name="inverter_current_u_status"
              data={props.data?.data}
            />
          </div>
          <div className="flex justify-between items-center gap-4">
            <div className="font-bold whitespace-nowrap">V電流派</div>
            <StatusBox
              name="inverter_current_v_status"
              data={props.data?.data}
            />
          </div>
          <div className="flex justify-between items-center gap-4">
            <div className="font-bold whitespace-nowrap">W電流派</div>
            <StatusBox
              name="inverter_current_w_status"
              data={props.data?.data}
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-4">
          <div className="flex justify-between items-center gap-4">
            <div className="font-bold whitespace-nowrap">
              冷却ファン
            </div>
            <StatusBox
              name="cooling_fan_vibration_status"
              data={props.data?.data}
            />
          </div>
          <div className="flex justify-between items-center gap-4">
            <div className="font-bold whitespace-nowrap">室内温度</div>
            <StatusBox
              name="indoor_temperature_status"
              data={props.data?.data}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PCSStatus;
