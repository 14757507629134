import ja from 'date-fns/locale/ja';
import dayjs from 'dayjs';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendarEvent } from 'react-icons/bi';
registerLocale('ja', ja);

type PROPS = {
  value: dayjs.Dayjs;
  onChange?: (date: Date | null) => void;
  disabled?: boolean;
};

const DatePicker = ({ value, onChange, disabled }: PROPS) => {
  return (
    <div
      className={`w-full relative flex items-center cursor-pointer text-re-gray z-1 ${
        disabled
          ? 'bg-re-smoke'
          : 'bg-re-blue50 focus-within:bg-re-blue100 focus-within:text-re-primary'
      }`}
    >
      <BiCalendarEvent className="absolute text-xl right-2 select-none" />
      <ReactDatePicker
        className={`w-full min-w-[9em] h-[40px] pl-[16px] rounded-[8px] cursor-pointer
            bg-transparent outline outline-1 outline-re-gray text-black focus:text-re-primary focus:outline-re-blue200 disabled:text-gray-600 disabled:cursor-default`}
        dateFormat="yyyy/MM/dd"
        disabled={disabled}
        selected={value.toDate()}
        peekNextMonth
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        locale="ja"
        onChange={(selectedDate) => {
          if (onChange) {
            onChange(selectedDate);
          }
        }}
      ></ReactDatePicker>
    </div>
  );
};

export default DatePicker;
