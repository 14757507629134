import FormLabel from 'components/common/FormLabel';
import TextField from 'components/common/TextField';
import { PowerPlantModel } from 'models';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Utils from 'utils';

type PROPS = {};

const PowerPlantEditFormBlock2: React.FC<PROPS> = (props) => {
  const { formState } = useFormContext<PowerPlantModel>();

  return (
    <div className="flex flex-col gap-8 [&_.form-label]:w-1/4">
      <div className="font-bold text-xl text-re-063B67">発電所担当者 情報</div>

      <div className="flex gap-2">
        <FormLabel text="担当者名" required={false} />
        <div className="flex-1 flex gap-4">
          <TextField
            fieldName="manager_last_name"
            placeholder="苗字"
            validations={{}}
            errorMessage={formState.errors.manager_last_name?.message}
          />
          <TextField
            fieldName="manager_first_name"
            placeholder="名前"
            validations={{}}
            errorMessage={formState.errors.manager_first_name?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="所属・部署" required={false} />
        <div className="flex-1 flex gap-4">
          <TextField
            fieldName="department"
            placeholder="例：◯◯◯事業部"
            validations={{}}
            errorMessage={formState.errors.department?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="住所" required={false} />
        <div className="flex-1 flex gap-4">
          <TextField
            fieldName="manager_address"
            placeholder="例：◯◯県◯◯市◯◯"
            validations={{}}
            errorMessage={formState.errors.manager_address?.message}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <FormLabel text="電話番号" required={false} />
        <div className="flex-1 flex gap-4">
          <TextField
            fieldName="manager_tel"
            placeholder="例：0000-0000-0000"
            validations={Utils.telValidation(false)}
            errorMessage={formState.errors.manager_tel?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default PowerPlantEditFormBlock2;
