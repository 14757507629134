import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Head from 'components/common/Head';
import ImageModal from 'components/common/ImageModal';
import TextField from 'components/common/TextField';
import dayjs from 'dayjs';
import { MemoPageListModel, OutletContextModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import Utils from 'utils';

const MonitorMapMemoPage = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  });
  const [memo, setMemo] = React.useState<Array<MemoPageListModel>>([]);
  const [imageSrc, setImageSrc] = React.useState('');

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const _memo = await API.Memo.getMemoPageList();
        if (_memo.data) {
          setMemo(_memo.data);
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full bg-re-F3F7FA px-8 py-2 pb-20 flex flex-col gap-4">
      <Head title="報告一覧" />
      <Breadcrumbs
        items={[
          { label: 'MAP・報告', path: '/monitor/map' },
          { label: '報告一覧' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">MAP・報告</div>
      <Card className="p-6">
        <div className="grid grid-cols-3 max-2xl:grid-cols-2 gap-3 items-center z-[2] mb-4">
          <div className="flex col-span-2 items-center">
            <div className="font-bold text-2xl text-re-063B67">報告一覧</div>

            <FormProvider {...methods}>
              <div className="flex ml-9 items-center">
                <TextField
                  fieldName="search"
                  placeholder="例：　記入者名 など"
                  width="w-[320px]"
                />
                <Button size="sm" text="絞り込み" className="ml-3" />
              </div>
            </FormProvider>
          </div>

          <div className="flex 2xl:justify-end items-center">
            <Button
              text="MAP表示"
              size="sm"
              width="w-[145px]"
              outlined
              link="/monitor/map/"
            />
            <Button
              text="MAP設定"
              size="sm"
              outlined
              link="/monitor/map/settings"
              className="ml-9"
            />
          </div>
        </div>

        <div className="my-4 w-full border border-re-gray rounded-t-lg overflow-x-auto">
          <table className="min-w-full border-separate border-spacing-0">
            <thead>
              <tr className="bg-re-4D7FAF text-white [&>*]:p-2 [&>th]:whitespace-nowrap">
                <th className="w-[12%]">記入日</th>
                <th className="w-[12%]">発電所</th>
                <th className="w-[12%]">記入者</th>
                <th className="w-[12%]">MAP画像名</th>
                <th className="w-[12%]">ピンのタイトル</th>
                <th className="w-[20%]">内容</th>
                <th className="w-[20%] rounded-tr-lg">添付</th>
              </tr>
            </thead>
            <tbody>
              {memo.map((v, i) => (
                <tr key={i} className="group">
                  <td className="p-4 group-last:rounded-bl-lg">
                    {dayjs(v.updated_at).format('YYYY/MM/DD HH:mm:ss')}
                  </td>
                  <td className="p-4">{v.power_plant_name}</td>
                  <td className="p-4">{v.user_name}</td>
                  <td className="p-4">{v.map_title}</td>
                  <td className="p-4">{v.pin_title}</td>
                  <td className="p-4">{v.memo}</td>
                  <td className="p-4 group-last:rounded-br-lg">
                    <img
                      src={v.memo_file_url || ''}
                      alt=""
                      className="max-h-[250px] object-contain cursor-pointer"
                      onClick={() => {
                        setImageSrc(v.memo_file_url || '');
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>

      <ImageModal
        show={imageSrc !== ''}
        src={imageSrc}
        onClose={() => {
          setImageSrc('');
        }}
      />
    </div>
  );
};

export default MonitorMapMemoPage;
