import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Button from 'components/common/Button';
import Dialog from 'components/common/Dialog';
import Head from 'components/common/Head';
import Link from 'components/common/Link';
import TextField from 'components/common/TextField';
import Pagination from 'components/common/pagination/Pagination';
import dayjs from 'dayjs';
import { UserRoleType, getUserRoleTypeStr } from 'enums';
import { OutletContextModel, UserModel } from 'models';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { Store } from 'store';
import Utils from 'utils';

type FormState = {
  keyword: string;
};

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;

const ManageUserPage: React.FC = () => {
  const user = Store.loadUserDara();
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const methods = useForm<FormState>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      keyword: '',
    },
  });

  const [data, setData] = React.useState<UserModel[]>([]);
  const [filtered, setFiltered] = React.useState<UserModel[]>([]);
  const [items, setItems] = React.useState<UserModel[]>([]);
  const [page, setPage] = React.useState(1);

  const [unlockLoginTarget, setUnlockLoginTarget] =
    React.useState<UserModel | null>(null);

  const onFilter = () => {
    let filtered = data;
    const filterText = methods.getValues().keyword;
    if (filterText !== '') {
      filtered = data.filter(
        (v) =>
          (v.company_name ?? '').includes(filterText) ||
          v.login_id.includes(filterText) ||
          (v.domain ?? '').includes(filterText) ||
          v.first_name.includes(filterText) ||
          v.last_name.includes(filterText) ||
          v.contractor_name.includes(filterText)
      );
    }
    setPage(1);
    setFiltered(filtered);
    setItems(filtered.slice(0, NUM_IN_PAGE));
  };

  const onChangePage = (page: number) => {
    setPage(page);
    if (!filtered) {
      setItems([]);
      return;
    }

    const start = NUM_IN_PAGE * (page - 1);
    const end = start + NUM_IN_PAGE;
    setItems(filtered.slice(start, end));
  };

  const onOpenUnlockLoginDialog = (target: UserModel) => {
    setUnlockLoginTarget(target);
  };

  const onCloseUnlockLoginDialog = async (result?: boolean) => {
    const tmp = unlockLoginTarget;
    setUnlockLoginTarget(null);

    if (!tmp) {
      return;
    }
    if (result !== true) {
      return;
    }

    ctx.spinner.setState(true);
    try {
      const response = await API.User.unlock(tmp.id!);
      console.log(response);
      {
        const response = await API.User.list();
        console.log(response);
        if (response.data) {
          setData(response.data);
          setFiltered(response.data);
          setItems(response.data.slice(NUM_IN_PAGE * (page - 1), NUM_IN_PAGE));
        }
      }
      ctx.msgDialog.open('解除しました');
    } catch (e) {
      console.error(e);
    }
    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.User.list();
        console.log(response);
        if (response.data) {
          setData(response.data);
          setFiltered(response.data);
          setItems(response.data.slice(NUM_IN_PAGE * (page - 1), NUM_IN_PAGE));
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="アカウント管理" />
      <Breadcrumbs items={[{ label: 'アカウント管理' }]} />
      <div className="font-bold text-3xl text-re-063B67">アカウント管理</div>
      <div className="drop-shadow rounded bg-white p-6">
        <div className="flex items-center gap-10">
          <div className="flex-1 font-bold text-2xl text-re-063B67">
            アカウント管理
          </div>
          <div className="flex items-center gap-1">
            <FormProvider {...methods}>
              <TextField
                fieldName="keyword"
                width="w-[320px]"
                placeholder="例： 発電事業者名 など"
              />
            </FormProvider>
            <Button text="絞り込み" size="sm" onClick={onFilter} />
          </div>
          {(user?.type === UserRoleType.MASTER ||
            user?.type === UserRoleType.ADMIN) && (
              <Button text="アカウント新規登録" link="/manage/user/create" />
            )}
        </div>

        <table className="w-full my-4 border border-re-gray rounded-lg border-separate border-spacing-0">
          <thead>
            <tr className="bg-re-4D7FAF text-white [&>*]:p-2">
              <th className="rounded-tl-lg">発電事業者</th>
              <th>ユーザーID</th>
              <th>ユーザー名</th>
              <th>権限</th>
              <th>所属</th>
              <th>最終ログイン</th>
              <th>ユーザー詳細</th>
              <th className="rounded-tr-lg">状況</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              const isDeleted = !(
                item.deleted_at === undefined ||
                item.deleted_at === null ||
                item.deleted_at === ''
              );
              return (
                <tr
                  key={index}
                  className={`group ${isDeleted ? 'bg-re-smoke text-re-gray' : ''
                    } [&>*]:p-4`}
                >
                  <td className="font-bold group-last:rounded-bl-lg">
                    {item.company_name}
                  </td>
                  <td>
                    {item.login_id}@{item.domain}
                  </td>
                  <td>
                    {item.type === UserRoleType.SUB ? (
                      <>{item.contractor_name}</>
                    ) : (
                      <>
                        {item.last_name} {item.first_name}
                      </>
                    )}
                  </td>
                  <td>{getUserRoleTypeStr(item.type)}</td>
                  <td>{item.department}</td>
                  <td>
                    {item.logined_at ? (
                      <>
                        {dayjs(item.logined_at).format('YYYY/MM/DD hh:mm:ss')}
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className="p-4 text-center group-last:rounded-br-lg">
                    <Link to={`/manage/user/detail/${item.id}/`}>
                      <Button text="詳細" size="sm" />
                    </Link>
                  </td>
                  <td>
                    {item.locked_at &&
                      (isDeleted ? (
                        <div className="text-center text-re-gray font-bold">
                          <div className="text-xs mt-2">
                            ({dayjs(item.locked_at).format('YYYY/MM/DD')}
                            からロック中)
                          </div>
                        </div>
                      ) : (
                        <div className="text-center text-red-600 font-bold">
                          <button
                            className="bg-white border-2 border-red-600 px-2 py-1"
                            onClick={() => {
                              onOpenUnlockLoginDialog(item);
                            }}
                          >
                            ログインロックを解除
                          </button>
                          <div className="text-xs mt-2">
                            ({dayjs(item.locked_at).format('YYYY/MM/DD')}
                            からロック中)
                          </div>
                        </div>
                      ))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <Pagination
            current={page}
            count={Math.ceil(filtered.length / NUM_IN_PAGE)}
            onChange={onChangePage}
          />
        </div>
      </div>
      {/* ログインロック解除ダイアログ */}
      <Dialog show={!!unlockLoginTarget} onClose={onCloseUnlockLoginDialog}>
        <div className="flex flex-col items-center">
          <div className="font-bold text-2xl text-re-063B67">
            ログインロック解除
          </div>
          <div className="my-6 font-bold text-xl text-re-063B67">
            対象ユーザー: {unlockLoginTarget?.last_name}{' '}
            {unlockLoginTarget?.first_name}
          </div>
          <div>ログインロックを解除しますか？</div>
          <div className="mt-12 flex gap-4">
            <Button
              outlined
              text={'解除しない'}
              onClick={() => {
                onCloseUnlockLoginDialog();
              }}
            />
            <Button
              text={'解除する'}
              onClick={() => {
                onCloseUnlockLoginDialog(true);
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ManageUserPage;
