import Head from 'components/common/Head';
import React from 'react';

const Error404: React.FC = () => {
  return (
    <div className="w-full p-8">
      <Head title="404 Not Found" />
      <div className="flex justify-center">
        <div className="p-16 border border-red-400 rounded-lg">
          <span className="text-lg font-bold">Error 404 Not Found</span>
        </div>
      </div>
    </div>
  );
};

export default Error404;
