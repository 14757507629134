import Head from 'components/common/Head';
import React from 'react';
import Card from 'components/common/Card';
import { FormProvider, useForm } from 'react-hook-form';
import TextField from 'components/common/TextField';
import MobileAlertCard from 'components/monitor/alert/MobileAlertCard';
import MobilePagination from 'components/common/pagination/MobilePagination';
import { OutletContextModel, PCSStatusModel } from 'models';
import API from 'api';
import { useOutletContext } from 'react-router-dom';
import StatusBox from 'components/monitor/pcs/StatusBox';
import Utils from 'utils';
import Button from '../../components/common/Button';

type FormState = {
  keyword: string;
};

// １ページに表示するアイテム数
const NUM_IN_PAGE = 10;

const MobileMonitorPCSPage: React.FC = () => {
  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const methods = useForm<FormState>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      keyword: '',
    },
  });

  const [data, setData] = React.useState<PCSStatusModel[]>([]);
  const [filtered, setFiltered] = React.useState<PCSStatusModel[]>([]);
  const [items, setItems] = React.useState<PCSStatusModel[]>([]);
  const [page, setPage] = React.useState(1);

  const getStatus = (data: PCSStatusModel, sensorName: string) => {
    if (!data || !data.status) {
      return null;
    }
    const target = data.status.find((v) => v.sensor_name === sensorName);
    return target ? target.type : null;
  };

  const onFilter = () => {
    let filtered = data;
    const filterText = methods.getValues().keyword;
    if (filterText !== '') {
      filtered = data.filter(
        (v) =>
          v.power_plant_name.includes(filterText) ||
          v.pcs_name.includes(filterText)
      );
    }
    setPage(1);
    setFiltered(filtered);
    setItems(filtered.slice(0, NUM_IN_PAGE));
  };

  const onChangePage = (page: number) => {
    setPage(page);
    if (!filtered) {
      setItems([]);
      return;
    }
    const start = NUM_IN_PAGE * (page - 1);
    const end = start + NUM_IN_PAGE;
    setItems(filtered.slice(start, end));
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      try {
        const response = await API.PCS.latest();
        console.log(response);
        if (response.data) {
          response.data.forEach((v) => {
            const aipcsi = getStatus(v, 'logger_status');
            if (aipcsi === null) {
              // AiPCSiの状態情報が無い場合、その他の状態情報は無視する
              v.status = [];
            } else if (aipcsi === 'trouble') {
              // AiPCSiの状態が"異常"の場合は、その他の状態情報は無視する
              v.status = [{ sensor_name: 'logger_status', type: 'trouble' }];
            }
          });

          // "注意"or"異常"を含むデータを前にソート
          response.data = response.data.sort((a, b) => {
            const aa = a.status.some(
              (v) => v.type === 'trouble' || v.type === 'caution'
            );
            const bb = b.status.some(
              (v) => v.type === 'trouble' || v.type === 'caution'
            );
            if (!aa && bb) {
              return 1;
            } else if (aa && !bb) {
              return -1;
            } else return 0;
          });

          setData(response.data);
          setFiltered(response.data);
          setItems(response.data.slice(NUM_IN_PAGE * (page - 1), NUM_IN_PAGE));
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full p-4">
      <Head title="PCSの状態" />
      <div className="flex justify-center">
        <Card className="p-5">
          <div className="font-bold text-2xl text-re-063B67">PCS一覧</div>
          <div className="flex justify-center py-3 items-center">
            <FormProvider {...methods}>
              <TextField
                fieldName="keyword"
                width="w-full"
                placeholder="例： 発電所名 など"
              />
            </FormProvider>
            <div className="pl-4">
              <Button text="絞り込む" size="sm" onClick={onFilter} />
            </div>
          </div>
          {items.map((item, index) => {
            const isDeleted = !!item.deleted_at;

            return (
              <MobileAlertCard
                key={index}
                className="mb-3"
                headerClass="bg-re-4D7FAF text-white font-bold"
                mainClass="truncate"
                mainTitle={item.pcs_name}
                subTitle={item.power_plant_name}
              >
                <div
                  className={`p-3 ${
                    isDeleted ? 'bg-re-smoke text-re-gray' : ''
                  }`}
                >
                  <div className="gap-y-3 pb-3">
                    <div className="grid grid-cols-5 mb-4 items-center">
                      <div className="col-span-3 text-left font-medium text-sm">
                        AiPCSiシステム正常/異常
                      </div>
                      <div className="font-bold text-right">
                        <StatusBox type={getStatus(item, 'logger_status')} />
                      </div>
                    </div>
                    <div className="grid grid-cols-5 mb-8 items-center">
                      <div className="col-span-3 text-left font-medium text-sm">
                        冷却ファンの故障予兆
                      </div>
                      <div className="font-bold text-right">
                        <StatusBox
                          type={getStatus(item, 'cooling_fan_vibration_status')}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-5 mb-8 items-center">
                      <div className="col-span-3 text-left font-medium text-sm">
                        U電流の故障予兆
                      </div>
                      <div className="font-bold text-right">
                        <StatusBox
                          type={getStatus(item, 'inverter_current_u_status')}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-5 mb-8 items-center">
                      <div className="col-span-3 text-left font-medium text-sm">
                        V電流の故障予兆
                      </div>
                      <div className="font-bold text-right">
                        <StatusBox
                          type={getStatus(item, 'inverter_current_v_status')}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-5 mb-8 items-center">
                      <div className="col-span-3 text-left font-medium text-sm">
                        W電流の故障予兆
                      </div>
                      <div className="font-bold text-right">
                        <StatusBox
                          type={getStatus(item, 'inverter_current_w_status')}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-5 mb-8 items-center">
                      <div className="col-span-3 text-left font-medium text-sm">
                        温度
                      </div>
                      <div className="font-bold text-right">
                        <StatusBox
                          type={getStatus(item, 'indoor_temperature_status')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </MobileAlertCard>
            );
          })}
          <div>
            <MobilePagination
              current={page}
              count={Math.ceil((filtered?.length ?? 0) / NUM_IN_PAGE)}
              onChange={onChangePage}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default MobileMonitorPCSPage;
