import API from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Head from 'components/common/Head';
import UserDetailPanel1 from 'components/manage/user/UserDetailPanel1';
import UserDetailPanel2 from 'components/manage/user/UserDetailPanel2';
import UserDetailPanel3 from 'components/manage/user/UserDetailPanel3';
import dayjs from 'dayjs';
import { UserRoleType } from 'enums';
import { OutletContextModel, PowerPlantModel, UserModel } from 'models';
import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Store } from 'store';
import Utils from 'utils';

const ManageUserDetailPage: React.FC = () => {
  const { id } = useParams();

  const ctx = useOutletContext<OutletContextModel>();
  const initialized = React.useRef(false);

  const [powerPlantList, setPowerPlantList] = React.useState<PowerPlantModel[]>(
    []
  );
  const [data, setData] = React.useState<UserModel>();

  const user = Store.loadUserDara();

  const isDeleted = () => {
    if (!data) {
      return false;
    }
    return !!data.deleted_at && dayjs(data.deleted_at).isValid();
  };

  const load = async () => {
    try {
      let user: UserModel | null = null;
      const response = await API.User.get(id!);
      console.log(response);
      if (response.data) {
        user = response.data;
        setData(user);
        return user;
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  };
  const onAddEmail = async (email: string) => {
    // バリデーション
    ctx.spinner.setState(true);
    try {
      const response = await API.User.addEmail(id!, email);
      console.log(response);
      if (response.data) {
        await load();
      }
      ctx.spinner.setState(false);
      ctx.msgDialog.open('登録しました');
      return true;
    } catch (e) {
      console.error(e);
      ctx.spinner.setState(false);
      ctx.msgDialog.open('登録できませんでした。入力に誤りがあります。');
      return false;
    }
  };

  const onRemoveEmail = async (emailID: string) => {
    ctx.spinner.setState(true);
    try {
      const response = await API.User.deleteEmail(id!, emailID);
      console.log(response);
      await load();
      ctx.msgDialog.open('解除しました');
    } catch (e) {
      console.error(e);
      ctx.msgDialog.open('解除できませんでした。入力に誤りがあります。');
    }
    ctx.spinner.setState(false);
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      const user = await load();
      if (user) {
        const response = await API.PowerPlant.listInCompany(user.company_id);
        if (response.data) {
          setPowerPlantList(response.data);
        }
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen bg-re-F3F7FA px-8 py-2 flex flex-col gap-4">
      <Head title="アカウント詳細" />
      <Breadcrumbs
        items={[
          { label: 'アカウント管理', path: '/manage/user/' },
          { label: 'アカウント詳細' },
        ]}
      />
      <div className="font-bold text-3xl text-re-063B67">アカウント詳細</div>

      <UserDetailPanel1
        data={data}
        editPagePath={`/manage/user/edit/${data?.id}/`}
        powerPlantList={powerPlantList}
        isDeleted={isDeleted()}
      />
      {/* 権限:マスターか権限:アドミンか自分のパスワードであれば変更可能 */}
      {(user?.type === UserRoleType.MASTER ||
        user?.type === UserRoleType.ADMIN ||
        user?.id === id) && (
          <UserDetailPanel2
            changePWPagePath={`/manage/user/changepw/${data?.id}/`}
            isDeleted={isDeleted()}
          />
        )}
      <UserDetailPanel3
        data={data}
        onAddEmail={onAddEmail}
        onRemoveEmail={onRemoveEmail}
        isDeleted={isDeleted()}
      />
    </div>
  );
};

export default ManageUserDetailPage;
