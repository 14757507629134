import React from 'react';

type PROPS = {
  value: boolean;
  leftLabel: React.ReactNode;
  rightLabel: React.ReactNode;
  width?: string;
  height?: string;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
};

const Switch: React.FC<PROPS> = ({
  value,
  leftLabel,
  rightLabel,
  width = 'w-full',
  height = 'h-[48px]',
  setValue,
}: PROPS) => {
  const style = {
    container: `flex ${width} ${height} border border-2 border-re-primary rounded-[6px]`,
    contentBox: `flex items-center justify-center w-1/2 cursor-pointer
      text-re-primary text-[18px] font-bold select-none
    `,
    active: `bg-re-primary !text-white`,
  };
  return (
    <div className={style.container}>
      <div
        className={`${style.contentBox} ${value && style.active}`}
        onClick={() => {
          setValue(true);
        }}
      >
        {leftLabel}
      </div>
      <div
        className={`${style.contentBox} ${!value && style.active}`}
        onClick={() => {
          setValue(false);
        }}
      >
        {rightLabel}
      </div>
    </div>
  );
};

export default Switch;
