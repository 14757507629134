import Card from 'components/common/Card';
import Head from 'components/common/Head';
import MobilePowerPlantAiPCSiPanel from 'components/manage/power_plant/MobilePowerPlantAiPCSiPanel';
import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import API from '../../api';
import {
  AiPCSiModel,
  OutletContextModel,
  PCSModel,
  PowerPlantModel,
} from '../../models';
import Utils from 'utils';

const MobileManagePowerPlantDetailPage: React.FC = () => {
  const { id } = useParams();
  const initialized = React.useRef(false);
  const ctx = useOutletContext<OutletContextModel>();
  const [data, setData] = React.useState<PowerPlantModel>();
  const [AiPCSis, setAiPCSis] = React.useState<AiPCSiModel[]>([]);
  const [pcsSet, setPCSSet] = React.useState<{ [id: string]: PCSModel[] }>({});
  const load = async () => {
    try {
      const response = await API.PowerPlant.get(id);
      console.log(response);
      if (response.data) {
        setData(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    Utils.scrollTop();
  }, []);

  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    (async () => {
      ctx.spinner.setState(true);
      await load();
      try {
        const response = await API.AiPCSi.list(id);
        console.log(response);
        if (response.data) {
          const aipcsis = response.data.aipcsis;
          setAiPCSis(aipcsis);
          for (const aipcsi of aipcsis) {
            const response = await API.PCS.list(aipcsi.id);
            console.log(response);
            pcsSet[aipcsi.id!] = response.data;
            setPCSSet({ ...pcsSet });
          }
        }
      } catch (e) {
        console.error(e);
      }
      ctx.spinner.setState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-full p-4 bg-re-F3F7FA">
      <Head title="発電所詳細" />
      <div className="mb-3 font-bold text-3xl text-re-063B67">発電所 詳細</div>
      <div className="flex justify-center">
        <Card className="p-6">
          <div className="font-bold text-2xl text-re-063B67 mb-8">
            発電所 基本情報
          </div>
          <div>
            <div className="font-bold text-xl text-re-063B67 mb-8">
              発電所 情報
            </div>
            <div className="mb-8">
              <div className="font-medium text-sm">発電所名</div>
              <div className="font-bold text-lg">{data?.name}</div>
            </div>
            <div className="mb-8">
              <div className="font-medium text-sm">発電所住所</div>
              <div className="font-bold text-lg">{data?.address}</div>
            </div>
          </div>
          <div>
            <div className="font-bold text-xl text-re-063B67 mb-8">
              発電所 担当者情報
            </div>
            <div className="mb-8">
              <div className="font-medium text-sm">担当者名</div>
              <div className="font-bold text-lg">
                {data?.manager_last_name}&nbsp;{data?.manager_first_name}
              </div>
            </div>
            <div className="mb-8">
              <div className="font-medium text-sm">所属・部署</div>
              <div className="font-bold text-lg">{data?.department}</div>
            </div>
            <div className="mb-8">
              <div className="font-medium text-sm">発電所住所</div>
              <div className="font-bold text-lg">{data?.manager_address}</div>
            </div>
            <div className="mb-2">
              <div className="font-medium text-sm">電話番号</div>
              <div className="font-bold text-lg">{data?.manager_tel}</div>
            </div>
          </div>
        </Card>
      </div>
      <MobilePowerPlantAiPCSiPanel
        power_plant_id={data?.id!}
        data={AiPCSis}
        pcsSet={pcsSet}
      />
    </div>
  );
};

export default MobileManagePowerPlantDetailPage;
